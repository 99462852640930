import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NotificationService} from '../../utils/notification.service';
import {PermissionService} from '../../../services/permission.service';
import {AuthService} from '../../../+auth/auth.service';
import {AuthenticationService} from '../../../services/authentication.service';

declare var $: any;

@Component({
    selector: 'sa-logout',
    template: `
        <div id="logout" (click)="showPopup()" class=" transparent pull-right">
        <span > <a routerlink="/auth/login" title="Sign Out" data-action="userLogout"
                  data-logout-msg="You can improve your security further after logging out by closing this opened browser">
            <i nz-icon nzType="logout" style="color: black" nzTheme="outline"></i></a> </span>
        </div>
    `,
    styles: []
})
export class LogoutComponent implements OnInit {

    constructor(private router: Router,
                public permissionService: PermissionService,
                public authService: AuthenticationService,
                private notificationService: NotificationService) {
    }

    showPopup() {
        this.notificationService.smartMessageBox({
            title: '<i class=\'fa fa-sign-out txt-color-orangeDark\'></i> Logout <span class=\'txt-color-orangeDark\'><strong>' + $('#show-shortcut').text() + '</strong></span> ?',
            content: 'You can improve your security further after logging out by closing this opened browser',
            buttons: '[No][Yes]'

        }, (ButtonPressed) => {
            if (ButtonPressed == 'Yes') {
                this.logout()
            }
        });
    }

    logout() {
        this.permissionService.clearAuthKeys();
        this.authService.logout();
        this.router.navigate(['/auth/login']);
    }

    ngOnInit() {

    }


}
