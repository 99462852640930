import * as tslib_1 from "tslib";
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
var OrganisationTagService = /** @class */ (function (_super) {
    tslib_1.__extends(OrganisationTagService, _super);
    function OrganisationTagService(http) {
        var _this = _super.call(this, 'organisation-tag', http) || this;
        _this.http = http;
        return _this;
    }
    return OrganisationTagService;
}(BaseService));
export { OrganisationTagService };
