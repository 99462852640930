import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../services/base.service';
import { Constants } from '../../../constants/app.constant';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MovieRecordService = /** @class */ (function (_super) {
    tslib_1.__extends(MovieRecordService, _super);
    function MovieRecordService(http) {
        var _this = _super.call(this, 'virtual_good_order', http) || this;
        _this.http = http;
        return _this;
    }
    MovieRecordService.prototype.ngOnInit = function () {
    };
    MovieRecordService.prototype.getAllByPaging = function (searchCondition, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    MovieRecordService.prototype.getAllCouponRecordByPaging = function (searchCondition, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var ticketStr = '&f_category=4&f_category_op==';
        return this.http.get(Constants.API_ENDPOINT + "v1/virtual_good_order?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + ticketStr) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    MovieRecordService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    MovieRecordService.prototype.getAllByWalletIdsByPaging = function (walletIds, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    MovieRecordService.ngInjectableDef = i0.defineInjectable({ factory: function MovieRecordService_Factory() { return new MovieRecordService(i0.inject(i1.HttpClient)); }, token: MovieRecordService, providedIn: "root" });
    return MovieRecordService;
}(BaseService));
export { MovieRecordService };
