import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';
import {Headers, Http, RequestOptions} from '@angular/http'
import {PromoKey} from '../../models/latipay/promo-key.model';
import {PromoKeyWords} from '../../models/latipay/promo-key-words.model';
import {PricingPlan} from '../../models/latipay/pricing-plan.model';
import {BusinessKey} from '../../models/latipay/business-key.model';


@Injectable()
export class PromoItemService extends BaseService implements OnInit {

    constructor(public http: HttpClient,
                public httpP: Http) {
        super('promo-item', http);
    }

    ngOnInit(): void {
    }

    getAllNormalPromoItemByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}/get-promos/${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllPromoItemByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    addPromoKeyword(token: string, promoKeyBody: PromoKey) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/search-promo-item/';

        return this.httpP.post(`${url}`, promoKeyBody, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    patchKeyword(token: string, promoKeyBody: PromoKeyWords, id: number) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/search-keyword/' + id + '/';

        return this.httpP.patch(`${url}`, promoKeyBody, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getSinglePromoKeyword(token: string, id: number) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/search-promo-item/?promo_id=' + id;

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getSingleBusinessKeyword(token: string, id: number) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/search-business-item/?business_id=' + id;

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    patchPromoKeyword(token: string, promoKeyBody: PromoKey, id: number) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/search-promo-item/' + id + '/';

        return this.httpP.patch(`${url}`, promoKeyBody, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    patchBusinessKeyword(token: string, promoKeyBody: BusinessKey, id: number) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/search-business-item/' + id + '/';

        return this.httpP.patch(`${url}`, promoKeyBody, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getPromoByKeyword(token: string, searchP: string, paging: Paging) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/search-promo-item/?search=' + searchP + '&embed=item_detail' + '&ordering=id&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString();

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getBusinessByKeyword(token: string, searchP: string, paging: Paging) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/search-business-item/?search=' + searchP + '&embed=item_detail' + '&ordering=id&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString();

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllPromoByKeyword(token: string, paging: Paging) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/search-promo-item/' + '?embed=item_detail' + '&ordering=id&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString();


        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllBusinessByKeyword(token: string, paging: Paging) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/search-business-item/' + '?embed=item_detail' + '&ordering=id&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString();


        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    insertAllPromoKeywords(token: string, searchP: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        // if (!searchP) {
        //     searchP = '';
        // } else {
        //     searchP = '?promo_id=' + searchP;
        // }

        const url = this.baseParaUrl + 'api/search-promo-item/?promo_id__in=' + searchP + '&ordering=-promo_id';

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    insertAllBusinessKeywords(token: string, searchP: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        // if (!searchP) {
        //     searchP = '';
        // } else {
        //     searchP = '?promo_id=' + searchP;
        // }

        const url = this.baseParaUrl + 'api/search-business-item/?business_id__in=' + searchP + '&ordering=-business_id';

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }



    getAllPromoKeyword(token: string, searchP: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/search-promo-item/?promo_id=' + searchP;

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllBusinessKeyword(token: string, searchP: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/search-business-item/?business_id=' + searchP;

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    getAllPromoKeywordsWithoutPaging(token: string, searchP: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/search-keyword/?search=' + searchP;

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllPromoKeywords(token: string, searchP: string, kPaging: Paging) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/search-keyword/?ordering_statistically=searchIn30&limit=50&offset=' + (+(+kPaging.getPagingNumber() - 1) * 50).toString();

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllBusinessKeywords(token: string, searchP: string, kPaging: Paging) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/search-keyword/?ordering_statistically=searchIn30&limit=50&offset=' + (+(+kPaging.getPagingNumber() - 1) * 50).toString();

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getPromoKeywordsByName(token: string, searchP: string, paging: Paging) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);

        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/search-keyword/?search=' + searchP + '&ordering_statistically=searchIn30&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString();

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    addKeyWords(token: string, newKeyword: PromoKeyWords) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', 'Bearer ' + token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/search-keyword/';

        return this.httpP.post(`${url}`, newKeyword, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    uploadImage(token: string, file: any) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', 'Bearer ' + token);
        const options = new RequestOptions({headers: header});



        const url = this.baseUrl + 'admin-upload';

        return this.httpP.post(`${url}`, file, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    // getPromoKeys(id: number) {
    //
    //     const keyUrl = 'https://parasite-staging.latipay.net/api/search-promo-item/?promo_id=';
    //     const str = this.embeddedStr ? `${id}?${this.embeddedStr}` : id;
    //     return this.http.get(`${keyUrl}${str}`).pipe(
    //         map((response) => response), catchError((res: any) => observableThrowError(res)));
    // }


    getAllPromoItemIDByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        const embededStrr = '&embedded=category-id';
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${searchCondition + embededStrr}&sort=categoryId.queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllPromoItemIDByPagingFirst(searchCondition: string, sortOprions: Sorts, paging: Paging, pageNum: number) {
        if (!searchCondition) {
            searchCondition = '';
        }
        if (pageNum) {
            paging.pageNumber = pageNum;
        }
        const sortStr = sortOprions.getSortString();
        const embededStrr = '&embedded=category-id';
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${searchCondition + embededStrr}&sort=categoryId.queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByBusinessIdByPaging(searchCondition: string, businessId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }

        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc` + (businessId ? `&f_businessId=${businessId}&f_businessId_op==` : '')).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByWalletIdsByPaging(walletIds: string[], searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,asc';
        let walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(id => {
                walletIdsStr += `&f_acceptedMerchants=${id}`;
            })
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${walletIdsStr}${sortStr}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res))
        );
    }

    publishCouponById(id: string) {
        return this.http.post(`${this.url}/${id}/dispatch`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    applyPromo(id: number) {
        return this.http.post(`${this.url}/${id}/apply`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    closePromo(id: number) {
        return this.http.post(`${this.url}/${id}/close`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllHotKeywords(token: string, searchP: string) {
        const header: Headers = new Headers();

        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/search-keyword/?hot=' + searchP;

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllNewKeywords(token: string, searchP: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/search-keyword/?new=' + searchP;

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllPromoHotKeywords(token: string, kPaging: Paging) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/search-keyword/?ordering_statistically=searchIn30&hot=true&limit=50&offset=' + (+(+kPaging.getPagingNumber() - 1) * 50).toString();

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllPromoNewKeywords(token: string, kPaging: Paging) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/search-keyword/?ordering_statistically=searchIn30&new=true&limit=50&offset=' + (+(+kPaging.getPagingNumber() - 1) * 50).toString();

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllCouponTransactionWithoutPaging(token: string, searchP: string) {
        const header: Headers = new Headers();
        token = 'Bearer ' + token;
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'para/api/coupon-statistics/?create_date=' + searchP;

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    getAllCouponTransaction(token: string, searchP: string, paging: Paging) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/search-keyword/?ordering=id&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString();

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    sendSMSToUsers(token: string, smsBody: any) {
        token = 'Bearer ' + token;
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/sms/';

        return this.httpP.post(`${url}`, smsBody, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    addPricingPlan(token: string, pricingPlanBody: PricingPlan) {
        const header: Headers = new Headers();
        token = 'Bearer ' + token;
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'para/api/pricing-plan/';

        return this.httpP.post(`${url}`, pricingPlanBody, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updatePricingPlan(token: string, pricingPlanBody: PricingPlan, id: number) {
        const header: Headers = new Headers();
        token = 'Bearer ' + token;
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'para/api/pricing-plan/' + id + '/';

        return this.httpP.patch(`${url}`, pricingPlanBody, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllPromoItem(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = '&sort=queue,asc';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${this.embeddedStr + searchStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getTotalPromoExpiry() {
        return this.http.get(`${this.url}/get-total-days`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllFilterUser(condtions: string, token: string, paging: Paging) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/app-user/' + '?ordering=-create_date&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString() + '&conditions=' + condtions;


        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllFilterUserWithoutPaging(condtions: string, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/app-user/' + '?ordering=-create_date' + '&conditions=' + condtions;


        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

}
