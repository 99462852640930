import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../constants/app.constant';
import {PricingPlan} from '../../models/latipay/pricing-plan.model';
import {Headers, Http, RequestOptions} from '@angular/http';

@Injectable()
export class PricingPlanService implements OnInit {


    public pricingUrl = Constants.API_ENDPOINT + 'v1/pricing-plan';
    public pricingBulkUrl = Constants.API_ENDPOINT + 'v1/pricing-plan/bulk';

    constructor(private http: HttpClient,
                public httpP: Http) {
    }

    ngOnInit(): void {

    }

    // getAllSinglePricingList(searchCondition: string, orgId: any) {
    //     const str = '?f_merchant.id=' + orgId + '&f_merchant.id_op==';
    //     const embeddedStr = '&size=5000&embedded=merchant';
    //     return this.http.get(`${this.pricingUrl + str + searchCondition + embeddedStr}`).pipe(
    //         map((response) => response), catchError((res: any) => observableThrowError(res)));
    // }

    getPricingList(searchCondition: string, orgId: any, walletCode: string) {
        const str = '?f_merchant.id=' + orgId + '&f_merchant.id_op==' + '&f_wallet.id=' + walletCode;
        const embeddedStr = '&size=5000&embedded=merchant,wallet';
        return this.http.get(`${this.pricingUrl + str + searchCondition + embeddedStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    update(pricingList: PricingPlan[]) {
        return this.http.put(`${this.pricingBulkUrl}`, pricingList).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    reset(id: number) {
        return this.http.post(`${Constants.API_ENDPOINT + 'v1/pricing-plan/reset?orgIds=' + id}`, {}).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    add(value: any) {
        return this.http.post(`${this.pricingUrl}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    updateP(id: number | string, value: any) {
        return this.http.put(`${this.pricingUrl}/${id}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    addByList(id: number, code: string, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.pricingUrl}/add-by-list/${id}/${code}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
