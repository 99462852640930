import {Injectable, OnInit} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs';

@Injectable()
export class UserAccountService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('user-account', http);
    }

    ngOnInit(): void {
    }

    getAll(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        const embededstr = '&embedded=merchant-user,wallet';

        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${embededstr + searchStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

}
