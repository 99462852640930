
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../../services/base.service';
import {Paging} from '../../models/latipay/paging.model';

@Injectable()
export class InvoiceService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('invoice', http);
    }

    ngOnInit(): void {
    }

    verifyTransaction(orderId: string, taskId: number) {
        return this.http.post(`${this.url}/verify/${orderId}` + (taskId ? '?taskId=' + taskId : ''), null).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }
    verifyNewTransaction(orderId: string, taskId: number) {
        return this.http.post(`${this.url}/verifyV2/${orderId}` + (taskId ? '?taskId=' + taskId : ''), null).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }
    getAllInoviceByPaging(searchCondition: string, paging: Paging) {
        let idSortStr = '&sort=orderId,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition }`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }
}
