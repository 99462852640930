
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../constants/app.constant';
import {Paging} from "../../models/latipay/paging.model";
import {BaseService} from "../base.service";
import {Bank} from '../../models/latipay/bank.model';

@Injectable()
export class BankService extends BaseService implements OnInit {


    constructor(public http: HttpClient) {
        super('bank', http);
    }

    ngOnInit(): void {
    }

    updateBank(id: number, bank: Bank) {
        return this.http.put(this.url + '/' + id, bank).pipe(
            map((response) => response));
    }

    addBank(bank: Bank) {
        return this.http.post(this.url, bank).pipe(
            map((response) => response));
    }

    getAllAbledBanks() {
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = 'sort=id,desc&';
        let statusStr = '&f_status=1&f_status_op==';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${this.embeddedStr + statusStr}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }
}
