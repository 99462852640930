import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../services/base.service';
var InvoiceService = /** @class */ (function (_super) {
    tslib_1.__extends(InvoiceService, _super);
    function InvoiceService(http) {
        var _this = _super.call(this, 'invoice', http) || this;
        _this.http = http;
        return _this;
    }
    InvoiceService.prototype.ngOnInit = function () {
    };
    InvoiceService.prototype.verifyTransaction = function (orderId, taskId) {
        return this.http.post(this.url + "/verify/" + orderId + (taskId ? '?taskId=' + taskId : ''), null).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    InvoiceService.prototype.verifyNewTransaction = function (orderId, taskId) {
        return this.http.post(this.url + "/verifyV2/" + orderId + (taskId ? '?taskId=' + taskId : ''), null).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    InvoiceService.prototype.getAllInoviceByPaging = function (searchCondition, paging) {
        var idSortStr = '&sort=orderId,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return InvoiceService;
}(BaseService));
export { InvoiceService };
