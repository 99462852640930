
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Paging} from '../../models/latipay/paging.model';

@Injectable()
export class ConfigurationService extends BaseService implements OnInit {


    constructor(public http: HttpClient) {
        super('configuration', http);
    }

    ngOnInit(): void {
    }

    // updateBank(id: number, bank: Bank) {
    //     return this.http.put(this.url + '/' + id, bank)
    //         .map((response) => response);
    // }
    //
    // addBank(bank: Bank) {
    //     return this.http.post(this.url, bank)
    //         .map((response) => response);
    // }

    getAllByPagingByCustomSearch(orgId: number, searchCondition: string, paging: Paging) {
        const idSortStr = 'sort=id,desc&';
        let customStr = '';
        if (!(searchCondition && searchCondition.indexOf('&f_skey=autowithdraw_report_email_for_') >= 0)) {
            customStr = '&f_skey=autowithdraw_report_email_for_';
        }
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition + customStr}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

}
