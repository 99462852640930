import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
var WalletPayeaseTypeService = /** @class */ (function (_super) {
    tslib_1.__extends(WalletPayeaseTypeService, _super);
    function WalletPayeaseTypeService(http) {
        var _this = _super.call(this, 'wallet-payease-type', http) || this;
        _this.http = http;
        return _this;
    }
    WalletPayeaseTypeService.prototype.ngOnInit = function () {
    };
    return WalletPayeaseTypeService;
}(BaseService));
export { WalletPayeaseTypeService };
