import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
var AppPrizeItemService = /** @class */ (function (_super) {
    tslib_1.__extends(AppPrizeItemService, _super);
    function AppPrizeItemService(http) {
        var _this = _super.call(this, 'app-prize-item', http) || this;
        _this.http = http;
        return _this;
    }
    AppPrizeItemService.prototype.ngOnInit = function () {
    };
    AppPrizeItemService.prototype.getAllAppFeedbackByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + searchCondition + "&sort=created,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppPrizeItemService.prototype.getAllUserByPaging = function (searchCondition, id, paging, hasUser) {
        var idSortStr = '&sort=issueDate,desc';
        var embededStr = '&embedded=prize';
        var str = '';
        if (hasUser === 'has') {
            str = '&f_prize.raffleId_op==&f_prize.raffleId=' + id;
        }
        else {
            str = '&f_userId=U&f_prize.raffleId_op==&f_prize.raffleId=' + id;
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + idSortStr + embededStr + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppPrizeItemService.prototype.getAllUserByPrizeIdByPaging = function (searchCondition, id, paging) {
        var idSortStr = '&sort=id,desc';
        var embededStr = '&embedded=prize';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_userId=U&f_prize.id_op==&f_prize.id=' + id;
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + idSortStr + embededStr + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppPrizeItemService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppPrizeItemService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppPrizeItemService.prototype.publishCouponById = function (id) {
        return this.http.post(this.url + "/" + id + "/dispatch", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppPrizeItemService.prototype.updateStatus = function (id, enabled) {
        return this.http.post(this.url + "/" + id + "/" + enabled + "/update-status", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppPrizeItemService.prototype.addBudget = function (id, budget) {
        return this.http.post(this.url + "/" + id + "/" + budget + "/add-budget", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppPrizeItemService.prototype.activatePrize = function (id) {
        return this.http.post(this.url + "/" + id + "/activate-prize", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppPrizeItemService.prototype.redeemPrize = function (id) {
        return this.http.post(this.url + "/" + id + "/redeem-prize", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return AppPrizeItemService;
}(BaseService));
export { AppPrizeItemService };
