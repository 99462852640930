import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
var AppCashWalletDetailService = /** @class */ (function (_super) {
    tslib_1.__extends(AppCashWalletDetailService, _super);
    function AppCashWalletDetailService(http) {
        var _this = _super.call(this, 'prepaid-wallet-detail', http) || this;
        _this.http = http;
        return _this;
        // this.embeddedStr = '&embedded=currency,organisation,organisation.primary-currency';
    }
    AppCashWalletDetailService.prototype.ngOnInit = function () {
    };
    //  /v1/wallet?f_organisation.id=18&f_organisation.id_op==
    AppCashWalletDetailService.prototype.getAllByPagingByOrgId = function (orgId, searchCondition, paging) {
        var sortStr = '&sort=createDate,desc';
        var embededStr = '&embedded=organisation';
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(this.baseUrl + "wallet?" + paging.getPagingStr() + (this.embeddedStr + str + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCashWalletDetailService.prototype.getAllDetailByPaging = function (selectCode, searchCondition, paging) {
        var sortStr = '&sort=createDate,desc';
        var codeStr = '';
        if (selectCode) {
            codeStr = '&f_prepaidWalletCode=' + selectCode;
        }
        // const embededStr = '&embedded=organisation';
        // const search = '&f_accountName=钱多多';
        return this.http.get(this.baseUrl + "prepaid-wallet-detail?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + sortStr + codeStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCashWalletDetailService.prototype.getAllByPaging = function (searchCondition, paging) {
        var sortStr = '&sort=id,desc';
        // const embededStr = '&embedded=organisation';
        // const search = '&f_accountName=钱多多';
        return this.http.get(this.baseUrl + "prepaid-wallet-detail?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCashWalletDetailService.prototype.saveWallet = function (payeaseType, isOnline, bankAccountId, merchantUserId, value) {
        return this.http.post(this.url + "?merchantUserId=" + merchantUserId + "&payeaseType=" + payeaseType + "&isOnline=" + isOnline + "&bankAccountId=" + bankAccountId, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCashWalletDetailService.prototype.updateWallet = function (payeaseType, isOnline, bankAccountId, id, value) {
        return this.http.put(this.url + "/" + id + "?payeaseType=" + payeaseType + "&isOnline=" + isOnline + "&bankAccountId=" + bankAccountId, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return AppCashWalletDetailService;
}(BaseService));
export { AppCashWalletDetailService };
