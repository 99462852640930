
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../constants/app.constant';
import {Paging} from "../../models/latipay/paging.model";
import {BaseService} from "../base.service";
import {Bank} from '../../models/latipay/bank.model';

@Injectable()
export class PayCompanyInfoService extends BaseService implements OnInit {


    constructor(public http: HttpClient) {
        super('pay-company-info', http);
        // this.embeddedStr =
    }

    ngOnInit(): void {
    }

    getAllByCode() {
        const tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        const idSortStr = 'sort=id,desc&';
        const codeSearchStr = '&f_code=0&f_code_op==&subType=ONLINE';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${this.embeddedStr + codeSearchStr}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    // updateBank(id: number, bank: Bank) {
    //     return this.http.put(this.url + '/' + id, bank)
    //         .map((response) => response);
    // }
    //
    // addBank(bank: Bank) {
    //     return this.http.post(this.url, bank)
    //         .map((response) => response);
    // }

}
