import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paging } from "../../models/latipay/paging.model";
import { BaseService } from "../base.service";
var PayCompanyInfoService = /** @class */ (function (_super) {
    tslib_1.__extends(PayCompanyInfoService, _super);
    function PayCompanyInfoService(http) {
        var _this = _super.call(this, 'pay-company-info', http) || this;
        _this.http = http;
        return _this;
        // this.embeddedStr =
    }
    PayCompanyInfoService.prototype.ngOnInit = function () {
    };
    PayCompanyInfoService.prototype.getAllByCode = function () {
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=id,desc&';
        var codeSearchStr = '&f_code=0&f_code_op==&subType=ONLINE';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (this.embeddedStr + codeSearchStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return PayCompanyInfoService;
}(BaseService));
export { PayCompanyInfoService };
