import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Paging } from '../../models/latipay/paging.model';
var OrgUserService = /** @class */ (function (_super) {
    tslib_1.__extends(OrgUserService, _super);
    function OrgUserService(http) {
        var _this = _super.call(this, 'org-user', http) || this;
        _this.http = http;
        return _this;
    }
    OrgUserService.prototype.ngOnInit = function () {
    };
    OrgUserService.prototype.getAllByPagingByOrgId = function (orgId, searchCondition, paging) {
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(this.baseUrl + "merchant-user?" + paging.getPagingStr() + (this.embeddedStr + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllByPagingByOrgIdWithWallet = function (orgId, searchCondition, paging) {
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        var embedded = '&embedded=user-accounts,user-accounts.wallet';
        return this.http.get(this.baseUrl + "merchant-user?" + paging.getPagingStr() + (embedded + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllActiveByPagingByOrgIdWithWallet = function (orgId, searchCondition, paging) {
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==' + '&f_disabled_op==&f_disabled=0';
        var embedded = '&embedded=user-accounts,user-accounts.wallet';
        return this.http.get(this.baseUrl + "merchant-user?" + paging.getPagingStr() + (embedded + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllDisableByPagingByOrgIdWithWallet = function (orgId, searchCondition, paging) {
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==' + '&f_disabled_op==&f_disabled=1';
        var embedded = '&embedded=user-accounts,user-accounts.wallet';
        return this.http.get(this.baseUrl + "merchant-user?" + paging.getPagingStr() + (embedded + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAll = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = '&sort=id,asc';
        return this.http.get(this.baseUrl + "merchant-user?" + tempPaging.getPagingStr() + (this.embeddedStr + searchStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllByOrgId = function (orgId) {
        var paging = new Paging({ pageNumber: 1, pageSize: 10000 });
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        var embedded = '&embedded=organisation';
        return this.http.get(this.baseUrl + "merchant-user?" + paging.getPagingStr() + (embedded + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getRolesIdsByOrgId = function (orgId) {
        var paging = new Paging({ pageNumber: 1, pageSize: 10000 });
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        var str1 = '&f_rolesId=' + 0 + '&f_rolesId_op==';
        return this.http.get(this.baseUrl + "merchant-user?" + paging.getPagingStr() + (this.embeddedStr + str + str1)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getMerchantUser = function (id) {
        var embed = '&embedded=organisation&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        return this.http.get(this.baseUrl + "merchant-user/" + id + embed).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getOneEWalletUsers = function (searchCondition, paging) {
        var embedded = '&embedded=organisation&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        // const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency,organisation.org-bind-account&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        var sortId = '&sort=id,desc';
        return this.http.get(this.baseUrl + "merchant-user?" + (paging.getPagingStr() + embedded + searchCondition + sortId)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getByOrgIdAndMerchantUserId = function (orgId, id) {
        // http://localhost:10000/v1/merchant-user?page=1&size=12&f_organisation.id=26&f_organisation.id_op==&f_id=U000000043&f_id_op==
        var paging = new Paging({ pageNumber: 1, pageSize: 10000 });
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        str += '&f_id=' + id + '&f_id_op==';
        return this.http.get(this.baseUrl + "merchant-user?" + paging.getPagingStr() + (this.embeddedStr + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getMerchantUserWithUserAccounts = function (id) {
        return this.http.get(this.baseUrl + "merchant-user/" + id + "?embedded=user-accounts,user-accounts.wallet").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getMerchantUserWithOut = function (id) {
        var embedded = '&embedded=organisation';
        return this.http.get(this.baseUrl + "merchant-user/" + id + "?embedded=organisation").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.patchMerchantUser = function (id, data) {
        return this.http.patch(this.baseUrl + "merchant-user/" + id, data).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.updateMerchantUser = function (id, data) {
        return this.http.put(this.baseUrl + "merchant-user/" + id, data).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.saveMerchantUser = function (data) {
        return this.http.post(this.baseUrl + "merchant-user", data).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.exportAnalysisUser = function (data, token, timeId) {
        return this.http.post(this.baseUrl + "merchant-user/export-user-analysis", data).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.copyVerifoneApiKey = function (id) {
        return this.http.put(this.baseUrl + "merchant-user/verifone/" + id, null).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllEWalletUsersWithoutPaging = function (searchCondition) {
        var embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        var sortId = '&sort=id,desc';
        var paging = new Paging({ pageNumber: 1, pageSize: 10000 });
        return this.http.get(this.baseUrl + "merchant-user?" + (paging.getPagingStr() + embedded + searchCondition + sortId)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllEWalletUsersUseForMessage = function (searchCondition) {
        var embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        var sortId = '&sort=id,desc';
        var paging = new Paging({ pageNumber: 1, pageSize: 10000 });
        return this.http.get(this.baseUrl + "merchant-user?" + (paging.getPagingStr() + embedded + searchCondition + sortId)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllEWalletUsers = function (searchCondition, paging) {
        var embedded = '&embedded=organisation,organisation.org-bind-account&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        // const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency,organisation.org-bind-account&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        var sortId = '&sort=id,desc';
        return this.http.get(this.baseUrl + "merchant-user/user-list/?" + (paging.getPagingStr() + embedded + searchCondition + sortId)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllMerchanttUsers = function (searchCondition, paging) {
        var embedded = '&embedded=organisation&f_organisation.merchantType=COMPANY&f_organisation.merchantType_op==';
        // const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency,organisation.org-bind-account&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        var sortId = '&sort=id,desc';
        return this.http.get(this.baseUrl + "merchant-user?" + (paging.getPagingStr() + embedded + searchCondition + sortId)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllEWalletInvalidUsers = function (searchCondition, paging) {
        var embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        var sortId = '&sort=id,desc';
        return this.http.get(this.baseUrl + "merchant-user/get-invalid-user?" + (paging.getPagingStr() + embedded + searchCondition + sortId)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllEWalletSMSWithDateInvalidUsers = function (fromStr, toStr, paging) {
        var embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        var sortId = '&sort=id,desc';
        return this.http.get(this.baseUrl + "merchant-user/" + fromStr + "/" + toStr + "/get-sms-date-invalid-user?" + paging.getPagingStr()).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllEWalletSMSInvalidUsers = function (searchCondition, paging) {
        return this.http.get(this.baseUrl + "merchant-user/get-sms-invalid-user?" + (paging.getPagingStr() + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllEWalletValidUsers = function (searchCondition, paging) {
        var embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        var sortId = '&sort=id,desc';
        return this.http.get(this.baseUrl + "merchant-user/get-valid-user?" + (paging.getPagingStr() + embedded + searchCondition + sortId)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllEWalletErrorUsers = function (searchCondition, paging) {
        var embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        var sortId = '&sort=id,desc';
        return this.http.get(this.baseUrl + "merchant-user/get-error-user?" + (paging.getPagingStr() + embedded + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllEWalletUnbindUsers = function (searchCondition, paging) {
        var embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        var sortId = '&sort=id,desc';
        return this.http.get(this.baseUrl + "merchant-user/get-unbind-user?" + (paging.getPagingStr() + embedded + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllEWalletSendSMSUsers = function (searchCondition, paging) {
        var embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        var sortId = '&sort=id,desc';
        return this.http.get(this.baseUrl + "merchant-user/get-send-sms-user?" + (paging.getPagingStr() + embedded + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getAllEWalletNeedValidUsers = function (searchCondition, paging) {
        var embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        var sortId = '&sort=organisation.tradeName,desc';
        return this.http.get(this.baseUrl + "merchant-user/need-valid?" + (paging.getPagingStr() + embedded + searchCondition + sortId)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.exportExcelUrl = function (searchCondition) {
        var sortId = '&sort=id,desc';
        var embedded = '&embedded=organisation,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        return "v1/merchant-user/excel?" + searchCondition + embedded + sortId;
    };
    OrgUserService.prototype.getPhoneCode = function (phone) {
        return this.http.get(this.baseUrl + "merchant-user/get-phone-code/" + phone).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.getUserWithMerchantType = function (id) {
        return this.http.get(this.baseUrl + "merchant-user?&f_id=" + id + "&embedded=organisation").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgUserService.prototype.testSms = function () {
        return this.http.post(this.baseUrl + "merchant-user/test-message", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return OrgUserService;
}(BaseService));
export { OrgUserService };
