import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Paging} from '../../models/latipay/paging.model';
import {AppCoupon} from '../../models/latipay/app-coupon.model';
import {VoucherBatch} from '../../models/latipay/voucher-batch.model';

@Injectable()
export class AppPrizeCodeService extends BaseService implements OnInit {


    constructor(public http: HttpClient) {
        super('app-prize-code', http);
        // this.embeddedStr = '&embedded=currency,organisation,organisation.primary-currency';
    }

    ngOnInit(): void {
    }


    getGenerateCode(id: string) {
        return this.http.get(`${this.url}/generate-code/${id}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    addByNum(value: any) {
        return this.http.post(`${this.url}/add-code`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    addPrizeByNum(value: any) {
        return this.http.post(`${this.url}/by-prize-number`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllCashWallet(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = 'sort=createDate,desc&';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${this.embeddedStr + searchStr + idSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    //  /v1/wallet?f_organisation.id=18&f_organisation.id_op==
    getAllByPagingByOrgId(orgId: number, searchCondition: string, paging: Paging) {
        const sortStr = '&sort=createDate,desc';
        const embededStr = '&embedded=organisation';
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(`${this.baseUrl}wallet?${paging.getPagingStr()}${this.embeddedStr + str + sortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    getAllByPagingByCode(searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,desc';
        const embededStr = '&embedded=prize';
        return this.http.get(`${this.url}?${paging.getPagingStr()}${embededStr + sortStr + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllVoucherBatchByPaging(searchCondition: string, paging: Paging) {
        const sortStr = '&sort=created,desc';

        // const search = '&f_accountName=钱多多';

        return this.http.get(`${this.baseUrl}voucher-batch?${paging.getPagingStr()}${searchCondition + sortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    saveWallet(payeaseType: string, isOnline: boolean, bankAccountId: number, merchantUserId: string | number, value: any) {
        return this.http.post(`${this.url}?merchantUserId=${merchantUserId}&payeaseType=${payeaseType}&isOnline=${isOnline}&bankAccountId=${bankAccountId}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateWallet(payeaseType: string, isOnline: boolean, bankAccountId: number, id: number, value: any) {
        return this.http.put(`${this.url}/${id}?payeaseType=${payeaseType}&isOnline=${isOnline}&bankAccountId=${bankAccountId}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    adjustWalletBalance(id: string, value: any) {
        return this.http.put(`${this.url}/adjust/${id}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllWalletByOpenIds(openIds: string[], paging: Paging) {
        let openIdsStr = '';
        if (openIds) {
            openIds.forEach(openId => {
                openIdsStr += `&openid=${openId}`
            })
        }
        return this.http.get(`${this.url}/openids?${paging.getPagingStr()}${openIdsStr}`)
    }

    getPrefix(id: string) {

        return this.http.get(`${this.url}/get-prefix/${id}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    adjust(id: number, data: VoucherBatch) {
        return this.http.put(`${this.url}/${id}/adjust-number`, data).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
