import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../constants/app.constant';
import {Paging} from '../../models/latipay/paging.model';
import {Organisation} from '../../models/latipay/organisation.model';
import {Branch} from '../../models/latipay/branch.model';
import {BaseService} from '../base.service';
import {Sorts} from '../../models/admin/sorts.model';
import {DDPaymentOrder} from '../../models/latipay/dd-payment-order.model';

@Injectable()
export class DDPaymentOrderService extends BaseService implements OnInit {


    public orgUrl = Constants.API_ENDPOINT + 'v1/organisation';
    public ddUrl = Constants.API_ENDPOINT + 'v1/dd-payment-order';
    public pricingUrl = Constants.API_ENDPOINT + 'v1/pricing-plan';
    public couponUrl = Constants.API_ENDPOINT + 'v1/coupon/merchants';
    public preCouponUrl = Constants.API_ENDPOINT + 'v1/pre-coupon/merchants';

    constructor(public http: HttpClient) {
        super('dd-payment-order', http);
    }

    ngOnInit(): void {

    }

    getAll(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        const tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});

        return this.http.get(`${this.orgUrl}?${tempPaging.getPagingStr()}${searchStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    // getAll() {
    //     return this.http.get(`${this.orgUrl}`)
    //         .map((response) => response).catch((res: any) => Observable.throw(res));
    // }


    getAllDdPaymentOrderByPagingDesc(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        // const embeddedStr = '&embedded=transaction-order';
        const sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${  searchCondition}&sort=created,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    getAllDdPaymentOrderByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        // const embeddedStr = '&embedded=transaction-order';
        const sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${  searchCondition}&sort=created,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByIdsByPaging(ids, searchCondition: string, paging: Paging) {
        const idsSearchArray = [];
        ids.forEach(id => {
            idsSearchArray.push('f_id=' + id);
        });
        let idsSearchStr = '';
        if (idsSearchArray.length > 0) {
            idsSearchStr = idsSearchArray.join('&');
            idsSearchStr = '&' + idsSearchStr + '&f_id_op=IN';
        }
        const embedded = idsSearchStr + '&embedded=branch,onboard,primary-currency,operation-organisations,operation-organisations.operation,organisation-tags,organisation-tags.tag';
        let url = `${this.orgUrl}?${paging.getPagingStr() + searchCondition + embedded}`;
        if (url.indexOf('&f_merchantType_op==&f_merchantType') < 0) {
            url += '&f_merchantType=COMPANY&f_merchantType=INDIVIDUAL&f_merchantType_op=IN'
        }
        return this.http.get(url).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllEWalletMerchantsByPaging(searchCondition: string, paging: Paging) {
        const embedded = '&f_merchantType=EWALLET&f_merchantType_op==&embedded=branch,onboard,primary-currency,operation-organisations,operation-organisations.operation,organisation-tags,organisation-tags.tag';
        return this.http.get(`${this.orgUrl}?${paging.getPagingStr() + searchCondition + embedded}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllCouponMerchantsByPaging(searchCondition: string, paging: Paging) {
        return this.http.get(`${this.couponUrl}?${paging.getPagingStr() + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllPreCouponMerchantsByPaging(searchCondition: string, paging: Paging) {
        return this.http.get(`${this.preCouponUrl}?${paging.getPagingStr() + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getOrg(id: any) {
        const embedded = '?embedded=primary-currency,affiliate,onboard,organisation-tags,organisation-tags.tag,organisation-tags.tag.tag-category';
        return this.http.get(`${this.orgUrl}/${id + embedded}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getPricingList(orgId: any) {
        const str = '?f_merchant.id=' + orgId + '&f_merchant.id_op==';
        return this.http.get(`${this.pricingUrl + str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    update(id: number, org: DDPaymentOrder) {
        return this.http.put(`${this.ddUrl}/${id}`, org).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    // add(org: Organisation) {
    //     return this.http.post(`${this.orgUrl}`, org).pipe(
    //         map((response) => response), catchError((res: any) => observableThrowError(res)),);
    // }

    assign(selectedIds: any, branchId: number) {
        return this.http.post(`${this.orgUrl}/assign?orgIds=${selectedIds}&branchId=${branchId}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    verify(selectedIds: any) {
        return this.http.post(`${this.orgUrl}/verify?orgIds=${selectedIds}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    changeSteps(selectedIds: any, selectedStepId: number) {
        return this.http.post(`${this.orgUrl}/changeSteps?orgIds=${selectedIds}&stepId=${selectedStepId}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    changeStep(item: Organisation) {
        return this.http.post(`${this.orgUrl}/changeStep?orgId=${item.id}&step=${item.onboard.step}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    assignOnbordAndChangeStep(orgId: number, selectedOnboardId: number, selectedOnboardStepId: number) {
        return this.http.post(`${this.orgUrl}/assignOnbordAndChangeStep?onboardId=${selectedOnboardId}&orgId=${orgId}&step=${selectedOnboardStepId}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    importMerchant(importMerchantObject: any) {
        return this.http.post(`${this.orgUrl}/importMerchant`, importMerchantObject).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

}
