import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
var VoucherService = /** @class */ (function (_super) {
    tslib_1.__extends(VoucherService, _super);
    function VoucherService(http) {
        var _this = _super.call(this, 'voucher', http) || this;
        _this.http = http;
        return _this;
        // this.embeddedStr = '&embedded=currency,organisation,organisation.primary-currency';
    }
    VoucherService.prototype.ngOnInit = function () {
    };
    //  /v1/wallet?f_organisation.id=18&f_organisation.id_op==
    VoucherService.prototype.getAllByPagingByOrgId = function (orgId, searchCondition, paging) {
        var sortStr = '&sort=createDate,desc';
        var embededStr = '&embedded=organisation';
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(this.baseUrl + "wallet?" + paging.getPagingStr() + (this.embeddedStr + str + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherService.prototype.getAllVoucherByPaging = function (searchCondition, paging) {
        var sortStr = '&sort=id,desc';
        // const search = '&f_accountName=钱多多';
        return this.http.get(this.baseUrl + "voucher?" + paging.getPagingStr() + (searchCondition + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherService.prototype.saveWallet = function (payeaseType, isOnline, bankAccountId, merchantUserId, value) {
        return this.http.post(this.url + "?merchantUserId=" + merchantUserId + "&payeaseType=" + payeaseType + "&isOnline=" + isOnline + "&bankAccountId=" + bankAccountId, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherService.prototype.updateWallet = function (payeaseType, isOnline, bankAccountId, id, value) {
        return this.http.put(this.url + "/" + id + "?payeaseType=" + payeaseType + "&isOnline=" + isOnline + "&bankAccountId=" + bankAccountId, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherService.prototype.adjustWalletBalance = function (id, value) {
        return this.http.put(this.url + "/adjust/" + id, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherService.prototype.getAllWalletByOpenIds = function (openIds, paging) {
        var openIdsStr = '';
        if (openIds) {
            openIds.forEach(function (openId) {
                openIdsStr += "&openid=" + openId;
            });
        }
        return this.http.get(this.url + "/openids?" + paging.getPagingStr() + openIdsStr);
    };
    VoucherService.prototype.getPrefixAndMax = function (id) {
        var str = "" + id;
        return this.http.get(this.url + "/get-prefix/" + str).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return VoucherService;
}(BaseService));
export { VoucherService };
