import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../constants/app.constant';
import {Paging} from '../models/latipay/paging.model';

export abstract class BaseService {

    public baseUrl = Constants.API_ENDPOINT + 'v1/';
    public baseParaUrl = Constants.API_PARA_ENDPOINT;
    public url: string;
    public embeddedStr = '';

    constructor(public entity: string, public http: HttpClient) {
        this.url = this.baseUrl + entity;
    }

    // public setUrl(entity: string) {
    //     this.url = this.baseUrl + entity;
    // }
    getAll(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = '&sort=queue,asc';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${this.embeddedStr + searchStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    getAllWithoutEmbeded(searchStr?: string) {
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = 'sort=id,desc&';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${searchStr ? searchStr : ''}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    get(id) {
        const str = this.embeddedStr ? `${id}?${this.embeddedStr}` : id;
        return this.http.get(`${this.url}/${str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllByPaging(searchCondition: string, paging: Paging) {
        let idSortStr = '&sort=id,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition + idSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    add(value: any) {
        return this.http.post(`${this.url}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    update(id: number | string, value: any) {
        return this.http.put(`${this.url}/${id}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    onSend(id: any, modeStr: string) {
        return this.http.get(`${this.url}/send/${id}/${modeStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateTicket(id: number | string, itemId: string, value: any) {
        return this.http.put(`${this.url}/${id}?itemId=${itemId}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    updatePreCoupon(id: string | string, itemId: string, value: any) {
        return this.http.put(`${this.url}/${id}?itemId=${itemId}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    patch(id: number, value: any) {
        return this.http.patch(`${this.url}/${id}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

}
