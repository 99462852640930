import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseComponent } from '../../../theme/shared/base-component/base.component';
import { AuthenticationService } from '../../../services/authentication.service';
import { UserService } from '../../../services/user.service';
import { MyNotifyService } from '../../../services/my-notify.service';
import { LayoutService } from '../layout.service';
import { AppTransactionMonitorService } from '../../../services/latipay/app-transaction-monitor.service';
import { OrgOnboardService } from '../../../services/latipay/org-onboard.service';
var NavigationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NavigationComponent, _super);
    function NavigationComponent(authService, userService, layoutService, orgOnboardService, appTransactionMonitorService, myNotifyService) {
        var _this = _super.call(this) || this;
        _this.authService = authService;
        _this.userService = userService;
        _this.layoutService = layoutService;
        _this.orgOnboardService = orgOnboardService;
        _this.appTransactionMonitorService = appTransactionMonitorService;
        _this.myNotifyService = myNotifyService;
        _this.monitorTransactionSize = 0;
        _this.unHandleTicketSize = 0;
        _this.feedbackSize = 0;
        _this.appOnboardSize = 0;
        _this.orgOnboards = [];
        return _this;
    }
    NavigationComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this.startTaskTimer();
        this.getMonitorTransactionSize();
        var schedule = require('node-schedule');
        schedule.scheduleJob('0 0 0-23 * * ?', function () {
            _this.getMonitorTransactionSize();
            // this.getOnboardSize();
            // this.getFeedbackSize();
        });
    };
    // toggleCollapsed(): void {
    //     this.layoutService.onCollapseAntMenu()
    // }
    NavigationComponent.prototype.clearCache = function () {
        var _this = this;
        this.userService.clearCache().subscribe(function (resp) {
            if (resp === true) {
                _this.myNotifyService.notifySuccess('Cache cleared successfully.');
            }
            console.log('ClearCacheResponse:', resp);
        });
    };
    NavigationComponent.prototype.refresh = function () {
    };
    // startTaskTimer() {
    //     IntervalObservable.create(120000).subscribe(() => {
    //
    //
    //         this.getMonitorTransactionSize();
    //     })
    // }
    NavigationComponent.prototype.getMonitorTransactionSize = function () {
        // const str = '&f_status_op==&f_status=0';
        // this.appTransactionMonitorService.getAll(str).subscribe((resp: any) => {
        //     console.log('FullVersionList:', resp);
        //     if (resp && resp.content.length > 0) {
        //         this.monitorTransactionSize = resp.content.length;
        //     }
        //
        //
        // }, err => {
        //
        // });
        var _this = this;
        this.appTransactionMonitorService.getNavigationTask().subscribe(function (resp) {
            console.log('FullVersionList:', resp);
            if (resp) {
                var task = resp.toString().split(',');
                console.log(task);
                _this.monitorTransactionSize = task[0];
                _this.appOnboardSize = task[1];
                _this.feedbackSize = task[2];
                _this.unHandleTicketSize = task[3];
            }
        }, function (err) {
        });
    };
    NavigationComponent.prototype.getFeedbackSize = function () {
        var _this = this;
        var str = '&f_status_op==&f_status=0';
        this.appTransactionMonitorService.getAll(str).subscribe(function (resp) {
            console.log('FullVersionList:', resp);
            if (resp && resp.content.length > 0) {
                _this.monitorTransactionSize = resp.content.length;
            }
        }, function (err) {
        });
    };
    return NavigationComponent;
}(BaseComponent));
export { NavigationComponent };
