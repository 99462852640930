
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../constants/app.constant';
import {Paging} from "../../models/latipay/paging.model";
import {BaseService} from "../base.service";
import {Bank} from '../../models/latipay/bank.model';

@Injectable()
export class OnboardService extends BaseService implements OnInit {


    constructor(public http: HttpClient) {
        super('onboard', http);
    }

    ngOnInit(): void {
    }

    getOnboardByOrgId(id) {
        // return this.http.get(`${this.url}/getByOrgId/${id}?${this.embeddedStr}`)
        //     .map((response) => response).catch((res: any) => Observable.throw(res));
        return this.http.get(`${this.url}?f_organisation.id=${id}&f_organisation.id_op==&embedded=organisation`).pipe(
            map((resp) => resp),catchError((res: any) => observableThrowError(res)),);
    }
}
