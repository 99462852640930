import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../../services/base.service';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {Paging} from '../../models/latipay/paging.model';
import {Constants} from '../../constants/app.constant';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class TaskService extends BaseService {

    constructor(public http: HttpClient) {
        super('task', http);
        this.embeddedStr = '&embedded=admin-user,task-type';
    }

    getAllPendingTaskSize() {
        return this.http.get(`${Constants.API_ENDPOINT}v1/task/pending-size?task-type=1,2,3,4,5,6,7,8,9,10`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllPendingVerifyTask(id: string) {
        const searchCondition = 'f_targetInvoiceId=' + id;
        return this.http.get(`${Constants.API_ENDPOINT}v1/task?`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getMerchantPendingTaskSize() {
        return this.http.get(`${Constants.API_ENDPOINT}v1/task/pending-size?task-type=1,2,3,4,5,6`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getEWalletPendingTaskSize() {
        return this.http.get(`${Constants.API_ENDPOINT}v1/task/pending-size?task-type=7,8,9,10`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
