import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../services/base.service';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
var PricingPlanDefaultService = /** @class */ (function (_super) {
    tslib_1.__extends(PricingPlanDefaultService, _super);
    function PricingPlanDefaultService(http) {
        var _this = _super.call(this, 'pricing-plan-default', http) || this;
        _this.http = http;
        return _this;
    }
    PricingPlanDefaultService.prototype.ngOnInit = function () {
    };
    PricingPlanDefaultService.prototype.getAllExisting = function (id, searchCondition, code, paging) {
        return this.http.post(this.url + "/" + id + "/" + code + "/except-existing?" + paging.getPagingStr() + searchCondition, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return PricingPlanDefaultService;
}(BaseService));
export { PricingPlanDefaultService };
