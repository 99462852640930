import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
var ConfigurationService = /** @class */ (function (_super) {
    tslib_1.__extends(ConfigurationService, _super);
    function ConfigurationService(http) {
        var _this = _super.call(this, 'configuration', http) || this;
        _this.http = http;
        return _this;
    }
    ConfigurationService.prototype.ngOnInit = function () {
    };
    // updateBank(id: number, bank: Bank) {
    //     return this.http.put(this.url + '/' + id, bank)
    //         .map((response) => response);
    // }
    //
    // addBank(bank: Bank) {
    //     return this.http.post(this.url, bank)
    //         .map((response) => response);
    // }
    ConfigurationService.prototype.getAllByPagingByCustomSearch = function (orgId, searchCondition, paging) {
        var idSortStr = 'sort=id,desc&';
        var customStr = '';
        if (!(searchCondition && searchCondition.indexOf('&f_skey=autowithdraw_report_email_for_') >= 0)) {
            customStr = '&f_skey=autowithdraw_report_email_for_';
        }
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + customStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return ConfigurationService;
}(BaseService));
export { ConfigurationService };
