import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
var AutoServiceConfigService = /** @class */ (function (_super) {
    tslib_1.__extends(AutoServiceConfigService, _super);
    function AutoServiceConfigService(http) {
        var _this = _super.call(this, 'auto-service-config', http) || this;
        _this.http = http;
        return _this;
    }
    AutoServiceConfigService.prototype.ngOnInit = function () {
    };
    AutoServiceConfigService.prototype.getAutoConfig = function (id) {
        return this.http.get(this.url + "/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AutoServiceConfigService.prototype.getAllByPagingByOrgId = function (orgId, searchCondition, paging) {
        var sortStr = '&sort=createDate,desc';
        var customStr = '';
        if (orgId > 0) {
            customStr = '&f_orgId=' + orgId + '&f_orgId_op==';
        }
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + customStr + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AutoServiceConfigService.prototype.removeWithdrawal = function (item) {
        return this.http.post(this.url + "/" + item + "/delete-config", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return AutoServiceConfigService;
}(BaseService));
export { AutoServiceConfigService };
