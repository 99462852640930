import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginInfoComponent} from './login-info/login-info.component';
import {LogoutComponent} from './logout/logout.component';
import {NzDropDownModule, NzIconModule, NzMenuModule} from 'ng-zorro-antd';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';

@NgModule({
    imports: [CommonModule, RouterModule, FormsModule, NzDropDownModule, NzMenuModule, NzDropDownModule, NzIconModule],
    declarations: [LoginInfoComponent, LogoutComponent],
    exports: [LoginInfoComponent, LogoutComponent]
})
export class UserModule {
}
