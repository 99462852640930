import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
var AppTransactionMonitorService = /** @class */ (function (_super) {
    tslib_1.__extends(AppTransactionMonitorService, _super);
    function AppTransactionMonitorService(http) {
        var _this = _super.call(this, 'app-trans-monitor', http) || this;
        _this.http = http;
        return _this;
    }
    AppTransactionMonitorService.prototype.ngOnInit = function () {
    };
    AppTransactionMonitorService.prototype.getAllAppCouponByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.getAllCoupons = function (walletId, sortOprions, paging) {
        var sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get(this.url + "/get-coupons/" + walletId + sortStr + paging.getPagingStr() + this.embeddedStr + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.getAllLatipayCouponByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        var str = '&f_issuer_op==&f_issuer=0';
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition + str) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.getAllCouponByBusinessIdByPaging = function (searchCondition, businessId, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var idStr = '&f_businessId_op==&f_businessId=' + businessId;
        var sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get(this.url + "/get-coupons" + sortStr + paging.getPagingStr() + (this.embeddedStr + idStr + searchCondition) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.publishCouponById = function (id) {
        return this.http.post(this.url + "/" + id + "/dispatch", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.getNavigationTask = function () {
        return this.http.post(this.url + "/get-navigation-task", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.getOverviewTask = function () {
        return this.http.post(this.url + "/get-overview-task", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.addExtra = function (id) {
        return this.http.post(this.url + "/" + id + "/create-extra", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.applyCoupon = function (id) {
        return this.http.post(this.url + "/" + id + "/apply", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.copyCoupon = function (id) {
        return this.http.post(this.url + "/" + id + "/copy", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.closeCoupon = function (id) {
        return this.http.post(this.url + "/" + id + "/close", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.updateNote = function (id, data) {
        return this.http.put(this.url + "/update-notes/" + id, data).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.getAllLatipayByPaging = function (searchCondition, paging) {
        var idSortStr = '&sort=id,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_type_op==&f_type=1';
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + idSortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppTransactionMonitorService.prototype.getAllLatipayByMerchantIdByPaging = function (merchantId, searchCondition, paging) {
        var idSortStr = '&sort=id,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_merchantId_op==&f_merchantId=' + merchantId;
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + idSortStr + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return AppTransactionMonitorService;
}(BaseService));
export { AppTransactionMonitorService };
