
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Paging} from '../../models/latipay/paging.model';

@Injectable()
export class OperationOrganisationService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('operation-organisation', http);
    }

    ngOnInit(): void {
    }

    getAllByPagingByMerchantId(merchantId: number, searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,desc';
        const embeddedStr = '&embedded=organisation,operation';
        if (!searchCondition) {
            searchCondition = '';
        }
        const str = '&f_organisation.id=' + merchantId + '&f_organisation.id_op==';
        return this.http.get(`${this.baseUrl}operation-organisation?${paging.getPagingStr()}${embeddedStr + str + sortStr + searchCondition }`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }
}
