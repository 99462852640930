import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../../services/base.service';

@Injectable()
export class ExportFileService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('export-file', http);
    }

    ngOnInit(): void {
    }
}