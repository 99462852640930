<nz-layout>
    <nz-sider [nzTrigger]="null" nzCollapsible [(nzCollapsed)]="isCollapsed" style="background-color: #001529">

        <sa-navigation [needCollapsed]="isCollapsed"></sa-navigation>
    </nz-sider>
    <nz-layout>

        <nz-header style="height: 50px">



            <span class="trigger" style="float: left;padding-bottom: 55px" (click)="toggleCollapsed()"> <i
                    class="fa fa-reorder"></i></span>
            <span style="float: right; padding-right: 10px;">
                      <sa-logout></sa-logout>
                </span>

            <div>


                <span style="float: right; padding-right: 10px;">
                  <a href="#/pages/profile">
	           <img src="assets/img/avatars/sunny-big.png"
                    style="height: 20px;width: 20px;margin-bottom: 2px;margin-left: 10px"
                    alt="me"/><span style="margin-left: 2px;color: #0e90d2">Hi, {{user.name}}</span>
                </a>
                </span>
                <span style="float: right; "><sa-activities [userType]="'E-Wallet'"></sa-activities></span>
                <span style="float: right; padding-right: 10px;"><sa-activities
                        [userType]="'Merchant'"></sa-activities></span>
                <span style="float: right; padding-right: 10px;"><sa-activities
                        [userType]="'Feedback'"></sa-activities></span>
                <span style="float: right; padding-right: 20px;"><a href="/#/pages/organisations/task-overview"><i
                        class="far fa-calendar-alt" style="font-size: 20px"></i></a></span>

            </div>


        </nz-header>

        <nz-content>
            <div class="inner-content">
                <router-outlet></router-outlet>
            </div>
            <!--<div id="main" role="main">-->
            <!--&lt;!&ndash;<sa-layout-switcher></sa-layout-switcher>&ndash;&gt;-->

            <!--&lt;!&ndash;<sa-ribbon></sa-ribbon>&ndash;&gt;-->

            <!---->
            <!--</div>-->
        </nz-content>
        <nz-footer>
            <sa-footer></sa-footer>
        </nz-footer>
    </nz-layout>


</nz-layout>

<!--<sa-shortcut></sa-shortcut>-->
