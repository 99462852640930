export class BankAccount {
    accountCountry: string;
    accountName: string;
    accountNumber: string;
    bankName: string; // ??
    registerBank: string;
    branchAddress: string;
    branchName: string;
    clearingCode: string;
    clearingCodeType: string;
    createdAt: number;
    currency: string;
    id: number;
    walletId: string;
    settlementReference: string;
    bankAccountId: number;
    defaultEnable: number;
    merchantId: number;
    delete: number;
    swiftCode: string;
    updatedAt: number;
    files: any;
    status: number;
}
