import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../services/base.service';
import { throwError as observableThrowError } from 'rxjs';
import { Constants } from '../../constants/app.constant';
import { catchError, map } from 'rxjs/operators';
var TaskService = /** @class */ (function (_super) {
    tslib_1.__extends(TaskService, _super);
    function TaskService(http) {
        var _this = _super.call(this, 'task', http) || this;
        _this.http = http;
        _this.embeddedStr = '&embedded=admin-user,task-type';
        return _this;
    }
    TaskService.prototype.getAllPendingTaskSize = function () {
        return this.http.get(Constants.API_ENDPOINT + "v1/task/pending-size?task-type=1,2,3,4,5,6,7,8,9,10").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TaskService.prototype.getAllPendingVerifyTask = function (id) {
        var searchCondition = 'f_targetInvoiceId=' + id;
        return this.http.get(Constants.API_ENDPOINT + "v1/task?").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TaskService.prototype.getMerchantPendingTaskSize = function () {
        return this.http.get(Constants.API_ENDPOINT + "v1/task/pending-size?task-type=1,2,3,4,5,6").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TaskService.prototype.getEWalletPendingTaskSize = function () {
        return this.http.get(Constants.API_ENDPOINT + "v1/task/pending-size?task-type=7,8,9,10").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return TaskService;
}(BaseService));
export { TaskService };
