import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Paging} from '../../models/latipay/paging.model';
import {Headers, Http, RequestOptions} from '@angular/http';

@Injectable()
export class StaticQrCodeService extends BaseService implements OnInit {


    constructor(public http: HttpClient,
                public httpP: Http) {
        super('static-qr-code', http);
        // this.embeddedStr = '&embedded=currency,organisation,organisation.primary-currency';
    }

    ngOnInit(): void {
    }

    getAllCashWallet(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = 'sort=createDate,desc&';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${this.embeddedStr + searchStr + idSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    //  /v1/wallet?f_organisation.id=18&f_organisation.id_op==
    getAllByPagingByOrgId(orgId: number, searchCondition: string, paging: Paging) {
        const sortStr = '&sort=createDate,desc';
        const embededStr = '&embedded=organisation';
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(`${this.baseUrl}wallet?${paging.getPagingStr()}${this.embeddedStr + str + sortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByPaging(searchCondition: string, paging: Paging) {
        const sortStr = '&sort=modifyDate,desc';
        // const embededStr = '&embedded=organisation';
        // const search = '&f_accountName=钱多多';
        return this.http.get(`${this.baseUrl}prepaid-wallet?${paging.getPagingStr()}${this.embeddedStr + searchCondition + sortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    saveWallet(payeaseType: string, isOnline: boolean, bankAccountId: number, merchantUserId: string | number, value: any) {
        return this.http.post(`${this.url}?merchantUserId=${merchantUserId}&payeaseType=${payeaseType}&isOnline=${isOnline}&bankAccountId=${bankAccountId}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateWallet(payeaseType: string, isOnline: boolean, bankAccountId: number, id: number, value: any) {
        return this.http.put(`${this.url}/${id}?payeaseType=${payeaseType}&isOnline=${isOnline}&bankAccountId=${bankAccountId}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    adjustWalletBalance(id: string, value: any) {
        return this.http.put(`${this.url}/adjust/${id}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllWalletByOpenIds(openIds: string[], paging: Paging) {
        let openIdsStr = '';
        if (openIds) {
            openIds.forEach(openId => {
                openIdsStr += `&openid=${openId}`
            })
        }
        return this.http.get(`${this.url}/openids?${paging.getPagingStr()}${openIdsStr}`)
    }

    // generateCode(id: number, value: any, token: string) {
    //     const header: Headers = new Headers();
    //     header.append('Content-Type', 'application/json');
    //     header.append('Authorization', token);
    //     const options = new RequestOptions({headers: header});
    //     return this.httpP.post(`${this.url}/${id}/add-sessions`, value, options).pipe(
    //         map((response) => response), catchError((res: any) => observableThrowError(res)));
    // }


    generateCode(value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        return this.httpP.post(`${this.url}/generate-qr-code`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    updateCode(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        return this.httpP.patch(`${this.url}/${id}/update-qr-code`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
