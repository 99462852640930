import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';
import {Headers, Http, RequestOptions} from '@angular/http';

@Injectable()
export class OrganisationAddOnService extends BaseService implements OnInit {

    constructor(public http: HttpClient,
                public httpP: Http) {
        super('organisation-add-on', http);
    }

    ngOnInit(): void {
    }


    getAllClawSessionsByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const filterStr = '&f_businessId_op==&f_businessId=54'
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition + filterStr}&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    getAllTicketSessionsByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByBusinessIdByPaging(searchCondition: string, businessId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }

        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc` + (businessId ? `&f_businessId=${businessId}&f_businessId_op==` : '')).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByWalletIdsByPaging(walletIds: string[], searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,asc';
        let walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(id => {
                walletIdsStr += `&f_acceptedMerchants=${id}`;
            })
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${walletIdsStr}${sortStr}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res))
        );
    }

    publishCouponById(id: string) {
        return this.http.post(`${this.url}/${id}/dispatch`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateAllQueue(id: number) {
        return this.http.post(`${this.url}/${id}/update-all-queue`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    setTop(id: number) {
        return this.http.post(`${this.url}/${id}/set-top`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    cancelTop(id: number) {
        return this.http.post(`${this.url}/${id}/remove-top`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    setUp(id: number) {
        return this.http.post(`${this.url}/${id}/go-up`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    setDown(id: number) {
        return this.http.post(`${this.url}/${id}/go-down`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    addSessions(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/${id}/add-sessions`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
