import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';
import {PromoCategory} from '../../models/latipay/promo-category.model';
import {Headers, Http, RequestOptions} from '@angular/http';

@Injectable()
export class AppActivityLogService extends BaseService implements OnInit {

    constructor(public http: HttpClient,
                public httpP: Http) {
        super('app-activity-log', http);
    }

    ngOnInit(): void {
    }

    getAllCategory(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = '&sort=queue,asc';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${this.embeddedStr + searchStr + idSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllArea(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        const embed = '&embedded=parent-area';
        let idSortStr = '&sort=queue,asc';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${searchStr + embed}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    getAllTopicByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByActivityIdByPaging(searchCondition: string, aId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const str = '&f_activityId_op==&f_activityId=' + aId;
        const embed = '&embedded=prize';

        return this.http.get(`${this.url}?${paging.getPagingStr()}${embed + searchCondition + str}&sort=createdOn,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByWalletIdsByPaging(walletIds: string[], searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,asc';
        let walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(id => {
                walletIdsStr += `&f_acceptedMerchants=${id}`;
            })
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${walletIdsStr}${sortStr}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res))
        );
    }

    publishCouponById(id: string) {
        return this.http.post(`${this.url}/${id}/dispatch`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    // updatePromoCategoryList(data: PromoCategory[], token: string) {
    //     const header: Headers = new Headers();
    //     header.append('Content-Type', 'application/json');
    //     header.append('Authorization', token);
    //     const options = new RequestOptions({headers: header});
    //     return this.httpP.post(`${this.url}/${promoId}/update-link`, value, options).pipe(
    //         map((response) => response), catchError((res: any) => observableThrowError(res)));
    // }

    swapCategoryQueue(origin: string, target: string) {
        return this.http.post(`${this.url}/${origin}/${target}/swap`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updatePromoCategoryList(value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/update-sort`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

}
