import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AppPicturePipe} from './appPicture/appPicture.pipe';
import {ProfilePicturePipe} from './profilePicture/profilePicture.pipe';
import {MailSearchPipe} from './search/mail-search.pipe';
import {SearchPipe} from './search/search.pipe';
import {FourDecimalPipe} from './decimalPipe/four-decimal.pipe';
import {TwoDecimalPipe} from './decimalPipe/two-decimal.pipe';
import {SecondsToTimePipe} from './secondsToTimePipe/seconds-to-time';
import {PermissionFilterPipe} from './search/permission-filter.pipe';
import {GatewayPipe} from './enums/gateway.pipe';
import {CurrencyPipe} from './enums/currency.pipe';
import {CommonStatusPipe} from './enums/commonstatus.pipe';
import {CountryPipe} from './enums/country.pipe';
import {SettleStatusPipe} from './enums/settlestatus.pipe';
import {SettletypePipe} from './enums/settletype.pipe';
import {ArraySortPipe} from './enums/array-sort.pipe';
import {MerchantRolePipe} from './enums/merchant-role.pipe';
import {PaymentMethodPipe} from './enums/payment-method.pipe';
import {StepPipe} from './enums/step.pipe';
import {InvoiceStatusPipe} from './enums/invoice.status.pipe';
import {FullVersionStatusPipe} from './enums/full-version.status.pipe';
import {AutoServiceTypePipe} from './enums/auto-service-type.pipe';
import {FullVersionStepPipe} from './enums/full-version.step.pipe';
import {FullVersionStepV2Pipe} from './enums/full-verison.step.v2.pipe';
import {CouponTypePipe} from './enums/coupon-type.pipe';
import {EPayStatusPipe} from './enums/e-pay-status.pipe';
// import {VgstatusPipe} from './enums/vgstatus.pipe';
import {VirtualGoodStatusPipe} from './enums/VirtualGoodStatus.pipe';
import {TypePipe} from './enums/type.pipe';
import {OfferPipe} from './enums/offer.pipe';
import {DisplayNTRPipe} from './displayNTRPipe/display-ntr.pipe';
import {DisplayQuantityPipe} from './displayQuantityPipe/display-quantity.pipe';
import {DisplayBindCardPipe} from './displayAppUsers/display-bind-card.pipe';
import {DisplayGenderPipe} from './displayAppUsers/display-gender.pipe';
import {DisplayBirthPipe} from './displayAppUsers/display-birth.pipe';
import {DisplaySystemOrderIdPipe} from './displaySystemOrderId/display-system-order-id.pipe';
import {DisplayCouponNumberPipe} from './displayCouponNumberPipe/display-coupon-number.pipe';
import {DisplayPreCouponNumberPipe} from './displayPreCouponNumberPipe/display-precoupon-number.pipe';
import {DisplayCashAmountPipe} from './displayCashAmountPipe/display-cash-amount.pipe';
import {DisplayCreditAmountPipe} from './displayCreditAmountPipe/display-credit-amount.pipe';
import {DisplayVirtualNamePipe} from './displayVirtualNamePipe/display-virtual-name.pipe';
import {DisplayAdminUserNamePipe} from './displayAdminUserNamePipe/display-admin-user-name.pipe';
import {DisplayVirtualIdPipe} from './displayVirtualIdPipe/display-virtual-id.pipe';
import {HideBarcodePipe} from './hideBarcodePipe/hide-barcode.pipe';
import {DisplayMovieNameCnPipe} from './displayMovieNameCnPipe/display-movie-name-cn.pipe';
import {DisplayMovieTimePipe} from './displayMovieTimePipe/display-movie-time.pipe';
import {DisplayValidToPipe} from './displayValidToPipe/display-valid-to.pipe';
import {DisplayMovieReleaseStatusPipe} from './displayMovieReleaseStatusPipe/display-movie-release-status.pipe';
import {DisplayKeywordNumPipe} from './displayKeywordNumPipe/display-keyword-num.pipe';
import {DisplaySearchIn30Pipe} from './displaySearchInPipe/display-search-in-30.pipe';
import {DisplaySearchIn90Pipe} from './displaySearchInPipe/display-search-in-90.pipe';
import {DisplaySearchIn60Pipe} from './displaySearchInPipe/display-search-in-60.pipe';
import {DisplayMovieTypePipe} from './displayMovieTypePipe/display-movie-type.pipe';
import {DisplayMovieCostPipe} from './displayMovieTypePipe/display-movie-cost.pipe';
import {DisplayMovieNTRPipe} from './displayNTRPipe/display-movie-ntr.pipe';
import {DisplayMovieQuantityPipe} from './displayQuantityPipe/display-movie-quantity.pipe';
import {DisplayTransRankPipe} from './displayTransRankPipe/display-trans-rank.pipe';
import {DisplayPromoValidPipe} from './displayPromoValidPipe/display-promo-valid.pipe';
import {DisplayPromoCategoryNamePipe} from './displayPromoCategoryNamePipe/display-promo-category-name.pipe';
import {DisplayClawCranePricePipe} from './displayClawCranePricePipe/display-claw-crane-price.pipe';
import {DisplayDdErrorCodePipe} from './displayDDErrorCodePipe/display-dd-error-code.pipe';
import {DisplayPrecouponRecordTitle} from './displayPrecouponRecordTitlePipe/display-precoupon-record-title.pipe';
import {DisplayParentCategoryNamePipe} from './displayParentCategoryNamePipe/display-parent-category-name.pipe';
import {DisplayUserAnalysisTag} from './displayUserAnalysisTagPipe/display-user-analysis-tag.pipe';
import {DisplayRaffleStartDatePipe} from './displayRaffleStartDatePipe/display-raffle-start-date.pipe';
import {DisplayAppCouponTypePipe} from './displayAppCouponTypePipe/display-app-coupon-type.pipe';
import {DisplayTransPricePipe} from './displayTransPricePipe/display-trans-price.pipe';
import {DisplayTransDiscountPipe} from './displayTransDiscountPipe/display-trans-discount.pipe';
import {DisplayUtcAsLocalPipe} from './displayUTCAsLocalPipe/display-utc-as-local.pipe';
import {DisplayPrizeStockPipe} from './displayPrizeStockPipe/display-prize-stock.pipe';
import {DisplayWeekPipe} from './displayWeekPipe/display-week.pipe';
import {DisplayChineseDatePipe} from '../displayChineseDatePipe/display-chinese-date.pipe';
import {AppTransactionMonitorStatusPipe} from './enums/app-transaction-monitor.status.pipe';
import {DisplayMonitorNotesPipe} from './displayMonitorNotesPipe/display-monitor-notes.pipe';
import {DisplayMonitorNotesAdminUserPipe} from './displayMonitorNotesAdminUserPipe/display-monitor-notes-admin-user.pipe';
import {DisplayCashAmountBalancePipe} from './displayCashAmountBalancePipe/display-cash-amount-balance.pipe';
import {DisplayCouponCashWalletFeePipe} from './displayCouponCashWalletFeePipe/display-coupon-cash-wallet-fee.pipe';
import {DisplayAmountPipe} from './displayAmountPipe/display-amount.pipe';
import {DisplayEcomProductPipe} from './displayNTRPipe/display-ecom-product.pipe';
import {DisplayUserAccountWalletPipe} from './displayUserAccountWalletPipe/display-user-account-wallet.pipe';
import {DisplayUserEnableWalletCountPipe} from './displayUserEnableWalletCountPipe/display-user-enable-wallet-count.pipe';
import {DisplayBusinessCategoryPipe} from './displayBusinessCategoryPipe/display-business-category.pipe';

// shared module
// do not provide services in Shared Modules! Especially not if you plan to use them in Lazy Loaded Modules!
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        // the pipe should declare once some where and only once.
        AppPicturePipe,
        ProfilePicturePipe,
        MailSearchPipe,
        DisplayCreditAmountPipe,
        SearchPipe,
        DisplayCouponNumberPipe,
        DisplayCashAmountPipe,
        FourDecimalPipe,
        TwoDecimalPipe,
        SecondsToTimePipe,
        PermissionFilterPipe,
        GatewayPipe,
        DisplayUserAccountWalletPipe,
        DisplayCashAmountBalancePipe,
        DisplayNTRPipe,
        DisplayKeywordNumPipe,
        CurrencyPipe,
        CommonStatusPipe,
        VirtualGoodStatusPipe,
        CountryPipe,
        SettleStatusPipe,
        DisplayVirtualNamePipe,
        DisplayDdErrorCodePipe,
        DisplayBindCardPipe,
        DisplayAmountPipe,
        SettletypePipe,
        DisplayRaffleStartDatePipe,
        DisplayClawCranePricePipe,
        DisplayPromoValidPipe,
        ArraySortPipe,
        DisplayParentCategoryNamePipe,
        AppTransactionMonitorStatusPipe,
        DisplaySearchIn30Pipe,
        DisplaySearchIn60Pipe,
        DisplaySearchIn90Pipe,
        MerchantRolePipe,
        DisplayUserAnalysisTag,
        DisplayPromoCategoryNamePipe,
        DisplayVirtualIdPipe,
        DisplaySystemOrderIdPipe,
        DisplayQuantityPipe,
        HideBarcodePipe,
        DisplayAdminUserNamePipe,
        StepPipe,
        DisplayBirthPipe,
        DisplayBusinessCategoryPipe,
        DisplayMovieTimePipe,
        DisplayMovieNameCnPipe,
        TypePipe,
        DisplayPrecouponRecordTitle,
        DisplayGenderPipe,
        OfferPipe,
        DisplayPreCouponNumberPipe,
        DisplayMovieCostPipe,
        DisplayMovieNTRPipe,
        DisplayTransPricePipe,
        DisplayMonitorNotesPipe,
        DisplayTransDiscountPipe,
        DisplayAppCouponTypePipe,
        DisplayWeekPipe,
        PaymentMethodPipe,
        DisplayChineseDatePipe,
        InvoiceStatusPipe,
        DisplayCouponCashWalletFeePipe,
        DisplayTransRankPipe,
        FullVersionStatusPipe,
        FullVersionStepPipe,
        DisplayPrizeStockPipe,
        DisplayMovieReleaseStatusPipe,
        DisplayValidToPipe,
        DisplayUtcAsLocalPipe,
        DisplayMovieQuantityPipe,
        DisplayUserEnableWalletCountPipe,
        DisplayEcomProductPipe,
        DisplayMonitorNotesAdminUserPipe,
        DisplayMovieTypePipe,
        FullVersionStepV2Pipe,
        AutoServiceTypePipe,
        CouponTypePipe,
        EPayStatusPipe
    ],
    exports: [
        AppPicturePipe,
        ProfilePicturePipe,
        MailSearchPipe,
        SearchPipe,
        DisplayPrizeStockPipe,
        DisplayBirthPipe,
        DisplayMovieNTRPipe,
        FourDecimalPipe,
        DisplayTransPricePipe,
        DisplayCashAmountBalancePipe,
        DisplayMovieQuantityPipe,
        DisplayKeywordNumPipe,
        DisplayCouponCashWalletFeePipe,
        DisplayValidToPipe,
        DisplayAmountPipe,
        DisplayBusinessCategoryPipe,
        DisplayEcomProductPipe,
        DisplayUserEnableWalletCountPipe,
        AppTransactionMonitorStatusPipe,
        DisplayMonitorNotesAdminUserPipe,
        DisplayTransDiscountPipe,
        DisplayUtcAsLocalPipe,
        DisplayUserAccountWalletPipe,
        DisplayMovieTimePipe,
        DisplayRaffleStartDatePipe,
        DisplayChineseDatePipe,
        DisplaySearchIn30Pipe,
        DisplayUserAnalysisTag,
        DisplaySearchIn60Pipe,
        DisplayPrecouponRecordTitle,
        DisplayParentCategoryNamePipe,
        DisplaySearchIn90Pipe,
        DisplayMovieReleaseStatusPipe,
        DisplayGenderPipe,
        DisplayCreditAmountPipe,
        DisplayPromoValidPipe,
        DisplayAppCouponTypePipe,
        TwoDecimalPipe,
        DisplayPromoCategoryNamePipe,
        SecondsToTimePipe,
        PermissionFilterPipe,
        GatewayPipe,
        DisplayWeekPipe,
        DisplayClawCranePricePipe,
        DisplayMovieNameCnPipe,
        CurrencyPipe,
        HideBarcodePipe,
        CommonStatusPipe,
        DisplayBindCardPipe,
        VirtualGoodStatusPipe,
        DisplayVirtualNamePipe,
        DisplayDdErrorCodePipe,
        DisplayAdminUserNamePipe,
        DisplayVirtualIdPipe,
        DisplaySystemOrderIdPipe,
        CountryPipe,
        SettleStatusPipe,
        DisplayNTRPipe,
        DisplayQuantityPipe,
        SettletypePipe,
        DisplayMonitorNotesPipe,
        ArraySortPipe,
        DisplayMovieCostPipe,
        MerchantRolePipe,
        StepPipe,
        DisplayTransRankPipe,
        TypePipe,
        OfferPipe,
        DisplayMovieTypePipe,
        DisplayCashAmountPipe,
        DisplayCouponNumberPipe,
        DisplayPreCouponNumberPipe,
        PaymentMethodPipe,
        InvoiceStatusPipe,
        FullVersionStatusPipe,
        FullVersionStepPipe,
        FullVersionStepV2Pipe,
        AutoServiceTypePipe,
        CouponTypePipe,
        EPayStatusPipe
    ]
})
export class PipesModule {
}
