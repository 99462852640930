import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Paging} from '../../models/latipay/paging.model';

@Injectable()
export class BankAccountService extends BaseService implements OnInit {


    constructor(public http: HttpClient) {
        super('bank-account', http);
    }

    ngOnInit(): void {
    }

    getAllByPagingByOrgId(orgId: number, searchCondition: string, paging: Paging) {
        const sortStr = '&sort=currency,asc&sort=createDate,desc';
        const embededStr = '&embedded=organisation';
        if (!searchCondition) {
            searchCondition = '';
        }
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(`${this.baseUrl}bank-account?${paging.getPagingStr()}${this.embeddedStr + str + sortStr + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    getAllByPagingByOrgIdByWalletId(orgId: number, walletId: string, searchCondition: string, paging: Paging) {
        const sortStr = '&sort=currency,asc&sort=createDate,desc';
        const embededStr = '&embedded=organisation,wallet-bank-accounts';
        if (!searchCondition) {
            searchCondition = '';
        }
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==&f_walletBankAccounts.walletId=' + walletId;
        return this.http.get(`${this.baseUrl}bank-account?${paging.getPagingStr()}${this.embeddedStr + str + sortStr + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllByOrgId(orgId: number) {
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        const sortStr = '&sort=currency,asc&sort=createDate,desc';
        const embededStr = '&embedded=organisation';
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(`${this.baseUrl}bank-account?${tempPaging.getPagingStr()}${this.embeddedStr + str + sortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    setDefault(id: number) {
        return this.http.put(`${this.baseUrl}bank-account/${id}/set-default`, {}).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    activeAccount(id: number) {
        return this.http.put(`${this.baseUrl}bank-account/${id}/activate`, {}).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    verify(id: number) {
        return this.http.put(`${this.baseUrl}bank-account/${id}/verify`, {}).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    delete(id: number) {
        return this.http.put(`${this.baseUrl}bank-account/${id}/delete`, {}).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }
}
