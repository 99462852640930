import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paging } from '../../models/latipay/paging.model';
import { Headers, Http, RequestOptions } from '@angular/http';
var AppShipOptionService = /** @class */ (function (_super) {
    tslib_1.__extends(AppShipOptionService, _super);
    function AppShipOptionService(http, httpP) {
        var _this = _super.call(this, 'app-ship-option', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        return _this;
    }
    AppShipOptionService.prototype.ngOnInit = function () {
    };
    AppShipOptionService.prototype.getAllCategory = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = '&sort=queue,asc';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (this.embeddedStr + searchStr + idSortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppShipOptionService.prototype.getAllArea = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var embed = '&embedded=parent-area';
        var idSortStr = '&sort=queue,asc';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (searchStr + embed)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppShipOptionService.prototype.getAllShipsByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppShipOptionService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppShipOptionService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppShipOptionService.prototype.publishCouponById = function (id) {
        return this.http.post(this.url + "/" + id + "/dispatch", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    // updatePromoCategoryList(data: PromoCategory[], token: string) {
    //     const header: Headers = new Headers();
    //     header.append('Content-Type', 'application/json');
    //     header.append('Authorization', token);
    //     const options = new RequestOptions({headers: header});
    //     return this.httpP.post(`${this.url}/${promoId}/update-link`, value, options).pipe(
    //         map((response) => response), catchError((res: any) => observableThrowError(res)));
    // }
    AppShipOptionService.prototype.swapCategoryQueue = function (origin, target) {
        return this.http.post(this.url + "/" + origin + "/" + target + "/swap", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppShipOptionService.prototype.updatePromoCategoryList = function (value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-sort", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return AppShipOptionService;
}(BaseService));
export { AppShipOptionService };
