import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class TagCategoryService extends BaseService {
    constructor(public http: HttpClient) {
        super('tag-category', http);
    }
}
