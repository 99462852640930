
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Constants} from '../constants/app.constant';
import {Beneficiary} from '../models/beneficiary.model';
import {MyHttp} from './http.client.service';
import {PayerSubmit} from '../models/payer.submit.model';
import {Role} from '../models/admin/role.model';
import {Sort} from '../models/admin/sort.model';
import {Sorts} from '../models/admin/sorts.model';
import {Rule} from "../models/admin/rule.model";
import {Permission} from "../models/admin/permission.model";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class RoleService {
    public token: string;
    public roleUrl = Constants.API_ENDPOINT + 'v1/role';
    public ruleUrl = Constants.API_ENDPOINT + 'v1/rule';
    public rulePermissionUrl = Constants.API_ENDPOINT + 'v1/role-permission';
    public permissionUrl = Constants.API_ENDPOINT + 'v1/permission';
    public entityUrl = Constants.API_ENDPOINT + 'sys/entity';
    public sysPermissionUrl = Constants.API_ENDPOINT + 'sys/permission';

    constructor(private http: HttpClient) {
    }

    getRoles(sorts: Sorts) {
        return this.http.get(this.roleUrl + sorts.getSortString()).pipe(
            map((response) => response));
    }

    getAllRoles() {
        return this.http.get(this.roleUrl).pipe(
            map((response) => response));
    }

    updateRole(id: number, role: Role) {
        return this.http.put(this.roleUrl + '/' + id, role).pipe(
            map((response) => response));
    }

    getRules() {
        return this.http.get(this.ruleUrl).pipe(
            map((response) => response));
    }

    getRolePermissionByRoleId(id: number) {
        return this.http.get(this.rulePermissionUrl + '/' + id).pipe(
            map((response) => response));
    }

    getRole(id: number) {
        return this.http.get(this.roleUrl + '/' + id);
    }

    addRule(value: Rule) {
        return this.http.post(this.ruleUrl, value).pipe(
            map((response) => response));
    }

    updateRule(id: number, value: Rule) {
        return this.http.put(this.ruleUrl + '/' + id, value).pipe(
            map((response) => response));
    }

    getRule(id: number) {
        return this.http.get(this.ruleUrl + '/' + id).pipe(
            map((response) => response));
    }

    getRolePermissios() {
        const embedded = '?embedded=rules,permission';
        return this.http.get(this.permissionUrl).pipe(
            map((response) => response));
    }

    // http://api.ccfxtrader.com/v1/payer/2/payee?page=1&size=12
    getPermissiosByPaging(search: string, pageNumber: number, pageSize: number) {
        return this.http.get(`${this.permissionUrl}?page=${pageNumber}&size=${pageSize}${search}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getPermission(id: any) {
        return this.http.get(`${this.permissionUrl}/${id}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    addPermission(value: Permission) {
        return this.http.post(`${this.permissionUrl}`, value).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    updatePermission(id: any, value: Permission) {
        return this.http.put(`${this.permissionUrl}/${id}`, value).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getEntityList() {
        return this.http.get(this.entityUrl).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    addEntity(entity: string) {
        return this.http.post(this.sysPermissionUrl + '?entityName=' + entity, null).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

}
