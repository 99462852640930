import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Paging } from '../../models/latipay/paging.model';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
var CouponUsedService = /** @class */ (function (_super) {
    tslib_1.__extends(CouponUsedService, _super);
    function CouponUsedService(http) {
        var _this = _super.call(this, 'coupon_used', http) || this;
        _this.http = http;
        return _this;
    }
    CouponUsedService.prototype.ngOnInit = function () {
    };
    CouponUsedService.prototype.getAllByOrderIds = function (orderIds) {
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=id,asc&';
        var orderIdsStr = '';
        if (orderIds) {
            orderIds.forEach(function (openId) {
                orderIdsStr += "&f_transactionOrderId=" + openId;
            });
        }
        if (orderIdsStr) {
            orderIdsStr += '&f_transactionOrderId_op=IN';
        }
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + this.embeddedStr + orderIdsStr).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    CouponUsedService.prototype.getAllByCouponCodes = function (couponCodes) {
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=id,asc&';
        var couponCodesStr = '';
        if (couponCodes) {
            couponCodes.forEach(function (code) {
                couponCodesStr += "&f_couponCode=" + code;
            });
        }
        if (couponCodesStr) {
            couponCodesStr += '&f_couponCode_op=IN';
        }
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + this.embeddedStr + couponCodesStr).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return CouponUsedService;
}(BaseService));
export { CouponUsedService };
