import './lib'

import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode} from '@angular/core';
import {environment} from './environments/environment';
import {AppModule} from './app/app.module';

if (environment.production) {
    enableProdMode();
    // window.console.log = function () {
    // };
    // const methods = ['log', 'debug', 'warn', 'info'];
    // for (let i = 0; i < methods.length; i++) {
    //     console[methods[i]] = function () {
    //     };
    // }
}

platformBrowserDynamic().bootstrapModule(AppModule);
