import {Ingredient} from '../../shared/ingredient.model';
import {SystemOptionsActions} from './actions/system-options.actions';
import {SystemOptionsInitialStateConstant} from './system-options-initial-state.constant';
import {SystemOptionsActionConstants} from './actions/system-options-action.constant';


export function systemOptionsListReducer(state = SystemOptionsInitialStateConstant, action: SystemOptionsActions) {
    switch (action.type) {
        case (SystemOptionsActionConstants.SET_CURRENCIES):
            console.log('CurrencyActions.SET_CURRENCIES');
            return {
                ...state,
                currencies: [...action.payload]
            };
        // case (SystemOptionsActionConstants.ADD_CURRENCY):
        //     return {
        //         ...state,
        //         currencies: [...state.currencies, action.payload]
        //     };
        case (SystemOptionsActionConstants.UPDATE_CURRENCY):
            const adminUser = state.currencies[action.payload.index];
            const updatedCurrency = {
                ...adminUser,
                ...action.payload.updatedCurrency
            };
            const currencies = [...state.currencies];
            currencies[action.payload.index] = updatedCurrency;
            return {
                ...state,
                currencies: currencies
            };
        case (SystemOptionsActionConstants.DELETE_CURRENCY):
            const oldCurrencies = [...state.currencies];
            oldCurrencies.splice(action.payload, 1);
            return {
                ...state,
                currencies: oldCurrencies
            };
        default:
            return state;
    }
}
