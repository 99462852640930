import {Injectable} from '@angular/core';

import {NotificationService} from '../shared/utils/notification.service';

@Injectable()
export class MyNotifyService {
    constructor(private notificationService: NotificationService) {
    }

    notifySuccess(content: String) {
        this.notificationService.smallBox({
            title: 'Congratulations',
            content: content,
            color: '#356635',
            timeout: 5000,
            icon: 'fa fa-check'
        });
    }

    notifyFail(content: string) {
        this.notificationService.smallBox({
            title: 'Sorry',
            content: content,
            color: '#953b39',
            timeout: 3000,
            icon: 'fa fa-remove'
        });
    }
    notifyWarning(content: string) {
        this.notificationService.smallBox({
            title: 'Warning',
            content: content,
            color: '#CC9900',
            timeout: 3000,
            icon: 'fa fa-check'
        });
    }


}
