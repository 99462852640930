import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {User} from '../models/user.model';
import {AdminUser} from '../models/admin/admin-user.model';
import {Payer} from '../models/payer.model';
import {Permission} from '../models/admin/permission.model';
import {Wallet} from '../models/latipay/wallet.model';
import {text} from '@angular/core/src/render3';

@Injectable()
export class MessageService {
    subject = new Subject<any>();
    payerSubject = new Subject<Payer>();
    permissionSubject = new Subject<Permission>();

    permission: Permission;
    walletSubject= new Subject<Wallet>();
    orgIdSubject = new Subject<number>();
    orgTaskUpdateEventSubject = new Subject<string>();

    sendUser(user: AdminUser) {
        this.subject.next(user);
    }

    clear() {
        this.subject.next();
    }

    getUser(): Observable<AdminUser> {
        return this.subject.asObservable();
    }

    sendPayer(payer: Payer) {
        this.payerSubject.next(payer);
    }

    clearPayer() {
        this.payerSubject.next();
    }

    getPayer(): Observable<Payer> {
        return this.payerSubject.asObservable();
    }

    /**
     *  wallet
     * */

    sendWallet(wallet: Wallet) {
        this.walletSubject.next(wallet);
    }

    // clearWallet() {
    //     this.walletSubject.next();
    // }

    getWallet(): Observable<Wallet> {
        return this.walletSubject.asObservable()
    }

    /**
     *  OrgId
     * */

    sendOrgId(id: number) {
        this.orgIdSubject.next(id);
    }

    clearOrgId() {
        this.orgIdSubject.next();
    }

    getOrgId(): Observable<number> {
        return this.orgIdSubject.asObservable();
    }

    /**
     *  permission
     * */
    getPermission() {
        return this.permission;
    }

    clearPermission() {
        this.permission = null;
    }

    sendPermission(p: Permission) {
        this.permission = p;
    }

    /**
     *  Task Update
     * */

    sendTaskUpdateEvent(m: string) {
        this.orgTaskUpdateEventSubject.next(m);
    }

    clearTaskUpdateEvent() {
        this.orgTaskUpdateEventSubject.next();
    }

    getTaskUpdateEvent(): Observable<string> {
        return this.orgTaskUpdateEventSubject.asObservable();
    }

}
