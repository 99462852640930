import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class WalletBankAccountService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('wallet-bank-account', http);
    }

    ngOnInit(): void {
    }
}
