
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';
import {CustomerVerification} from '../../models/customer-verification.model';

@Injectable()
export class CustomerVerificationService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('customer-verification', http);
    }

    ngOnInit(): void {
    }

    getCustomerVerificationByUserId(userId: String) {
        return this.http.get(`${this.baseUrl}customer-verification?f_userId=${userId}&f_userId_op==`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    updateCustomerVerification(action: string,  data: CustomerVerification) {
        return this.http.post(`${this.baseUrl}customer-verification?action=${action}`, data).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }
}
