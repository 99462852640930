
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Constants} from '../constants/app.constant';
import {MyHttp} from './http.client.service';
import {PayerSubmit} from '../models/payer.submit.model';
import {Payer} from "../models/payer.model";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class PayerService {
    public token: string;
    public countryUrl = Constants.API_ENDPOINT + 'v1/country';
    public currencyUrl = Constants.API_ENDPOINT + 'v1/payee-bank-account/currency';
    public payerUrl = Constants.API_ENDPOINT + 'v1/payer';
    public codeTypeUrl = Constants.API_ENDPOINT + 'v1/payee-bank-account/code-types';


    constructor(private http: HttpClient) {
    }

    getCountries() {
        return this.http.get(this.countryUrl).pipe(map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    addPayer(payer: PayerSubmit) {
        return this.http.post(this.payerUrl, payer).pipe(map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getPayers(): any {
        return this.http.get(this.payerUrl).pipe(map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getPayersForAdmin(userId: number, pageNumber: number, pageSize: number): any {
        return this.http.get(`${this.payerUrl}?f_user.id=${userId}&f_user.id_op==&embedded=creator&page=${pageNumber}&size=${pageSize}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getPayersByUserIdForAdmin(userId: number): any {
        return this.http.get(`${this.payerUrl}?f_user.id=${userId}&f_user.id_op==&embedded=creator`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getAllByPaging(pageNumber: number, pageSize: number) {
        return this.http.get(this.payerUrl + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getCurrencies() {
        return this.http.get(this.currencyUrl).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getCodeTypes() {
        return this.http.get(this.codeTypeUrl).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getPayer(payerId: number) {
        const embedded = '?embedded=attachments';
        return this.http.get(`${this.payerUrl}/${payerId}${embedded}`).pipe(
            map((response) => response),
            catchError((res: any) => observableThrowError(res)),);
    }

    updatePayersForAdmin(payerId: number, payer: PayerSubmit) {
        return this.http.put(`${this.payerUrl}/${payerId}`, payer).pipe(
            map((response) => response),
            catchError((res: any) => observableThrowError(res)),);
    }

    addPayerForAdmin(payer: PayerSubmit) {
        return this.http.post(`${this.payerUrl}`, payer).pipe(map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    verifyPayers(selectedIds: string) {
        return this.http.put(`${this.payerUrl}/verify?ids=${selectedIds}`, {}).pipe(
            map((response) => response),
            catchError((res: any) => observableThrowError(res)),);
    }
}
