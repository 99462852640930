import {Component, OnInit, ViewChild} from '@angular/core';
import {CollapseMenuComponent} from '../header/collapse-menu';
import {UserService} from '../../../services/user.service';
import {AdminUser} from '../../../models/latipay/admin-user.model';
// import {FadeZoomInTop} from "../../animations/fade-zoom-in-top.decorator";

// @FadeZoomInTop()
@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',
    styles: [` nz-header,
    nz-footer {
        background: #fff;
        color: #7dbcea;
    }`]
})
export class MainLayoutComponent implements OnInit {
    isCollapsed = false;
    user: AdminUser = new AdminUser();
    constructor( private userService: UserService) {

    }

    toggleCollapsed(): void {
        this.isCollapsed = !this.isCollapsed;
    }
    getUser() {
        this.userService.getUserProfile().subscribe((user: any) => {
            this.user = user;
        })
    }

    ngOnInit() {
        this.getUser()
    }

}
