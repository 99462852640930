import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';
import {Headers, Http, RequestOptions} from '@angular/http';
import {PromoKeyWords} from '../../models/latipay/promo-key-words.model';
import {TicketPdf} from '../../models/latipay/ticket-pdf.model';

@Injectable()
export class TicketTypesService extends BaseService implements OnInit {

    constructor(public http: HttpClient,
                public httpP: Http) {
        super('ticket-types', http);
    }

    ngOnInit(): void {
    }

    getAllTicketTypesByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllTicketTypesSearchByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}/search?${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByBusinessIdByPaging(searchCondition: string, businessId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }

        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc` + (businessId ? `&f_businessId=${businessId}&f_businessId_op==` : '')).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByWalletIdsByPaging(walletIds: string[], searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,asc';
        let walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(id => {
                walletIdsStr += `&f_acceptedMerchants=${id}`;
            })
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${walletIdsStr}${sortStr}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res))
        );
    }

    enabledStatus(id: number) {
        return this.http.post(`${this.url}/${id}/enable`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    disableStatus(id: number) {
        return this.http.post(`${this.url}/${id}/disable`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    applyShip(id: number, shipId: number) {
        return this.http.post(`${this.url}/${id}/${shipId}/apply-ship`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    publishCouponById(id: string) {
        return this.http.post(`${this.url}/${id}/dispatch`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    publishTicketById(id: number, action: string) {
        return this.http.post(`${this.url}/${id}/dispatch?`, action).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateStatus(id: string, value: any) {
        return this.http.post(`${this.url}/${id}/update-status?`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getTemplateField(token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/template-field/';

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getTemplatePdf(token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'api/ticket-pdf-template/';

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    exportPdf(token: string, id: number) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});


        const url = this.baseParaUrl + 'para/ticket/preview/pdf/' + id + '/' + '?start_from_dash=2019-11-23&valid_to_dash=2020-11-22&voucher_code=AB12ED12' +
            '&customer_mobile=1234567890&customer_name=XiaoMing&valid_to_std=2019-11-23&valid_to_eng=2020-11-22&valid_to_chinese=2019-11-23&start_from_std=2019-11-23'
            + '&start_from_eng=2019-11-23&start_from_chinese=2019-11-23';

        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updatePdf(token: string, pdf: TicketPdf, id: number) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/ticket-pdf-template/' + id + '/';

        return this.httpP.patch(`${url}`, pdf, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    addPDF(token: string, pdf: TicketPdf) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/ticket-pdf-template/';

        return this.httpP.post(`${url}`, pdf, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    importProduct(businessId: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/${businessId}/import-product`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    setTop(id: string) {
        return this.http.post(`${this.url}/${id}/set-top`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    setUp(id: string) {
        return this.http.post(`${this.url}/${id}/go-up`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    setDown(id: string) {
        return this.http.post(`${this.url}/${id}/go-down`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

}
