import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
var FullVersionService = /** @class */ (function (_super) {
    tslib_1.__extends(FullVersionService, _super);
    function FullVersionService(http) {
        var _this = _super.call(this, 'onboard/full-version', http) || this;
        _this.http = http;
        return _this;
    }
    FullVersionService.prototype.ngOnInit = function () {
    };
    FullVersionService.prototype.getAllByPaging = function (searchCondition, paging) {
        var sortStr = '&sort=modifyDate,desc';
        // const embededStr = '&embedded=organisation';
        // const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + sortStr + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    FullVersionService.prototype.createIntoMerchant = function (id) {
        return this.http.post(this.url + "/convertToMerchant/" + id, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    FullVersionService.prototype.updateStatus = function (id) {
        return this.http.post(this.url + "/generate/" + id, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return FullVersionService;
}(BaseService));
export { FullVersionService };
