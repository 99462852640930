import {NgModule, ApplicationRef} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {FormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';


/*
 * Platform and Environment providers/directives/pipes
 */
import {routing} from './app.routing'
// App is our top level component
import {AppComponent} from './app.component';
import {APP_RESOLVER_PROVIDERS} from './app.resolver';
import {AppState, InternalStateType} from './app.service';

// Core providers
import {CoreModule} from './core/core.module';
import {SmartadminLayoutModule} from './shared/layout';
import {AuthenticationService} from './services/authentication.service';
import {LocalStoreService} from './services/localStore.service';
import {MyHttp} from './services/http.client.service';
import {RoleService} from './services/role.service';
import {AccountService} from './services/account.service';
import {HelperService} from './services/helper.service';
import {BeneficiaryService} from './services/beneficiary.service';
import {TrxService} from './services/trx.service';
import {PayerService} from './services/payer.service';
import {PayeeService} from './services/payee.service';
import {ToastContainerModule, ToastrModule, ToastrService} from 'ngx-toastr';
import {MessageService} from 'app/services/message.service';
import {DropzoneConfigInterface, DropzoneModule} from 'ngx-dropzone-wrapper';
import {Constants} from './constants/app.constant';
import {UserService} from './services/user.service';
import {PriceCategoryService} from './services/price-category.service';
import {PricePolicyService} from './services/price-policy.service';
import {EmailLogService} from './services/email-log.service';
import {ConfirmationPopoverModule} from 'angular-confirmation-popover';
import {NzdPaymentService} from './services/nzd-payment-service';
import {BalanceService} from './services/balance.service';
import {HomeService} from './services/home.service';
import {ANIMATION_TYPES, LoadingModule} from 'ngx-loading';
import {PermissionService} from './services/permission.service';
import {SmsLogService} from './services/sms-log.service';
import {MyNotifyService} from './services/my-notify.service';
import {AuthGuard} from './guards/auth.guard';
import {AppReadyEvent} from './app-ready.component';
import {SysConfigService} from './services/sys-config.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './services/auth.interceptor';
import {RespInterceptor} from 'app/services/resp.interceptor';
import {OrganisationService} from './services/latipay/organisation.service';
import {OrderService} from './services/latipay/order.service';
import {SettlementService} from './services/latipay/settlement.service';
import {BankService} from './services/latipay/bank.service';
import {OrgUserService} from './services/latipay/org-user.service';
import {WalletService} from './services/latipay/wallet.service';
import {CurrencyService} from './services/latipay/currency.service';
import {CountryService} from './services/latipay/country.service';
import {WechatUserService} from './services/latipay/wechat-user.service';
import {PayCompanyInfoService} from './services/latipay/pay-company-info.service';
import {ConfigurationService} from './services/latipay/configuration.service';
import {AutoServiceConfigService} from './services/latipay/auto-service-config.service';
import {PricingPlanService} from 'app/services/latipay/pricing-plan.service';
import {GatewayService} from './services/latipay/gateway.service';
import {BranchService} from './services/latipay/branch.service';
import {AssessmentQuestionOptionService} from './services/latipay/assessment-question-option.service';
import {AssessmentService} from './services/latipay/assessment.service';
import {OrganisationAssessmentService} from './services/latipay/organisation-assessment.service';
import {OrganisationAssessmentAnswerService} from './services/latipay/organisation-assessment-answer.service';
import {AssessmentQuestionService} from './services/latipay/assessment-question.service';
import {StoreModule} from '@ngrx/store';
import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {AppReducerConstants} from './pages/app-store/app-reducers.constant';
import {OperationOrganisationService} from './services/latipay/operation-organisation.service';
import {OperationService} from './services/latipay/operation.service';
import {BankAccountService} from './services/latipay/bank-account.service';
import {OnboardService} from './services/latipay/onboard.service';
import {PricingPlanDefaultService} from './pages/organisations/pricing-default/pricing-plan-default.service';
import {TaskService} from './pages/tasks/task.service';
import {ExportFileService} from './pages/export-file/export-file.service';
import {DownloadService} from './services/download.service';
import {TaskTypeService} from './pages/task-type/task-type.service';
import {InvoiceService} from './services/latipay/invoice.service';
import {FullVersionService} from './services/latipay/fullversion.service';
import {UserAccountService} from './services/latipay/user-account.service';
import {WalletPayeaseTypeService} from './services/latipay/wallet-payease-type.service';
import {WalletBankAccountService} from './services/latipay/wallet-bank-account.service';
import {WalletDetailService} from './services/latipay/wallet-detail.service';
import {TagService} from './services/latipay/tag.service';
import {TagCategoryService} from './services/latipay/tag-category.service';
import {OrganisationTagService} from './services/latipay/organisation-tag.service';
import {InvitationCodeDetailService} from './services/latipay/InvitationCodeDetailService';
import {CustomerService} from './services/latipay/customer.service';
import {CustomerVerificationService} from './services/latipay/customer-verification.service';
import {BusinessService} from './services/latipay/business.service';
import {CouponService} from './services/latipay/coupon.service';
import {CouponTakenService} from './services/latipay/coupon-taken.service';
import {CouponUsedService} from './services/latipay/coupon-used.service';
import {AppAdvertisementService} from './services/latipay/app-advertisement.service';
import {AlipayMerchantMaintainService} from './services/latipay/alipay-merchant-maintain.service';
import {AppMessageService} from './services/latipay/message.service';
import {PreCouponService} from './services/latipay/preCoupon.service';
import {VirtualGoodService} from './services/latipay/virtual-goods.service';
import {TicketSessionsService} from './services/latipay/ticket-sessions.service';
import {TicketTypesService} from './services/latipay/ticket-types.service';
import {TicketRecordService} from './pages/ticket/record/ticket-record.service';
import {SpecialService} from './services/latipay/special.service';
import {NgxSortableModule} from 'ngx-sortable';
import {TopicGroupService} from './services/latipay/topic-group.service';
import {TopicService} from './services/latipay/topic.service';
import {PromoItemService} from './services/latipay/promo-item.service';
import {PromoCategoryService} from './services/latipay/promo-category.service';
import {PanelMenuModule} from 'primeng/primeng';
import {AppHelpService} from './services/latipay/app-help.service';
import {AppHelpCategoryService} from './services/latipay/app-help-category.service';
import {AppFeedbackService} from './services/latipay/app-feedback.service';
import {AppCouponWhiteService} from './services/latipay/app-coupon-white.service';
import {BusinessHoursService} from './services/latipay/business-hours.service';
import {AppCashWalletService} from './services/latipay/app-cash-wallet.service';
import {AppCashWalletDetailService} from './services/latipay/app-cash-wallet-detail.service';
import {DDPaymentOrderService} from './services/latipay/dd-payment-order.service';
import {VoucherBatchService} from './services/latipay/voucher-batch.service';
import {ParasiteAuthenticationService} from './services/parasite-authentication.service';
import {MovieRecordService} from './pages/e-wallet/movie-record/movie-record.service';
import {BankAccountTypeService} from './services/latipay/bank-account-type.service';
import {ClubCardTypeService} from './services/latipay/club-card-type.service';
import {TicketSessionsTimeService} from './services/latipay/ticket-sessions-time.service';
import {VoucherService} from './services/latipay/voucher.service';
import {TicketAgentService} from './services/latipay/ticket-agent.service';
import {VirtualGoodsInventoryService} from './services/latipay/virtual-goods-inventory.service';
import {VoucherInventoryService} from './services/latipay/voucher-inventory.service';
import {ClawCraneService} from './services/latipay/claw-crane.service';
import {NgZorroAntdModule, NZ_I18N, en_US, NzLayoutModule} from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import {PromoCategoryLinkService} from './services/latipay/promo-category-link.service';
import {RouteReuseStrategy} from '@angular/router';
import { CustomReuseStrategy} from './shared/cache-route-reuse.strategy';
import {AppShareDataService} from './services/latipay/app-share-data.service';
import {IndustryCodeService} from './services/latipay/industry-code.service';
import {AppRaffleService} from './services/latipay/app-raffle.service';
import {AppPrizeService} from './services/latipay/app-prize.service';
import {AppPrizeWalletEstService} from './services/latipay/app-prize-wallet-est.service';
import {AppPrizeWalletService} from './services/latipay/app-prize-wallet.service';
import {AppPrizeRedeemWalletService} from './services/latipay/app-prize-redeem-wallet.service';
import {AppPrizeActiveWalletService} from './services/latipay/app-prize-active-wallet.service';
import {AppPrizeItemService} from './services/latipay/app-prize-item.service';
import {AppBusinessCategoryService} from './services/latipay/app-business-category.service';
import {AppCategoryService} from './services/latipay/app-category.service';
import {AppCouponService} from './services/latipay/app-coupon.service';
import {AppCouponWalletAppService} from './services/latipay/app-coupon-wallet-app.service';
import {AppAreaService} from './services/latipay/app-area.service';
import {AppGoodFeeService} from './services/latipay/app-good-fee.service';
import {AppActivityService} from './services/latipay/app-activity.service';
import {AppActivityPrizeService} from './services/latipay/app-activity-prize.service';
import {AppActivityPrizeWalletService} from './services/latipay/app-activity-prize-wallet.service';
import {AppActivityConditionService} from './services/latipay/app-activity-condition.service';
import {AppActivityLogService} from './services/latipay/app-activity-log.service';
import {AppAdvCategoryService} from './services/latipay/app-adv-category.service';
import {AppHomeEntryService} from './services/latipay/app-home-entry.service';
import {AppMessageTaskService} from './services/latipay/app-message-task.service';
import {EComRecordService} from './pages/e-com/record/e-com-record.service';
import {AppShipService} from './services/latipay/app-ship.service';
import {AppShipAreaService} from './services/latipay/app-ship-area.service';
import {AppShipOptionService} from './services/latipay/app-ship-option.service';
import {AppCustomerInfoService} from './services/latipay/app-customer-info.service';
import {AppBusinessDefaultCommissionService} from './services/latipay/app-business-default-commission.service';
import {AppPrizeCodeService} from './services/latipay/app-prize-code.service';
import {AppTransactionMonitorService} from './services/latipay/app-transaction-monitor.service';
import {StaticQrCodeService} from './services/latipay/static-qr-code.service';
import {OrgOnboardService} from './services/latipay/org-onboard.service';
import {WalletBankAccountsService} from './services/latipay/wallet-bank-accounts.service';
import {MerchantHelpService} from './services/latipay/merchant-help.service';
import {MerchantHelpCategoryService} from './services/latipay/merchant-help-category.service';
import {MerchantFeedbackService} from './services/latipay/merchant-feedback.service';
import {MerchantVersionService} from './services/latipay/merchant-version.service';
import {RebateStructureService} from './services/latipay/rebate-structure.service';
import {TierRuleService} from './services/latipay/tier-rule.service';
import {RebateTransactionService} from './services/latipay/rebate-transaction.service';
import {RebateReportService} from './services/latipay/rebate-report.service';
import {PricingPlanHistoryService} from './services/latipay/pricing-plan-history.service';
import {SupportTicketService} from './services/latipay/support-ticket.service';
import {WechatMerchantMaintainService} from './services/latipay/wechat-merchant-maintain.service';
import {WalletAddOnService} from './services/latipay/wallet-add-on.service';
import {OrganisationAddOnService} from './services/latipay/organisation-add-on.service';
import {NgxDocViewerModule} from 'ngx-doc-viewer';

registerLocaleData(en);


// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    AppState
];

interface StoreType {
    state: InternalStateType,
    restoreInputValues: () => void,
    disposeOldHosts: () => void
}

const DROPZONE_CONFIG: DropzoneConfigInterface = {
    // Change this to your upload POST address:
    url: Constants.API_ENDPOINT + 'v1/file',
    maxFilesize: 50,
    // acceptedFiles: 'image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
    acceptedFiles: '',
    addRemoveLinks: true,
    clickable: true
};


/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
    ],
    imports: [ // import Angular's modules
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpModule,
        NgxSortableModule,
        CoreModule,
        SmartadminLayoutModule,
        NzLayoutModule,
        NgxDocViewerModule,
        DropzoneModule.forRoot(DROPZONE_CONFIG),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        ToastContainerModule,
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'danger' // set defaults here
        })
        ,
        routing,
        LoadingModule.forRoot({
            animationType: ANIMATION_TYPES.threeBounce,
            backdropBackgroundColour: 'rgba(0,0,0,0.3)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff'
        }),
        StoreModule.forRoot(AppReducerConstants), // eargly loading not lazy loading.
        EffectsModule.forRoot([]),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        NgZorroAntdModule
    ],
    exports: [],
    providers: [ // expose our Services and Providers into Angular's dependency injection
        // ENV_PROVIDERS,
        APP_PROVIDERS,
        AuthenticationService,
        LocalStoreService,
        MyHttp,
        RoleService,
        HomeService,
        AccountService,
        MovieRecordService,
        VoucherBatchService,
        HelperService,
        BeneficiaryService,
        AccountService,
        TrxService,
        AppHelpService,
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },

        PayerService,
        PayeeService,
        MyHttp,
        HelperService,
        OrgOnboardService,
        ToastrService,
        MessageService,
        ParasiteAuthenticationService,
        UserService,
        PriceCategoryService,
        SupportTicketService,
        PricePolicyService,
        EmailLogService,
        NzdPaymentService,
        BalanceService,
        PermissionService,
        SmsLogService,
        MyNotifyService,
        SysConfigService,
        SettlementService,
        BankService,
        TaskService,
        PromoCategoryLinkService,
        OrgUserService,
        CustomerService,
        WalletBankAccountsService,
        CustomerVerificationService,
        WalletService,
        AuthGuard,
        AppReadyEvent,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: RespInterceptor, multi: true},
        // latipay
        OrganisationService,
        MerchantHelpService,
        OrderService,
        AppTransactionMonitorService,
        CurrencyService,
        AppHomeEntryService,
        VoucherInventoryService,
        PricingPlanService,
        PricingPlanDefaultService,
        CountryService,
        MerchantHelpCategoryService,
        MerchantFeedbackService,
        GatewayService,
        AppActivityPrizeService,
        AppPrizeItemService,
        AppActivityPrizeWalletService,
        IndustryCodeService,
        AppPrizeWalletEstService,
        OrganisationAddOnService,
        AppMessageTaskService,
        WalletAddOnService,
        AppPrizeActiveWalletService,
        AppPrizeWalletService,
        AppPrizeRedeemWalletService,
        BranchService,
        AppActivityService,
        AppBusinessCategoryService,
        RebateTransactionService,
        PayCompanyInfoService,
        VirtualGoodsInventoryService,
        PricingPlanHistoryService,
        WechatUserService,
        WechatMerchantMaintainService,
        TierRuleService,
        RebateReportService,
        MerchantVersionService,
        RebateStructureService,
        AppRaffleService,
        AppPrizeService,
        ConfigurationService,
        AutoServiceConfigService,
        ClawCraneService,
        AppAdvCategoryService,
        AssessmentQuestionService,
        AssessmentQuestionOptionService,
        StaticQrCodeService,
        AssessmentService,
        TicketAgentService,
        AppActivityLogService,
        VoucherService,
        AppShareDataService,
        OrganisationAssessmentService,
        OrganisationAssessmentAnswerService,
        OperationService,
        AppActivityConditionService,
        OperationOrganisationService,
        BankAccountService,
        OnboardService,
        ExportFileService,
        DownloadService,
        InvoiceService,
        TaskTypeService,
        FullVersionService,
        UserAccountService,
        WalletPayeaseTypeService,
        WalletBankAccountService,
        WalletDetailService,
        TagService,
        AppCouponWhiteService,
        TagCategoryService,
        OrganisationTagService,
        InvitationCodeDetailService,
        BusinessService,
        AppBusinessDefaultCommissionService,
        BankAccountTypeService,
        AppCategoryService,
        AppGoodFeeService,
        ClubCardTypeService,
        AppPrizeCodeService,
        AppAreaService,
        TicketSessionsTimeService,
        CouponService,
        AppCustomerInfoService,
        AppCouponService,
        AppCouponWalletAppService,
        AppFeedbackService,
        BusinessHoursService,
        TopicGroupService,
        PromoItemService,
        PromoCategoryService,
        TopicService,
        VirtualGoodService,
        AppHelpCategoryService,
        PreCouponService,
        AppShipOptionService,
        AppShipAreaService,
        TicketTypesService,
        SpecialService,
        AppShipService,
        AppMessageService,
        CouponTakenService,
        DDPaymentOrderService,
        CouponUsedService,
        AppCashWalletDetailService,
        TicketRecordService,
        EComRecordService,
        AppCashWalletService,
        TicketSessionsService,
        AppAdvertisementService,
        AlipayMerchantMaintainService,
        { provide: NZ_I18N, useValue: en_US }
    ]
})
export class AppModule {
    constructor(public appRef: ApplicationRef, public appState: AppState) {
    }
}

