import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';


@Injectable()
export class AppAdvertisementService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('app-advertisement', http);
    }

    ngOnInit(): void {
    }

    getAllAdv(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = 'sort=queue,desc&';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${this.embeddedStr + searchStr}&sort=queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllByPaging(searchCondition: string, paging: Paging) {
        this.embeddedStr = '&embedded=category-id';
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=categoryId.queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllSelectEnabledByPaging(searchCondition: string, paging: Paging) {
        this.embeddedStr = '&embedded=category-id';
        if (!searchCondition) {
            searchCondition = '';
        }
        const strr = '&f_active_op==&f_active=1';
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition + strr}&sort=categoryId.queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    generateNewLink() {
        return this.http.post(`${this.url}/new-link`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    get(id) {
        const embeddedStr = '&embedded=category-id';
        return this.http.get(`${this.url}/${id}?${embeddedStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
