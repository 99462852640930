import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Currency} from "../../models/latipay/currency.model";
import {Gateway} from "../../models/latipay/gateway.model";
import {Observable} from "rxjs";

@Injectable()
export class GatewayService extends BaseService implements OnInit {
    private gateways: any | Gateway[];

    constructor(public http: HttpClient) {
        super('payment-gateway', http);
    }

    ngOnInit(): void {
    }

    getGatewaysFromServer() {
       return this.getAll().subscribe((resp: any) => {
            console.log(resp);
            this.gateways = resp;
        });
    }

    getGatewaysFromLocal() {
        return this.gateways;
    }

}