import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers, Http, RequestOptions } from '@angular/http';
var AppRaffleService = /** @class */ (function (_super) {
    tslib_1.__extends(AppRaffleService, _super);
    function AppRaffleService(http, httpP) {
        var _this = _super.call(this, 'app-raffles', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        return _this;
    }
    AppRaffleService.prototype.ngOnInit = function () {
    };
    AppRaffleService.prototype.getAllAppFeedbackByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + searchCondition + "&sort=created,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppRaffleService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppRaffleService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppRaffleService.prototype.publishCouponById = function (id) {
        return this.http.post(this.url + "/" + id + "/dispatch", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppRaffleService.prototype.changeToDone = function (minAmount, id) {
        return this.http.post(this.url + "/" + minAmount + "/" + id + "/done", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppRaffleService.prototype.addBudget = function (id, budget) {
        return this.http.post(this.url + "/" + id + "/" + budget + "/add-budget", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppRaffleService.prototype.updateBudget = function (id, budget) {
        return this.http.post(this.url + "/" + id + "/" + budget + "/update-budget", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppRaffleService.prototype.adjustDate = function (id, date) {
        return this.http.post(this.url + "/" + id + "/" + date + "/adjust-date", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppRaffleService.prototype.updateStatus = function (id, enabled) {
        return this.http.post(this.url + "/" + id + "/" + enabled + "/update-status", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppRaffleService.prototype.applyRaffle = function (id) {
        return this.http.post(this.url + "/" + id + "/apply-raffle", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppRaffleService.prototype.getClickRaffle = function (token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/user-action/?limit=10&action_code=AUTH_RAFFLE_DRAW';
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return AppRaffleService;
}(BaseService));
export { AppRaffleService };
