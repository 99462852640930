import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers, Http, RequestOptions } from '@angular/http';
var AlipayMerchantMaintainService = /** @class */ (function (_super) {
    tslib_1.__extends(AlipayMerchantMaintainService, _super);
    function AlipayMerchantMaintainService(http, httpP) {
        var _this = _super.call(this, 'alipay-merchant-maintain', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        return _this;
    }
    AlipayMerchantMaintainService.prototype.checkStatus = function (id) {
        return this.http.get(this.url + "/check-status/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AlipayMerchantMaintainService.prototype.ngOnInit = function () {
    };
    AlipayMerchantMaintainService.prototype.getAllByPaging = function (searchCondition, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + this.embeddedStr + "&" + paging.getPagingStr() + searchCondition + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AlipayMerchantMaintainService.prototype.upLoad = function (id) {
        return this.http.get(this.url + "/upload/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AlipayMerchantMaintainService.prototype.maintainWallet = function (id) {
        return this.http.post(this.url + "/maintain-wallet/" + id, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AlipayMerchantMaintainService.prototype.maintainWechat = function (id) {
        return this.http.post(this.url + "/maintain-wechat/" + id, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AlipayMerchantMaintainService.prototype.sync = function () {
        return this.http.get(this.url + "/sync").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AlipayMerchantMaintainService.prototype.uploadAll = function () {
        return this.http.get(this.url + "/upload-all").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AlipayMerchantMaintainService.prototype.checkAll = function () {
        return this.http.get(this.url + "/check-status-all").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AlipayMerchantMaintainService.prototype.uploadWallet = function (value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/upload-wallet", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return AlipayMerchantMaintainService;
}(BaseService));
export { AlipayMerchantMaintainService };
