import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Paging } from '../../models/latipay/paging.model';
var CouponTakenService = /** @class */ (function (_super) {
    tslib_1.__extends(CouponTakenService, _super);
    function CouponTakenService(http) {
        var _this = _super.call(this, 'coupon_taken', http) || this;
        _this.http = http;
        return _this;
    }
    CouponTakenService.prototype.ngOnInit = function () {
    };
    CouponTakenService.prototype.getAllByPaging = function (searchCondition, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    CouponTakenService.prototype.getAllByCouponIdByPaging = function (searchCondition, couponId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (couponId ? "&f_couponId=" + couponId + "&f_couponId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    CouponTakenService.prototype.getAllByCouponIds = function (couponIds) {
        var searchCondition = '';
        if (couponIds && couponIds.length > 0) {
            searchCondition = '&f_couponId_op=IN';
            couponIds.forEach(function (id) {
                searchCondition += '&f_couponId=' + id;
            });
        }
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=id,desc&';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (this.embeddedStr + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return CouponTakenService;
}(BaseService));
export { CouponTakenService };
