import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';
import {AppCoupon} from '../../models/latipay/app-coupon.model';

@Injectable()
export class AppCouponService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('app-coupon', http);
    }

    ngOnInit(): void {

    }

    getAllAppCouponByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllCoupons(walletId: string, sortOprions: Sorts, paging: Paging) {

        const sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get(`${this.url}/get-coupons/${walletId}${sortStr}${paging.getPagingStr()}${this.embeddedStr}&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllLatipayCouponByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        const str = '&f_issuer_op==&f_issuer=0';
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition + str}&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllCouponByBusinessIdByPaging(searchCondition: string, businessId: number, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const idStr = '&f_businessId_op==&f_businessId=' + businessId;
        const sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get(`${this.url}/get-coupons${sortStr}${paging.getPagingStr()}${this.embeddedStr + idStr + searchCondition}&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByBusinessIdByPaging(searchCondition: string, businessId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }

        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc` + (businessId ? `&f_businessId=${businessId}&f_businessId_op==` : '')).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByWalletIdsByPaging(walletIds: string[], searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,asc';
        let walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(id => {
                walletIdsStr += `&f_acceptedMerchants=${id}`;
            })
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${walletIdsStr}${sortStr}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res))
        );
    }

    publishCouponById(id: string) {
        return this.http.post(`${this.url}/${id}/dispatch`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    addExtra(id: number) {
        return this.http.post(`${this.url}/${id}/create-extra`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    applyCoupon(id: number) {
        return this.http.post(`${this.url}/${id}/apply`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    copyCoupon(id: number) {
        return this.http.post(`${this.url}/${id}/copy`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    closeCoupon(id: number) {
        return this.http.post(`${this.url}/${id}/close`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    adjust(id: number, data: AppCoupon) {
        return this.http.put(`${this.url}/${id}/adjust-number`, data).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    get(id) {
        const str = this.embeddedStr ? `${id}?${this.embeddedStr}` : id;
        const idd = '&embedded=prepaid-wallet';
        return this.http.get(`${this.url}/${str}?${idd}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


}
