import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer, OnDestroy } from '@angular/core';
import { ActivitiesService } from './activities.service';
import { TaskService } from '../../../../pages/tasks/task.service';
import { UserService } from 'app/services/user.service';
import { AdminUser } from 'app/models/latipay/admin-user.model';
import { MessageService } from '../../../../services/message.service';
import { BaseComponent } from '../../../../theme/shared/base-component/base.component';
import { AppFeedbackService } from '../../../../services/latipay/app-feedback.service';
import { Router } from '@angular/router';
import { AppHelpCategoryService } from '../../../../services/latipay/app-help-category.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';
var ActivitiesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ActivitiesComponent, _super);
    function ActivitiesComponent(el, renderer, router, appHelpCategoryService, appFeedbackService, activitiesService, messageService, userService, taskService) {
        var _this = _super.call(this) || this;
        _this.el = el;
        _this.renderer = renderer;
        _this.router = router;
        _this.appHelpCategoryService = appHelpCategoryService;
        _this.appFeedbackService = appFeedbackService;
        _this.activitiesService = activitiesService;
        _this.messageService = messageService;
        _this.userService = userService;
        _this.taskService = taskService;
        _this.showTaskVisible = false;
        _this.appFeedback = [];
        _this.appHelpCategory = [];
        _this.aliveNew = true;
        _this.active = false;
        _this.activities = [];
        _this.count = 0;
        _this.lastUpdate = new Date();
        _this.pendingMerchantTaskSize = 0;
        _this.pendingEWalletTaskSize = 0;
        _this.pendingFeedbackTaskSize = 0;
        _this.tab = 1;
        _this.feedtab = 4;
        return _this;
    }
    ActivitiesComponent.prototype.ngOnInit = function () {
        // this.activitiesService.getActivities().subscribe(data => {
        //     this.activities = data;
        //     this.count = data.reduce((sum, it) => sum + it.data.length, 0);
        //     this.currentActivity = data[0];
        // });
        this.getFeeBackCategory();
        this.getAllPendingTaskSize();
        this.getFeedback();
        this.getPendingTasks();
        this.startTaskTimer();
    };
    ActivitiesComponent.prototype.getFeeBackCategory = function () {
        var _this = this;
        this.dropDownLoading = true;
        this.appHelpCategoryService.getAll().subscribe(function (res) {
            _this.appHelpCategory = res.content;
            _this.paging.totalSize = res.totalElements;
            _this.dropDownLoading = false;
        }, function (err) {
            _this.dropDownLoading = false;
        });
    };
    ActivitiesComponent.prototype.getAllPendingTaskSize = function () {
        var _this = this;
        this.taskService.getAllPendingTaskSize().take(1).subscribe(function (resp) {
            _this.pendingTaskSize = resp;
            console.log('ttttt');
            _this.pendingTaskSize.map(function (t) {
                if (t.taskType > 6) {
                    _this.pendingEWalletTaskSize += parseInt(t.size, 10);
                }
                else {
                    _this.pendingMerchantTaskSize += parseInt(t.size, 10);
                }
            });
        });
    };
    ActivitiesComponent.prototype.ngOnDestroy = function () {
        this.aliveNew = false;
        this.documentUnsub();
    };
    ActivitiesComponent.prototype.startTaskTimer = function () {
        // IntervalObservable.create(120000).subscribe(() => {
        //     this.pendingMerchantTaskSize = 0;
        //     this.pendingEWalletTaskSize = 0;
        //     this.pendingFeedbackTaskSize = 0;
        //     this.getAllPendingTaskSize();
        //
        //     this.getFeedback();
        // })
        var _this = this;
        Observable.timer(0, 900000)
            .takeWhile(function () { return _this.aliveNew; })
            .subscribe(function () {
            _this.pendingMerchantTaskSize = 0;
            _this.pendingEWalletTaskSize = 0;
            _this.pendingFeedbackTaskSize = 0;
            console.log('ffffff');
            _this.getAllPendingTaskSize();
            _this.getFeedback();
        });
    };
    ActivitiesComponent.prototype.getUser = function () {
        var _this = this;
        this.userService.getUserProfile().subscribe(function (user) {
            _this.user = user;
            _this.getMyTasks();
        });
    };
    ActivitiesComponent.prototype.getPendingTasks = function () {
        var _this = this;
        this.dropDownLoading = true;
        this.taskService.getAll('&f_status=PENDING&f_status_op==').subscribe(function (resp) {
            _this.lastUpdate = new Date();
            if (_this.userType === 'Merchant') {
                _this.pendingTasks = resp.content.filter(function (t) { return t.taskType.taskType < 7; });
                _this.getTaskUrl(_this.pendingTasks);
            }
            else if (_this.userType === 'E-Wallet') {
                _this.pendingTasks = resp.content.filter(function (t) { return t.taskType.taskType > 6; });
                _this.getTaskUrl(_this.pendingTasks);
            }
            // this.getTaskUrl(this.pendingTasks);
            _this.getUser();
            _this.getUnassignedTasks();
            _this.dropDownLoading = false;
        }, function (err) {
            _this.dropDownLoading = false;
        });
    };
    ActivitiesComponent.prototype.getFeedback = function () {
        var _this = this;
        this.dropDownLoading = true;
        this.paging.pageSize = 50;
        this.pendingFeedbackTaskSize = 0;
        this.appFeedbackService.getAllFeekBacks().take(1).subscribe(function (resp) {
            if (resp) {
                _this.appFeedback = resp.content;
                for (var i in _this.appFeedback) {
                    if (_this.appFeedback[i].status === 0) {
                        _this.pendingFeedbackTaskSize = _this.pendingFeedbackTaskSize + 1;
                    }
                    if (_this.appHelpCategory.length !== 0) {
                        for (var j in _this.appHelpCategory) {
                            if (_this.appHelpCategory[j].id === _this.appFeedback[i].categoryId) {
                                _this.appFeedback[i].categoryId = _this.appHelpCategory[j].nameCn;
                                // i['categoryId'] = j['code'];
                            }
                            else if (_this.appFeedback[i].categoryId === 9999) {
                                _this.appFeedback[i].categoryId = '其他';
                            }
                        }
                    }
                    else {
                        _this.appFeedback[i].categoryId = '用户反馈';
                    }
                }
            }
            _this.dropDownLoading = false;
        }, function (err) {
            _this.dropDownLoading = false;
        });
    };
    ActivitiesComponent.prototype.getUnassignedTasks = function () {
        if (this.pendingTasks && this.pendingTasks.length > 0) {
            if (this.userType === 'Merchant') {
                this.unassignedTasks = this.pendingTasks.filter(function (t) { return !t.adminUser && t.taskType.taskType < 7; });
            }
            else if (this.userType === 'E-Wallet') {
                this.unassignedTasks = this.pendingTasks.filter(function (t) { return !t.adminUser && t.taskType.taskType > 6; });
            }
        }
        else {
            this.unassignedTasks = null;
        }
    };
    ActivitiesComponent.prototype.getMyTasks = function () {
        var _this = this;
        if (this.pendingTasks && this.pendingTasks.length > 0) {
            if (this.userType === 'Merchant') {
                this.myTasks = this.pendingTasks.filter(function (t) { return t.adminUser && t.adminUser.id === _this.user.id && t.taskType.taskType < 7; });
            }
            else if (this.userType === 'E-Wallet') {
                this.myTasks = this.pendingTasks.filter(function (t) { return t.adminUser && t.adminUser.id === _this.user.id && t.taskType.taskType > 6; });
            }
        }
        else {
            this.myTasks = null;
        }
    };
    ActivitiesComponent.prototype.getTimePassed = function (created) {
        var diff = (Math.floor((Date.now() / 1000)) - created);
        var diffStr = this.millisecondsToStr(diff * 1000);
        if (diff / 60 <= 30) { // half an hour
            return "<span style=\"color: green;\">" + diffStr + "</span>";
        }
        else if (diff / 60 > 30 && diff / 60 <= 60) { // half an hour to one hour
            return "<span style=\"color: orange;\">" + diffStr + "</span>";
        }
        else if (diff / 60 > 60 && diff / 60 <= 240) {
            return "<span style=\"color: purple;\">" + diffStr + "</span>";
        }
        else {
            return "<span style=\"color: red;\">" + diffStr + "</span>";
        }
    };
    ActivitiesComponent.prototype.millisecondsToStr = function (milliseconds) {
        function numberEnding(number) {
            return (number > 1) ? 's' : '';
        }
        var temp = Math.floor(milliseconds / 1000);
        var years = Math.floor(temp / 31536000);
        if (years) {
            return years + ' year' + numberEnding(years);
        }
        var days = Math.floor((temp %= 31536000) / 86400);
        if (days) {
            return days + ' day' + numberEnding(days);
        }
        var hours = Math.floor((temp %= 86400) / 3600);
        if (hours) {
            return hours + ' hour' + numberEnding(hours);
        }
        var minutes = Math.floor((temp %= 3600) / 60);
        if (minutes) {
            return minutes + ' minute' + numberEnding(minutes);
        }
        var seconds = temp % 60;
        if (seconds) {
            return seconds + ' second' + numberEnding(seconds);
        }
        return 'just now';
    };
    ActivitiesComponent.prototype.viewFeedback = function (feedback) {
        var _this = this;
        console.log(feedback);
        this.loading = true;
        feedback.status = 1;
        this.appFeedbackService.get(feedback.id).subscribe(function (res) {
            if (res) {
                res.status = 1;
                _this.appFeedbackService.update(feedback.id, res).subscribe(function (resp) {
                    if (resp) {
                        _this.router.navigate(['/pages/e-wallet/app-feedback/edit/' + feedback.id]);
                    }
                    _this.loading = false;
                }, function (err) {
                    _this.loading = false;
                });
                _this.getFeedback();
            }
            _this.loading = false;
        }, function (err) {
            _this.loading = false;
        });
        // this.getFeedback();
    };
    ActivitiesComponent.prototype.assignToMe = function (task) {
        var _this = this;
        task.adminUser = new AdminUser();
        task.adminUser.id = this.user.id;
        // this.selectedTask.description = this.selectedAdminUserDesc;
        this.taskService.update(task.id, task).subscribe(function (resp) {
            _this.update();
        });
    };
    ActivitiesComponent.prototype.ignore = function (task) {
        var _this = this;
        task.status = 'IGNORED';
        this.taskService.update(task.id, task).subscribe(function (resp) {
            ////console.log(resp);
            _this.update();
        });
    };
    ActivitiesComponent.prototype.complete = function (task) {
        var _this = this;
        task.status = 'COMPLETED';
        this.taskService.update(task.id, task).subscribe(function (resp) {
            ////console.log(resp);
            _this.update();
        });
    };
    ActivitiesComponent.prototype.setActivity = function (activity) {
        this.currentActivity = activity;
    };
    ActivitiesComponent.prototype.onToggle = function () {
        var _this = this;
        var dropdown = $('.ajax-dropdown', this.el.nativeElement);
        this.active = !this.active;
        if (this.active) {
            dropdown.fadeIn();
            this.documentSub = this.renderer.listenGlobal('document', 'mouseup', function (event) {
                if (!_this.el.nativeElement.contains(event.target)) {
                    dropdown.fadeOut();
                    _this.active = false;
                    _this.documentUnsub();
                }
            });
        }
        else {
            dropdown.fadeOut();
            this.documentUnsub();
        }
    };
    ActivitiesComponent.prototype.update = function () {
        this.getPendingTasks();
        this.messageService.sendTaskUpdateEvent('TaskChanged');
    };
    ActivitiesComponent.prototype.documentUnsub = function () {
        if (this.documentSub && this.documentSub()) {
            this.documentSub = null;
        }
    };
    ActivitiesComponent.prototype.getTaskUrl = function (tasks) {
        tasks.map(function (t) {
            var parameter = JSON.parse(t.parameter);
            if (parameter.organisation_id) {
                t.targetOrganisationId = parameter.organisation_id;
            }
            if (parameter.invoice_id) {
                t.targetInvoiceId = parameter.invoice_id;
            }
            if (parameter.bank_account_id) {
                t.targetBankAccountId = parameter.bank_account_id;
            }
            if (parameter.id) {
                t.targetId = parameter.id;
            }
            if (t.taskType.taskType === 1 || t.taskType.taskType === 2) {
                t.url = "/#/pages/onboard/detail/" + t.targetOrganisationId + "?taskId=" + t.id;
            }
            else if (t.taskType.taskType === 3) {
                t.url = "/#/pages/invoice/invoice-detail/" + t.targetInvoiceId + "?taskId=" + t.id;
            }
            else if (t.taskType.taskType === 4) {
                t.url = "/#/pages/onboard/full-version/detail/" + t.targetId + "?taskId=" + t.id;
            }
            else if (t.taskType.taskType === 5) {
                t.url = "/#/pages/organisations/detail/" + t.targetOrganisationId + "?tab=bankAccount&taskId=" + t.id;
            }
            else if (t.taskType.taskType === 6) {
                t.url = "/#/pages/organisations/detail/" + parameter.id + "?taskId=" + t.id;
            }
            else if (t.taskType.taskType === 7 || t.taskType.taskType === 8) {
                t.url = "/#/pages/e-wallet/merchants/details/" + parameter.merchant_id + "?tab=Document";
            }
        });
    };
    ActivitiesComponent.prototype.getTaskDescription = function (task) {
        if (task.taskType.taskType === 1 || task.taskType.taskType === 2) {
            return task.taskType.description.replace('${id}', task.targetOrganisationId);
        }
        else if (task.taskType.taskType === 3) {
            return task.taskType.description.replace('${id}', task.targetInvoiceId);
        }
        else if (task.taskType.taskType === 4) {
            return task.taskType.description.replace('${id}', task.targetId);
        }
        else if (task.taskType.taskType === 5) {
            return task.taskType.description.replace('${id}', task.targetOrganisationId).replace('${bank_account_id}', task.targetBankAccountId);
        }
        else if (task.taskType.taskType === 6) {
            var parameter = JSON.parse(task.parameter);
            return task.taskType.description.replace('${id}', parameter.id).replace('${url}', '<a href="' + parameter.url + '" target="_blank">Show Photo</a>');
        }
        else if (task.taskType.taskType === 7 || task.taskType.taskType === 8) {
            var parameter = JSON.parse(task.parameter);
            return task.taskType.description.replace('${userId}', parameter.user_id);
        }
    };
    ActivitiesComponent.prototype.showTask = function () {
        this.showTaskVisible = true;
    };
    return ActivitiesComponent;
}(BaseComponent));
export { ActivitiesComponent };
