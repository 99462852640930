import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
var PreCouponService = /** @class */ (function (_super) {
    tslib_1.__extends(PreCouponService, _super);
    function PreCouponService(http) {
        var _this = _super.call(this, 'pre-coupon', http) || this;
        _this.http = http;
        return _this;
    }
    PreCouponService.prototype.ngOnInit = function () {
    };
    PreCouponService.prototype.getAllPreCouponByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PreCouponService.prototype.getAllPreCouponByBusinessIdByPaging = function (searchCondition, businessId, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var idStr = '&f_businessId_op==&f_businessId=' + businessId;
        var sortStr = sortOprions.getSortString();
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (this.embeddedStr + idStr + searchCondition) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PreCouponService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PreCouponService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PreCouponService.prototype.publishCouponById = function (id, name, promoId) {
        return this.http.post(this.url + "/" + id + "/" + promoId + "/dispatch?", name).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PreCouponService.prototype.disablePreCoupon = function (id, value) {
        return this.http.put(this.url + "/disable/" + id, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PreCouponService.prototype.updateStatus = function (id, status) {
        return this.http.post(this.url + "/" + id + "/" + status + "/update-status", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PreCouponService.prototype.addOld = function (value) {
        return this.http.post(this.url + "/old", value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PreCouponService.prototype.copyPreCoupon = function (id) {
        return this.http.post(this.url + "/" + id + "/copy-precoupon", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return PreCouponService;
}(BaseService));
export { PreCouponService };
