/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ng-zorro-antd/icon";
import * as i2 from "@angular/cdk/platform";
import * as i3 from "./logout.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../services/permission.service";
import * as i6 from "../../../services/authentication.service";
import * as i7 from "../../utils/notification.service";
var styles_LogoutComponent = [];
var RenderType_LogoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoutComponent, data: {} });
export { RenderType_LogoutComponent as RenderType_LogoutComponent };
export function View_LogoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", " transparent pull-right"], ["id", "logout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [["data-action", "userLogout"], ["data-logout-msg", "You can improve your security further after logging out by closing this opened browser"], ["routerlink", "/auth/login"], ["title", "Sign Out"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "i", [["nz-icon", ""], ["nzTheme", "outline"], ["nzType", "logout"], ["style", "color: black"]], null, null, null, null, null)), i0.ɵdid(4, 2834432, null, 0, i1.NzIconDirective, [i1.NzIconService, i0.ElementRef, i0.Renderer2, i2.Platform], { nzType: [0, "nzType"], nzTheme: [1, "nzTheme"] }, null)], function (_ck, _v) { var currVal_0 = "logout"; var currVal_1 = "outline"; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_LogoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sa-logout", [], null, null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i0.ɵdid(1, 114688, null, 0, i3.LogoutComponent, [i4.Router, i5.PermissionService, i6.AuthenticationService, i7.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutComponentNgFactory = i0.ɵccf("sa-logout", i3.LogoutComponent, View_LogoutComponent_Host_0, {}, {}, []);
export { LogoutComponentNgFactory as LogoutComponentNgFactory };
