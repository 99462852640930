import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Headers, Http, RequestOptions} from '@angular/http';
import {Sorts} from '../../models/admin/sorts.model';


@Injectable()
export class BusinessService extends BaseService implements OnInit {

    constructor(public http: HttpClient,
                public httpP: Http) {
        super('business', http);
    }

    ngOnInit(): void {
    }

    getAllBusiness(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = 'sort=id,desc&';
        this.embeddedStr = '&embedded=images';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${this.embeddedStr + searchStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllNormalBusiness(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = 'sort=id,desc&';
        this.embeddedStr = '&embedded=images';
        return this.http.get(`${this.url}/normal-get?${tempPaging.getPagingStr()}${this.embeddedStr + searchStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    getAllTopicBusiness(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = 'sort=id,desc&';
        this.embeddedStr = '&embedded=images';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllMessagessBusiness(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = 'sort=id,desc&';
        const str = '&f_enabled_op==&f_enabled=1';

        return this.http.get(`${this.url}/normal-get?${tempPaging.getPagingStr()}${str + searchStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllTypeBusiness(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = 'sort=id,desc&';
        const str = '&f_type_op==&f_type=1&f_enabled_op==&f_enabled=1';

        return this.http.get(`${this.url}/normal-get?${tempPaging.getPagingStr()}${str + searchStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllNoBusiness(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = 'sort=id,desc&';

        return this.http.get(`${this.url}/normal-get?${tempPaging.getPagingStr()}${searchStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getSelectbusiness(searchCondition: string, activityId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const embededStrr = '&embedded=area';
        return this.http.get(`${this.url}/all-select-business/${activityId}/?${paging.getPagingStr()}${searchCondition}&sort=id,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getSelectOther(searchCondition: string, activityId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const embededStrr = '&embedded=area';
        return this.http.get(`${this.url}/all-select-latipay-merchant/${activityId}/?${paging.getPagingStr()}${searchCondition}&sort=id,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    getAllBusinessIDByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        const embededStrr = '&embedded=category-id,images,area';
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${searchCondition + embededStrr}&sort=categoryId.queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllMerchantByPaging(searchCondition: string, paging: Paging) {
        this.embeddedStr = '&embedded=prepaid-wallet';
        if (!searchCondition) {
            searchCondition = '';
        }
        const str = '&f_prepaidWallet.code=-';
        return this.http.get(`${this.url}/normal-get?${paging.getPagingStr()}${this.embeddedStr + searchCondition + str}&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllMerchantHasByPaging(searchCondition: string, paging: Paging) {

        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}/prepaid-wallet?${paging.getPagingStr()}${ searchCondition }&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllClawByPaging(searchCondition: string, paging: Paging) {
        this.embeddedStr = '&embedded=images';
        if (!searchCondition) {
            searchCondition = '';
        }
        const filterStr = '&f_type_op==&f_type=2';
        return this.http.get(`${this.url}/all-business/?${paging.getPagingStr()}${this.embeddedStr + searchCondition + filterStr}&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByPaging(searchCondition: string, paging: Paging) {
        // this.embeddedStr = '&embedded=images,wallet';
        const enbed = '&embedded=images,category-id,area';
        if (!searchCondition) {
            searchCondition = '';
        }
        const filterStr = '&f_type_op==&f_type=1';
        return this.http.get(`${this.url}?${paging.getPagingStr()}${enbed + searchCondition}&sort=categoryId.queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllTicketsByPaging(searchCondition: string, paging: Paging) {
        this.embeddedStr = '&embedded=images';
        if (!searchCondition) {
            searchCondition = '';
        }
        let ticketTag = '&f_tags_op==&f_tags=Ticket';
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition + ticketTag}&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    get(id) {
        this.embeddedStr = 'embedded=images,area';
        return this.http.get(`${this.url}/${id}?${this.embeddedStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateBusiness(token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/tools/import/search-business/?modules=business';


        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));

    }

    updateBusinessCategory(token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/tools/import/search-business/?modules=business_category';


        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));

    }

    updateKeyWords(token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/tools/import/search-business/?modules=promo,coupon,pre_coupon';


        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));

    }

    updateKeyWord(token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/tools/import/search-business/?modules=keywords';


        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));

    }

    updateCoupon(token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/tools/import/search-business/?modules=coupon';


        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));

    }

    updatePrecoupon(token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});

        const url = this.baseParaUrl + 'api/tools/import/search-business/?modules=pre_coupon';


        return this.httpP.get(`${url}`, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));

    }

    getAddressCo(address: string) {
        // const header: Headers = new Headers();
        // header.append('Content-Type', 'application/json');
        // const options = new RequestOptions({headers: header});

        const url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=AIzaSyA4YtZga1UtkMDVAtljhNVPOiSxg8Z0UEs';


        return this.httpP.get(`${url}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));

    }


    getAllBusinessByPagingFirst(searchCondition: string, sortOprions: Sorts, paging: Paging, pageNum: number) {
        if (!searchCondition) {
            searchCondition = '';
        }
        if (pageNum) {
            paging.pageNumber = pageNum;
        }
        const sortStr = sortOprions.getSortString();
        const embededStrr = '&embedded=category-id,images,area';
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${searchCondition + embededStrr}&sort=categoryId.queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateShareLink(id: number) {
        return this.http.post(`${this.url}/${id}/update-share-link`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

}
