import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
var OrganisationAssessmentService = /** @class */ (function (_super) {
    tslib_1.__extends(OrganisationAssessmentService, _super);
    function OrganisationAssessmentService(http) {
        var _this = _super.call(this, 'organisation-assessment', http) || this;
        _this.http = http;
        _this.embeddedStr = '&embedded=organisation-assessment,organisation,assessment,organisation-assessment-answers';
        return _this;
    }
    OrganisationAssessmentService.prototype.ngOnInit = function () {
    };
    OrganisationAssessmentService.prototype.getByOrgIdAndAssessmentId = function (orgId, assessmentId) {
        return this.http.get(this.url + "?f_organisation.id=" + orgId + "&f_organisation.id_op==&f_id=" + assessmentId + "&f_id_op==").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrganisationAssessmentService.prototype.getByOrgId = function (orgId) {
        return this.http.get(this.url + "/organisation-id/" + orgId + "?embedded=assessment").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return OrganisationAssessmentService;
}(BaseService));
export { OrganisationAssessmentService };
