import {Role} from './admin/role.model';

export class User {
    address: string;
    city: string;
    country: string;
    email: string;
    userType: string;
    createdAt: number;
    id: number;
    name: string;
    username: string;
    updatedAt: number;
    enabled: boolean;
    introducedBy: User;
    settledBy: User;
    canceledBy: User;
    role: Role;
    isSelected: boolean;
    password: string;
    repeatPassword: string;
    newPassword: string;
}
