import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const copiedReq = req.clone({headers: req.headers.set('', '')});
        if (this.authService.getToken()) {
            // headers.append('Authorization', 'Bearer ' + this.authenticationService.getToken());
            // copiedReq copiedReq = req.clone({params: req.params.set('Authorization', 'Bearer ' + this.authService.getToken())});
            const copiedReq = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authService.getToken()}`
                }
            });
            return next.handle(copiedReq);
        }
        // return null;
    }
}
