import {Payer} from './payer.model';
import {BankAccount} from './bank-account.model';
import {Attachment} from "./attachment.model";
import {AdminUser} from "./admin/admin-user.model";


export class Payee {
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
    createdAt: number;
    enable: boolean;
    id: number;
    name: string;
    payer: Payer;
    postCode: string;
    status: string;
    payeeBankAccounts: BankAccount[];
    attachments: Attachment[];
    user: AdminUser;
    verify: boolean;
    isSelected: boolean;
}
