import { ApplicationRef } from '@angular/core';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState } from './app.service';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Constants } from './constants/app.constant';
import { en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);
// Application wide providers
var APP_PROVIDERS = APP_RESOLVER_PROVIDERS.concat([
    AppState
]);
var DROPZONE_CONFIG = {
    // Change this to your upload POST address:
    url: Constants.API_ENDPOINT + 'v1/file',
    maxFilesize: 50,
    // acceptedFiles: 'image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
    acceptedFiles: '',
    addRemoveLinks: true,
    clickable: true
};
var ɵ0 = en_US;
/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
var AppModule = /** @class */ (function () {
    function AppModule(appRef, appState) {
        this.appRef = appRef;
        this.appState = appState;
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
