import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../constants/app.constant';
import {Paging} from '../../models/latipay/paging.model';
import {Organisation} from '../../models/latipay/organisation.model';
import {Branch} from '../../models/latipay/branch.model';
import {Headers, Http, RequestOptions} from '@angular/http';

@Injectable()
export class OrganisationService implements OnInit {


    public orgUrl = Constants.API_ENDPOINT + 'v1/organisation';
    public pricingUrl = Constants.API_ENDPOINT + 'v1/pricing-plan';
    public couponUrl = Constants.API_ENDPOINT + 'v1/coupon/merchants';
    public preCouponUrl = Constants.API_ENDPOINT + 'v1/pre-coupon/merchants';

    constructor(private http: HttpClient,
                public httpP: Http) {
    }

    ngOnInit(): void {

    }

    getAll(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        const tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});

        return this.http.get(`${this.orgUrl}?${tempPaging.getPagingStr()}${searchStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    // getAll() {
    //     return this.http.get(`${this.orgUrl}`)
    //         .map((response) => response).catch((res: any) => Observable.throw(res));
    // }

    getAllByPaging(searchCondition: string, paging: Paging) {
        const idSortStr = '&f_organisationAddOn.queue_op==&f_organisationAddOn.queue=1&sort=id,desc';
        const embedded = '&embedded=branch,onboard,primary-currency,operation-organisations,operation-organisations.operation,organisation-tags,organisation-tags.tag,organisation-add-on';
        let url = `${this.orgUrl}?${paging.getPagingStr() + searchCondition + embedded + idSortStr}`;
        if (url.indexOf('&f_merchantType_op==&f_merchantType') < 0) {
            // url += '&f_merchantType=COMPANY'
            url += '&f_merchantType=COMPANY&f_merchantType=SOLE_TRADER&f_merchantType=PARTNERSHIP&f_merchantType_op=IN'
        }
        return this.http.get(url).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllSByPaging(searchCondition: string, paging: Paging) {
        const idSortStr = '&f_organisationAddOn.queue_op==&f_organisationAddOn.queue=0&sort=id,desc';
        const embedded = '&embedded=branch,onboard,primary-currency,operation-organisations,operation-organisations.operation,organisation-tags,organisation-tags.tag,organisation-add-on';
        let url = `${this.orgUrl}?${paging.getPagingStr() + searchCondition + embedded + idSortStr}`;
        if (url.indexOf('&f_merchantType_op==&f_merchantType') < 0) {
            // url += '&f_merchantType=COMPANY'
            url += '&f_merchantType=COMPANY&f_merchantType=SOLE_TRADER&f_merchantType=PARTNERSHIP&f_merchantType_op=IN'
        }
        return this.http.get(url).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllByIdsByPaging(ids, searchCondition: string, paging: Paging) {
        const idsSearchArray = [];
        ids.forEach(id => {
            idsSearchArray.push('f_id=' + id);
        });
        let idsSearchStr = '';
        if (idsSearchArray.length > 0) {
            idsSearchStr = idsSearchArray.join('&');
            idsSearchStr = '&' + idsSearchStr + '&f_id_op=IN';
        }
        const idSortStr = '&f_organisationAddOn.queue_op==&f_organisationAddOn.queue=1&sort=id,desc';
        const embedded = idsSearchStr + '&embedded=branch,onboard,primary-currency,operation-organisations,operation-organisations.operation,organisation-tags,organisation-tags.tag,organisation-add-on';
        let url = `${this.orgUrl}?${paging.getPagingStr() + searchCondition + embedded + idSortStr}`;
        if (url.indexOf('&f_merchantType_op==&f_merchantType') < 0) {
            url += '&f_merchantType=COMPANY'
        }
        return this.http.get(url).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllSByIdsByPaging(ids, searchCondition: string, paging: Paging) {
        const idsSearchArray = [];
        ids.forEach(id => {
            idsSearchArray.push('f_id=' + id);
        });
        let idsSearchStr = '';
        if (idsSearchArray.length > 0) {
            idsSearchStr = idsSearchArray.join('&');
            idsSearchStr = '&' + idsSearchStr + '&f_id_op=IN';
        }
        const idSortStr = '&f_organisationAddOn.queue_op==&f_organisationAddOn.queue=0&sort=id,desc';
        const embedded = idsSearchStr + '&embedded=branch,onboard,primary-currency,operation-organisations,operation-organisations.operation,organisation-tags,organisation-tags.tag,organisation-add-on';
        let url = `${this.orgUrl}?${paging.getPagingStr() + searchCondition + embedded + idSortStr}`;
        if (url.indexOf('&f_merchantType_op==&f_merchantType') < 0) {
            url += '&f_merchantType=COMPANY'
        }
        return this.http.get(url).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllEWalletMerchantsByPaging(searchCondition: string, paging: Paging) {
        const embedded = '&f_merchantType=EWALLET&f_merchantType_op==&embedded=branch,onboard,primary-currency,operation-organisations,operation-organisations.operation,organisation-tags,organisation-tags.tag';
        return this.http.get(`${this.orgUrl}?${paging.getPagingStr() + searchCondition + embedded}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllCouponMerchantsByPaging(searchCondition: string, paging: Paging) {
        return this.http.get(`${this.couponUrl}?${paging.getPagingStr() + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllPreCouponMerchantsByPaging(searchCondition: string, paging: Paging) {
        return this.http.get(`${this.preCouponUrl}?${paging.getPagingStr() + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getOrg(id: any) {
        const embedded = '?embedded=primary-currency,affiliate,onboard,organisation-tags,organisation-tags.tag,organisation-tags.tag.tag-category';
        return this.http.get(`${this.orgUrl}/${id + embedded}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getSingleOrg(id: any) {
        return this.http.post(`${this.orgUrl}/get-single-org/${id}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    updateReviewStatus(id: number, status: string) {
        return this.http.post(`${this.orgUrl}/${id}/${status}/update-review-status`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getPricingList(orgId: any) {
        const str = '?f_merchant.id=' + orgId + '&f_merchant.id_op==';
        return this.http.get(`${this.pricingUrl + str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }
    getDatazooL(orgId: any) {

        return this.http.get(`${this.orgUrl}/datazoo-log/${orgId}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    update(id: number, org: Organisation) {
        return this.http.put(`${this.orgUrl}/${id}`, org).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    add(org: Organisation) {
        return this.http.post(`${this.orgUrl}`, org).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    assign(selectedIds: any, branchId: number) {
        return this.http.post(`${this.orgUrl}/assign?orgIds=${selectedIds}&branchId=${branchId}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    verify(selectedIds: any) {
        return this.http.post(`${this.orgUrl}/verify?orgIds=${selectedIds}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    updatePerson(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.orgUrl}/update-person/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    addPerson(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.orgUrl}/add-person/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    deletePerson(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.orgUrl}/delete-person/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateAccountManager(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.orgUrl}/update-account-manager/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateWithdraw(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.orgUrl}/update-withdraw/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateComment(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.orgUrl}/update-comment/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateCompanyDetail(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.orgUrl}/update-company-detail/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateCompliance(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.orgUrl}/update-compliance/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateDocument(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.orgUrl}/update-document/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    disableAccount(id: number) {
        return this.http.post(`${this.orgUrl}/disable-org/${id}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    changeSteps(selectedIds: any, selectedStepId: number) {
        return this.http.post(`${this.orgUrl}/changeSteps?orgIds=${selectedIds}&stepId=${selectedStepId}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    changeStep(item: Organisation) {
        return this.http.post(`${this.orgUrl}/changeStep?orgId=${item.id}&step=${item.onboard.step}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    assignOnbordAndChangeStep(orgId: number, selectedOnboardId: number, selectedOnboardStepId: number) {
        return this.http.post(`${this.orgUrl}/assignOnbordAndChangeStep?onboardId=${selectedOnboardId}&orgId=${orgId}&step=${selectedOnboardStepId}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    importMerchant(importMerchantObject: any) {
        return this.http.post(`${this.orgUrl}/importMerchant`, importMerchantObject).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    completeInfo(orgId: number) {
        return this.http.post(`${this.orgUrl}/${orgId}/info-complete`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateStatus(orgId: number, status: number) {
        return this.http.post(`${this.orgUrl}/${orgId}/${status}/update-status`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


}
