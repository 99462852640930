import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PipesModule} from '../pipes/pipes.module';
import {PermissionDirective} from './permission/permission.directive';
import {CustomDisabledDirective} from "./custom.disable.directive";
import {OnlyNumberDirective} from "./only-number.directive";


@NgModule({
    imports: [
        CommonModule,
        PipesModule
    ],
    declarations: [
        PermissionDirective,
        CustomDisabledDirective,
        OnlyNumberDirective

    ],
    exports: [
        PermissionDirective,
        CustomDisabledDirective,
        OnlyNumberDirective
    ]
})
export class DirectivesModule {
}
