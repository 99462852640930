<!--<aside id="left-panel" class="aside" style="background-color: #9a0325">-->

<div>
    <!--<button nz-button [nzType]="'primary'" (click)="toggleCollapsed()" style="margin-bottom: 10px;">-->
    <!--<i nz-icon [type]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>-->
    <!--</button>-->

    <ul nz-menu [nzMode]="'inline'" nzTheme="dark" [nzInlineCollapsed]="needCollapsed">

        <li nz-menu-item><img *ngIf="!needCollapsed" style="height: 25px;width: 70px;text-align: center"
                              src="assets/img/logo/11.svg"
                              alt="Latipay Admin"/>
            <img *ngIf="needCollapsed" style="height: 25px;width: 20px;"
                 src="assets/img/logo/latipay_collasped_logo.svg"
                 alt="Latipay Admin"/>

        </li>

        <li nz-submenu [permission]="permission.INDEX_ORGANISATION">
          <span title>
        <i nz-icon nzType="user" nzTheme="outline"></i>
            <span>Merchant</span>
          </span>
            <ul style="background-color: #000c17">
                <li nz-menu-item routerLinkActive="active"><a
                        routerLink="/pages/organisations/list">{{'Merchants' | i18n}}</a></li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_ALL_WALLET_LIST"><a
                        routerLink="/pages/organisations/alipay-merchant-maintain">{{'Alipay SubMerchant' | i18n}}</a>
                </li>

                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_ALL_WALLET_LIST"><a
                        routerLink="/pages/organisations/wechat-merchant-maintain">{{'Wechat SubMerchant' | i18n}}</a>
                </li>


                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_ALL_WALLET_LIST">
                    <nz-badge [nzCount]="appOnboardSize" [nzOverflowCount]="99"
                                                                        [nzStyle]="{ backgroundColor: '#fa1616', color: '#ffffff', boxShadow: '0 0 0 1px #fa1616 inset' }">
                    <a routerLink="/pages/onboard/onboard/list"
                       style="color: #B0B4B7">{{'Onboard' | i18n}}</a>


                </nz-badge>
                </li>

                <!--<li nz-submenu [permission]="permission.DISPLAY_EWALLET_SECTION">-->
                <!--<span title>-->
                <!--<span>Onboard</span>-->
                <!--</span>-->
                <!--<ul>-->
                    <!--<li nz-menu-item routerLinkActive="active">-->

                        <!--<nz-badge [nzCount]="appOnboardSize" [nzOverflowCount]="99"-->
                                  <!--[nzStyle]="{ backgroundColor: '#fa1616', color: '#ffffff', boxShadow: '0 0 0 1px #fa1616 inset' }">-->
                            <!--<a routerLink="/pages/onboard/onboard/list"-->
                               <!--style="color: #B0B4B7">{{'From App' | i18n}}</a>-->


                        <!--</nz-badge>-->


                    <!--</li>-->

                    <!--<li nz-menu-item routerLinkActive="active"><a-->
                            <!--routerLink="/pages/onboard/full-version/list">{{'From Web' | i18n}}</a>-->
                    <!--</li>-->
                <!--</ul>-->


                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/organisations/merchant-users">Users</a>
                </li>

                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_ALL_WALLET_LIST">
                    <a routerLink="/pages/wallets/wallet-list">{{'Wallets' | i18n}}</a>
                </li>


                <!--<li nz-menu-item routerLinkActive="active">-->
                    <!--&lt;!&ndash;<a routerLink="/pages/organisations/latipay-merchant-monitor">{{'Transaction Monitor' | i18n}}</a>&ndash;&gt;-->

                    <!--<nz-badge [nzCount]="monitorTransactionSize" [nzOverflowCount]="99"-->
                              <!--[nzStyle]="{ backgroundColor: '#fa1616', color: '#ffffff', boxShadow: '0 0 0 1px #fa1616 inset' }">-->
                        <!--<a routerLink="/pages/organisations/latipay-merchant-monitor"-->
                           <!--style="color: #B0B4B7">{{'Transaction Monitor' | i18n}}</a>-->
                    <!--</nz-badge>-->
                <!--</li>-->
                <!--<li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">-->
                    <!--<nz-badge [nzCount]="unHandleTicketSize" [nzOverflowCount]="99"-->
                              <!--[nzStyle]="{ backgroundColor: '#fa1616', color: '#ffffff', boxShadow: '0 0 0 1px #fa1616 inset' }">-->
                    <!--<a routerLink="/pages/e-wallet/support-ticket" style="color: #B0B4B7">{{'Support Ticket' | i18n}}</a>-->
                    <!--</nz-badge>-->
                <!--</li>-->

                <!--<li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">-->

                <!--<nz-badge [nzCount]="feedbackSize" [nzOverflowCount]="99"-->
                          <!--[nzStyle]="{ backgroundColor: '#fa1616', color: '#ffffff', boxShadow: '0 0 0 1px #fa1616 inset' }">-->
                    <!--<a routerLink="/pages/e-wallet/merchant-feedback"-->
                       <!--style="color: #B0B4B7">{{'Online Feedback' | i18n}}</a>-->
                <!--</nz-badge>-->

                <!--</li>-->


                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/merchant-help">Help Center</a>
                </li>
                <li routerLinkActive="active" nz-menu-item [permission]="permission.INDEX_CONFIGURATION">
                    <a routerLink="/pages/others/config-dashboard">{{'Auto Withdrawal' | i18n}}</a>
                </li>

                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/merchant-version">App Version</a>
                </li>

                <!--<li nz-menu-item routerLinkActive="active"><a-->
                <!--routerLink="/pages/tags/list">{{'Tags' | i18n}}</a>-->
                <!--</li>-->
            </ul>
        </li>

        <!--<li nz-submenu [permission]="permission.DISPLAY_COUPON_SECTION">-->
        <!--<span title>-->
        <!--<i nz-icon nzType="project" nzTheme="outline"></i>-->
        <!--<span>Product</span>-->
        <!--</span>-->
        <!--<ul>-->
        <!--<li nz-submenu [permission]="permission.DISPLAY_COUPON_SECTION">-->
        <!--<span title>-->
        <!--<i nz-icon nzType="calendar" nzTheme="outline"></i>-->
        <!--<span>Record</span>-->
        <!--</span>-->
        <!--<ul>-->
        <!--&lt;!&ndash;<li nz-menu-item routerLinkActive="active"><a&ndash;&gt;-->
        <!--&lt;!&ndash;routerLink="/pages/preCoupon/merchants">{{'PreCoupon Merchant'|i18n}}</a></li>&ndash;&gt;-->
        <!--<li [permission]="permission.INDEX_EMAIL_LOG" nz-menu-item routerLinkActive="active">-->
        <!--<a routerLink="/pages/coupon/records">{{'Coupon' | i18n}}</a>-->
        <!--</li>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/preCoupon/records">{{'PreCoupon' | i18n}}</a>-->
        <!--</li>-->

        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/ticket/records">{{'Ticket'|i18n}}</a>-->
        <!--</li>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/e-wallet/movie-record">{{'Movie'|i18n}}</a>-->
        <!--</li>-->

        <!--</ul>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</li>-->


        <!--<i nz-icon nzType="tags" nzTheme="outline"></i>-->
        <!--<span>Coupon</span>-->
        <!--</span>-->
        <!--<ul>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/coupon/merchants">{{'Coupon Merchant' | i18n}}</a></li>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/coupon/records">{{'Coupon Record' | i18n}}</a>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</li>-->

        <!--<li nz-submenu [permission]="permission.DISPLAY_COUPON_SECTION">-->
        <!--<span title>-->
        <!--<i nz-icon nzType="tags" nzTheme="outline"></i>-->
        <!--<span>PreCoupon</span>-->
        <!--</span>-->
        <!--<ul>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/preCoupon/merchants">{{'PreCoupon Merchant'|i18n}}</a></li>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/preCoupon/records">{{'PreCoupon Record' | i18n}}</a>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</li>-->

        <!--<li nz-submenu [permission]="permission.DISPLAY_COUPON_SECTION">-->
        <!--<span title>-->
        <!--<i nz-icon nzType="tags" nzTheme="outline"></i>-->
        <!--<span>Ticket</span>-->
        <!--</span>-->
        <!--<ul>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/ticket/ticket-sessions">{{'Ticket Session'|i18n}}</a></li>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/ticket/ticket-types">{{'Ticket Type'|i18n}}</a>-->
        <!--</li>-->

        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/ticket/records">{{'Ticket Record'|i18n}}</a>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</li>-->
        <!--<li nz-submenu [permission]="permission.DISPLAY_COUPON_SECTION">-->
        <!--<span title>-->
        <!--<i nz-icon nzType="video-camera" nzTheme="outline"></i>-->
        <!--<span>Movie</span>-->
        <!--</span>-->
        <!--<ul>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/e-wallet/movie-product">{{'Movie Product'|i18n}}</a></li>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/e-wallet/movie-record">{{'Movie Record'|i18n}}</a>-->
        <!--</li>-->

        <!--</ul>-->
        <!--</li>-->


        <!--<li nz-submenu [permission]="permission.DISPLAY_COUPON_SECTION">-->
        <!--<span title>-->
        <!--<i nz-icon nzType="tags" nzTheme="outline"></i>-->
        <!--<span>Coupon</span>-->
        <!--</span>-->
        <!--<ul>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/coupon/merchants">{{'Coupon Merchant' | i18n}}</a></li>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/coupon/records">{{'Coupon Record' | i18n}}</a>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</li>-->


        <!--<li nz-submenu [permission]="permission.DISPLAY_COUPON_SECTION">-->
        <!--<span title>-->
        <!--<i nz-icon nzType="tags" nzTheme="outline"></i>-->
        <!--<span>PreCoupon</span>-->
        <!--</span>-->
        <!--<ul>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/preCoupon/merchants">{{'PreCoupon Merchant'|i18n}}</a></li>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/coupon/records">{{'Coupon Record' | i18n}}</a>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</li>-->
        <!--<li nz-submenu [permission]="permission.DISPLAY_COUPON_SECTION">-->
        <!--<span title>-->
        <!--<i nz-icon nzType="tags" nzTheme="outline"></i>-->
        <!--<span>Ticket</span>-->
        <!--</span>-->
        <!--<ul>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/ticket/ticket-sessions">{{'Ticket Session'|i18n}}</a></li>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/ticket/ticket-types">{{'Ticket Type'|i18n}}</a>-->
        <!--</li>-->

        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/ticket/records">{{'Ticket Record'|i18n}}</a>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</li>-->

        <!--<li nz-submenu [permission]="permission.DISPLAY_COUPON_SECTION">-->
        <!--<span title>-->
        <!--<i nz-icon nzType="video-camera" nzTheme="outline"></i>-->
        <!--<span>Movie</span>-->
        <!--</span>-->
        <!--<ul>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/e-wallet/movie-product">{{'Movie Product'|i18n}}</a></li>-->
        <!--<li nz-menu-item routerLinkActive="active"><a-->
        <!--routerLink="/pages/e-wallet/movie-record">{{'Movie Record'|i18n}}</a>-->
        <!--</li>-->

        <!--</ul>-->
        <!--</li>-->


        <li nz-submenu [permission]="permission.DISPLAY_EWALLET_SECTION">
          <span title>
      <i nz-icon nzType="appstore" nzTheme="outline"></i>
            <span>App</span>
          </span>
            <ul>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS"><a
                        routerLink="/pages/e-wallet/users">Users</a>
                </li>

                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_FOR_ADVISER"><a
                        routerLink="/pages/e-wallet/users">Users</a>
                </li>

                <li nz-menu-item routerLinkActive="active"
                    [permission]="permission.DISPLAY_EWALLET_FOR_ADVISER">
                    <a routerLink="/pages/e-wallet/transactions">Transactions</a>
                </li>

                <!--<li routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_MERCHANTS">-->
                <!--<a routerLink="/pages/e-wallet/users-need-valid">App Users Need Valid</a>-->
                <!--</li>-->
                <li nz-menu-item routerLinkActive="active"
                    [permission]="permission.DISPLAY_EWALLET_TRANSACTIONS">
                    <a routerLink="/pages/e-wallet/transactions">Transactions</a>
                </li>

                <li nz-submenu [permission]="permission.DISPLAY_COUPON_SECTION">
          <span title>

            <span>Product</span>
          </span>

                    <ul>
                        <!--<li nz-menu-item routerLinkActive="active"><a-->
                        <!--routerLink="/pages/preCoupon/merchants">{{'PreCoupon Merchant'|i18n}}</a></li>-->
                        <li nz-menu-item routerLinkActive="active"
                            [permission]="permission.DISPLAY_EWALLET_VIRTUAL_GOOD_LIST">
                            <a routerLink="/pages/e-wallet/quick-search">{{'Quick Search' | i18n}}</a>
                        </li>
                        <li nz-menu-item routerLinkActive="active"
                            [permission]="permission.DISPLAY_EWALLET_VIRTUAL_GOOD_LIST">
                            <a routerLink="/pages/e-pay">{{'E-Pay' | i18n}}</a>
                        </li>
                        <li [permission]="permission.INDEX_EMAIL_LOG" nz-menu-item routerLinkActive="active">
                            <a routerLink="/pages/coupon/records">{{'Coupon' | i18n}}</a>
                        </li>
                        <li nz-menu-item routerLinkActive="active"><a
                                routerLink="/pages/preCoupon/records">{{'Redeemable V' | i18n}}</a>
                        </li>

                        <li nz-menu-item routerLinkActive="active"><a
                                routerLink="/pages/ticket/records">{{'Exchangeable V'|i18n}}</a>
                        </li>

                        <li nz-menu-item routerLinkActive="active"><a
                                routerLink="/pages/e-com/records">{{'E-Com'|i18n}}</a>
                        </li>
                        <li nz-menu-item routerLinkActive="active"><a
                                routerLink="/pages/e-wallet/movie-record">{{'Movie'|i18n}}</a>
                        </li>

                    </ul>

                </li>

                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_BUSINESSES">
                    <a routerLink="/pages/e-wallet/businesses">Businesses</a>
                </li>
                <!--<li nz-menu-item routerLinkActive="active"-->
                <!--[permission]="permission.DISPLAY_EWALLET_VIRTUAL_GOOD_LIST">-->
                <!--<a routerLink="/pages/e-pay">{{'E-Pay' | i18n}}</a>-->
                <!--</li>-->
                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/claw-crane">ClawCrane Setting</a>
                </li>

                <li nz-menu-item routerLinkActive="active"
                    [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/cal-coupon-transactions">Data Analysis</a>
                </li>


                <!--<li routerLinkActive="active" [permission]="permission.DISPLAY_COUPONS">-->
                <!--<a routerLink="/pages/e-wallet/coupons">Coupon Setting</a>-->
                <!--</li>-->
                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/topic-group">FrontPage Content</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/app-advertisement-list">Banner</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/app-message">Notification MKT</a>
                </li>


                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/app-promotion">Promotion</a>
                </li>

                <!--<li routerLinkActive="active" [permission]="permission.DISPLAY_COUPONS">-->
                <!--<a routerLink="/pages/e-wallet/preCoupons">PreCoupon Setting</a>-->
                <!--</li>-->
                <!--<li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_COUPONS">-->
                <!--<a routerLink="/pages/e-wallet/promos">Promo</a>-->
                <!--</li>-->
                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_COUPONS">
                    <a routerLink="/pages/e-wallet/draw">Prize Draw</a>
                </li>

                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/app-feedback">Online Feedback</a>
                </li>

                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/app-help">Help Center</a>
                </li>
                <!--<li routerLinkActive="active" [permission]="permission.DISPLAY_COUPONS">-->
                <!--<a routerLink="/pages/e-wallet/movie-product">Movie Products</a>-->
                <!--</li>-->
                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/movie-stock">VoucherStocking</a>
                </li>

                <!--<li routerLinkActive="active" [permission]="permission.DISPLAY_COUPONS">-->
                <!--<a routerLink="/pages/e-wallet/special">Topic Setting</a>-->
                <!--</li>-->

                <!--<li routerLinkActive="active" [permission]="permission.DISPLAY_COUPONS">-->
                <!--<a routerLink="/pages/e-wallet/ticket-sessions">Ticket Session Setting</a>-->
                <!--</li>-->
                <!--<li routerLinkActive="active" [permission]="permission.DISPLAY_COUPONS">-->
                <!--<a routerLink="/pages/e-wallet/ticket-merchants">Ticket Merchant</a>-->
                <!--</li>-->


                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/app-card-list">Card Appearance</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/business-keywords">Keywords</a>
                </li>

                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_EWALLET_USERS">
                    <a routerLink="/pages/e-wallet/app-home-entry">Global Information</a>
                </li>
            </ul>
        </li>


        <li nz-submenu [permission]="permission.INDEX_TRANSACTION_ORDER">
          <span title>
      <i nz-icon nzType="transaction" nzTheme="outline"></i>
            <span>Transaction</span>
          </span>
            <ul>

                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_TRANSACTION_ORDER">
                    <a routerLink="/pages/organisations/orders">{{'Transactions' | i18n}}</a>
                </li>

                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_INVOICE">
                    <a routerLink="/pages/invoice/invoice-list">{{'Invoices' | i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_ALL_WALLET_LIST">
                    <a routerLink="/pages/wallets/wallet-list">{{'Wallet List' | i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_WALLET_DETAIL">
                    <a routerLink="/pages/wallet-detail/wallet-detail-list">{{'Wallet Detail' | i18n}}</a>
                </li>
            </ul>
        </li>


        <li nz-submenu [permission]="permission.INDEX_SETTLE_RECORD">
                 <span title>
<i nz-icon nzType="calculator" nzTheme="outline"></i>
            <span>Finance</span>
          </span>

            <ul>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_AM">
                    <a routerLink="/pages/settlements/settlement-list">{{'Settlements' | i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_AM">
                    <a routerLink="/pages/e-wallet/transactions-dd-fail">DD Fail Transactions</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_AM">
                    <a routerLink="/pages/settlements/dd-refund-list">{{'DD Refund' | i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_AM">
                    <a routerLink="/pages/settlements/polipay-refund-list">{{'Polipay Refund' | i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_AM">
                    <a routerLink="/pages/settlements/settle-record">{{'Settle Record' | i18n}}</a>
                </li>
                <!--<li routerLinkActive="active">-->
                <!--<a routerLink="/pages/settlements/merchant-cash-wallet">{{'Merchant Cash Wallet List' | i18n}}</a>-->
                <!--</li>-->

                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_AM">
                    <a routerLink="/pages/settlements/merchant-cash">{{'Prepaid Wallets' | i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_AM">
                    <a routerLink="/pages/settlements/merchant-balance">{{'Merchant Credit Balance' | i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_AM">
                    <a routerLink="/pages/settlements/wallet-adjust">{{'Balance Adjustment' | i18n}}</a>
                </li>
                <!--<li routerLinkActive="active" [permission]="permission.INDEX_ORG_WALLET">-->
                <!--<a>{{'Wallets' | i18n}}</a>-->
                <!--</li>-->
                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_AM">
                    <a routerLink="/pages/banks/bank-list">{{'System Bank' | i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_AM">
                    <a routerLink="/pages/organisations/rebate-structure">{{'Rebate Rule' |
                        i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_PRICING_PLAN">
                    <a routerLink="/pages/organisations/pricing-plan-list">{{'Pricing Plan' | i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_AM">
                    <a routerLink="/pages/organisations/pricing-plan-default-list">{{'Pricing Plan Default' |
                        i18n}}</a>
                </li>
            </ul>
        </li>

        <li nz-submenu [permission]="permission.INDEX_ADMIN_USER">
                 <span title>
   <i nz-icon nzType="hdd" nzTheme="outline"></i>
            <span>System</span>
          </span>

            <ul>
                <li nz-menu-item routerLinkActive="active">
                    <a routerLink="/pages/users/list">{{'Admin User List' | i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active">
                    <a routerLink="/pages/branchs/list">{{'Branch List' | i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active">
                    <a routerLink="/pages/others/wechat-user-list">{{'Wechat User List' | i18n}}</a>
                </li>
                <li nz-menu-item routerLinkActive="active">
                    <a routerLink="/pages/organisations/operation-list">{{'Operation List' | i18n}}</a>
                </li>
                <!--<li routerLinkActive="active">-->
                <!--<a>{{'Api Log' | i18n}}</a>-->
                <!--</li>-->
                <li (click)="clearCache()" nz-menu-item routerLinkActive="active">
                    <a>{{'Clear Cache' | i18n}}</a>
                </li>
            </ul>
        </li>

        <li nz-submenu [permission]="permission.INDEX_EMAIL_LOG">
                 <span title>
   <i nz-icon nzType="bell" nzTheme="outline"></i>
            <span>Notification</span>
          </span>

            <ul>
                <li [permission]="permission.INDEX_EMAIL_LOG" nz-menu-item routerLinkActive="active">
                    <a routerLink="/pages/notifications/email-log">{{'Email Log' | i18n}}</a>
                </li>
            </ul>
        </li>

        <li nz-submenu [permission]="permission.INDEX_CONFIGURATION">


                <span title>
       <i nz-icon nzType="setting" nzTheme="outline"></i>
            <span>Configuration</span>
          </span>
            <ul>
                <li routerLinkActive="active" nz-menu-item [permission]="permission.INDEX_CONFIGURATION">
                    <a routerLink="/pages/others/config-dashboard">{{'Auto Service' | i18n}}</a>
                </li>
            </ul>
        </li>


        <li nz-menu-item routerLinkActive="active" [permission]="permission.DISPLAY_TASK_LIST">
            <i nz-icon nzType="schedule" nzTheme="outline"></i>
            <span>Tasks</span>
            <a routerLink="/pages/tasks/list" title="Tasks">
                <span class="menu-item-parent">{{'Tasks' | i18n}}</span>
                <!-- <span class="badge pull-right inbox-badge margin-right-13">14</span> -->
            </a>
        </li>


        <li nz-menu-item routerLinkActive="active" [permission]="permission.INDEX_EXPORT_FILE">
            <i nz-icon nzType="export" nzTheme="outline"></i>
            <span>Export File</span>
            <a routerLink="/pages/export-file" title="Tasks">
                <span class="menu-item-parent">{{'Export File' | i18n}}</span>
                <!-- <span class="badge pull-right inbox-badge margin-right-13">14</span> -->
            </a>
        </li>
    </ul>
</div>





