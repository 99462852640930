import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Paging} from '../../models/latipay/paging.model';

@Injectable()
export class WechatUserService extends BaseService implements OnInit {


    constructor(public http: HttpClient) {
        super('wechat-user', http);
        this.embeddedStr = '&embedded=wechat-user';
    }

    ngOnInit(): void {
    }

    assignWallet(walletIds: string, wechatUserOpenid: string) {
        return this.http.post(`${this.url}/assignWallet?walletIds=${walletIds}&wechatUserOpenid=${wechatUserOpenid}`, null).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    unassignWallet(walletId: string, wechatUserOpenid: string) {
        return this.http.post(`${this.url}/unassignWallet?walletId=${walletId}&wechatUserOpenid=${wechatUserOpenid}`, null).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByPaging(searchCondition: string, paging: Paging) {
        const sortStr = '&sort=subscribeTime,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${sortStr}${this.embeddedStr + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
