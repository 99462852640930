
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';

@Injectable()
export class OrganisationAssessmentService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('organisation-assessment', http);
        this.embeddedStr = '&embedded=organisation-assessment,organisation,assessment,organisation-assessment-answers';
    }

    ngOnInit(): void {
    }

    getByOrgIdAndAssessmentId(orgId: number, assessmentId: number) {
        return this.http.get(`${this.url}?f_organisation.id=${orgId}&f_organisation.id_op==&f_id=${assessmentId}&f_id_op==`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }


    getByOrgId(orgId: number) {
        return this.http.get(`${this.url}/organisation-id/${orgId}?embedded=assessment`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }
}