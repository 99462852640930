
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Constants} from '../constants/app.constant';
import {MyHttp} from "./http.client.service";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class BeneficiaryService {
  public token: string;
  public url = Constants.API_ENDPOINT + 'beneficiary';
  private payerId: number;
  public payerApiUrl = Constants.API_ENDPOINT + 'v1/payer';
  public payeeApiUrl = Constants.API_ENDPOINT + 'v1/payer';
  public bankAccountUrl = Constants.API_ENDPOINT + 'v1/payee-bank-account';

  constructor(private http: HttpClient) {
    // this.getAdministrators().subscribe((resp: any) => {
    //     console.log(resp);
    // })
  }

  getAll() {
    // get users from api
    return this.http.get(this.url).pipe(
      map((response) => response));
  }

  getPayerList() {
    // get users from api
    return this.http.get(this.payerApiUrl).pipe(
      map((response) => response));
  }

  getPayer(id: number) {
    return this.http.get(this.payerApiUrl + '/' + id).pipe(
      map((response) => response));
  }

  getPayeeList(id) {
    return this.http.get(this.payeeApiUrl + '/' + id + '/payee').pipe(
      map((response) => response));
  }


  getBankAccountsByPayeeIdAndCurrency(payeeId: number, currency: string) {

    return this.http.get(this.bankAccountUrl + '?f_payee.id=' + payeeId + '&f_payee.id_op==&f_currency=' + currency + '&f_currency_op==').pipe(
      map((response) => response));
  }

}
