import {Injectable} from '@angular/core';

import {MyHttp} from './http.client.service';
import {Constants} from '../constants/app.constant';
import {Observable, Subject} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {UserService} from './user.service';
import {RoleService} from './role.service';
import {User} from '../models/user.model';
import {AdminUser} from '../models/admin/admin-user.model';
import {Permission} from '../models/admin/permission.model';
import {Role} from '../models/admin/role.model';
import {LocalStoreService} from './localStore.service';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class PermissionService {
    role: Role;
    user: AdminUser;
    public userUrl = Constants.API_ENDPOINT + 'v1/user';
    public permissions: Permission[];
    public authKeys: string[] = [];
    public subject = new Subject<any>();

    constructor(private http: HttpClient,
                public localStorage: LocalStoreService,
                private userService: UserService,
                private roleService: RoleService,
                private authenticationService: AuthenticationService) {
        // console.log('permission service init');
        // this.getUser();
    }

    getAndStoreUserPermission() {
        this.getUserProfile();
    }

    getUserProfile() {
        this.userService.getUserProfile().subscribe((resp: AdminUser) => {
            this.user = resp;
            this.getPermissions(this.user.role.id);
        });
    }

    getPermissions(id: number) {
        this.roleService.getRole(id).subscribe((resp: Role) => {
            this.role = resp;
            this.retrieveAuthKeys();
        });
    }

    retrieveAuthKeys() {
        this.authKeys = [];
        this.role.rolePermissions.map(rp => {
            if (rp.rules && rp.rules.length > 0) {
                this.authKeys.push(rp.permission.authKey)
            }
        });
        this.storeAuthKeysIntoLocal(this.authKeys);
        this.subject.next(Constants.PERMISSION_CHANGE);
    }

    subscribePermissionStatus(): Observable<any> {
        // return this.permissions;
        return this.subject.asObservable();
    }

    clear() {
        this.subject.next();
    }

    clearAuthKeys() {
        localStorage.removeItem(Constants.CurrentUserPermissions);
    }

    storeAuthKeysIntoLocal(permissions: string[]) {
        localStorage.setItem(Constants.CurrentUserPermissions, JSON.stringify(permissions));
    }

    getAuthKeysFromLocal() {
        let currentPermissions: any;
        // console.log(localStorage.getItem(Constants.CurrentUserPermissions));
        currentPermissions = JSON.parse(localStorage.getItem(Constants.CurrentUserPermissions));
        return currentPermissions;
        // if (!currentPermissions) {
        //     this.getUserProfile();
        // }
    }
}
