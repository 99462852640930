import * as tslib_1 from "tslib";
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
var InvitationCodeDetailService = /** @class */ (function (_super) {
    tslib_1.__extends(InvitationCodeDetailService, _super);
    function InvitationCodeDetailService(http) {
        var _this = _super.call(this, 'invitation-code-detail', http) || this;
        _this.http = http;
        return _this;
    }
    return InvitationCodeDetailService;
}(BaseService));
export { InvitationCodeDetailService };
