import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants/app.constant';
import { Paging } from '../../models/latipay/paging.model';
import { BaseService } from '../base.service';
var DDPaymentOrderService = /** @class */ (function (_super) {
    tslib_1.__extends(DDPaymentOrderService, _super);
    function DDPaymentOrderService(http) {
        var _this = _super.call(this, 'dd-payment-order', http) || this;
        _this.http = http;
        _this.orgUrl = Constants.API_ENDPOINT + 'v1/organisation';
        _this.ddUrl = Constants.API_ENDPOINT + 'v1/dd-payment-order';
        _this.pricingUrl = Constants.API_ENDPOINT + 'v1/pricing-plan';
        _this.couponUrl = Constants.API_ENDPOINT + 'v1/coupon/merchants';
        _this.preCouponUrl = Constants.API_ENDPOINT + 'v1/pre-coupon/merchants';
        return _this;
    }
    DDPaymentOrderService.prototype.ngOnInit = function () {
    };
    DDPaymentOrderService.prototype.getAll = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        return this.http.get(this.orgUrl + "?" + tempPaging.getPagingStr() + searchStr).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    // getAll() {
    //     return this.http.get(`${this.orgUrl}`)
    //         .map((response) => response).catch((res: any) => Observable.throw(res));
    // }
    DDPaymentOrderService.prototype.getAllDdPaymentOrderByPagingDesc = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        // const embeddedStr = '&embedded=transaction-order';
        var sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + searchCondition + "&sort=created,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.getAllDdPaymentOrderByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        // const embeddedStr = '&embedded=transaction-order';
        var sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + searchCondition + "&sort=created,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.getAllByIdsByPaging = function (ids, searchCondition, paging) {
        var idsSearchArray = [];
        ids.forEach(function (id) {
            idsSearchArray.push('f_id=' + id);
        });
        var idsSearchStr = '';
        if (idsSearchArray.length > 0) {
            idsSearchStr = idsSearchArray.join('&');
            idsSearchStr = '&' + idsSearchStr + '&f_id_op=IN';
        }
        var embedded = idsSearchStr + '&embedded=branch,onboard,primary-currency,operation-organisations,operation-organisations.operation,organisation-tags,organisation-tags.tag';
        var url = this.orgUrl + "?" + (paging.getPagingStr() + searchCondition + embedded);
        if (url.indexOf('&f_merchantType_op==&f_merchantType') < 0) {
            url += '&f_merchantType=COMPANY&f_merchantType=INDIVIDUAL&f_merchantType_op=IN';
        }
        return this.http.get(url).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.getAllEWalletMerchantsByPaging = function (searchCondition, paging) {
        var embedded = '&f_merchantType=EWALLET&f_merchantType_op==&embedded=branch,onboard,primary-currency,operation-organisations,operation-organisations.operation,organisation-tags,organisation-tags.tag';
        return this.http.get(this.orgUrl + "?" + (paging.getPagingStr() + searchCondition + embedded)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.getAllCouponMerchantsByPaging = function (searchCondition, paging) {
        return this.http.get(this.couponUrl + "?" + (paging.getPagingStr() + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.getAllPreCouponMerchantsByPaging = function (searchCondition, paging) {
        return this.http.get(this.preCouponUrl + "?" + (paging.getPagingStr() + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.getOrg = function (id) {
        var embedded = '?embedded=primary-currency,affiliate,onboard,organisation-tags,organisation-tags.tag,organisation-tags.tag.tag-category';
        return this.http.get(this.orgUrl + "/" + (id + embedded)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.getPricingList = function (orgId) {
        var str = '?f_merchant.id=' + orgId + '&f_merchant.id_op==';
        return this.http.get("" + (this.pricingUrl + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.update = function (id, org) {
        return this.http.put(this.ddUrl + "/" + id, org).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    // add(org: Organisation) {
    //     return this.http.post(`${this.orgUrl}`, org).pipe(
    //         map((response) => response), catchError((res: any) => observableThrowError(res)),);
    // }
    DDPaymentOrderService.prototype.assign = function (selectedIds, branchId) {
        return this.http.post(this.orgUrl + "/assign?orgIds=" + selectedIds + "&branchId=" + branchId, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.verify = function (selectedIds) {
        return this.http.post(this.orgUrl + "/verify?orgIds=" + selectedIds, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.changeSteps = function (selectedIds, selectedStepId) {
        return this.http.post(this.orgUrl + "/changeSteps?orgIds=" + selectedIds + "&stepId=" + selectedStepId, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.changeStep = function (item) {
        return this.http.post(this.orgUrl + "/changeStep?orgId=" + item.id + "&step=" + item.onboard.step, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.assignOnbordAndChangeStep = function (orgId, selectedOnboardId, selectedOnboardStepId) {
        return this.http.post(this.orgUrl + "/assignOnbordAndChangeStep?onboardId=" + selectedOnboardId + "&orgId=" + orgId + "&step=" + selectedOnboardStepId, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    DDPaymentOrderService.prototype.importMerchant = function (importMerchantObject) {
        return this.http.post(this.orgUrl + "/importMerchant", importMerchantObject).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return DDPaymentOrderService;
}(BaseService));
export { DDPaymentOrderService };
