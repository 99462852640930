import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
var IndustryCodeService = /** @class */ (function (_super) {
    tslib_1.__extends(IndustryCodeService, _super);
    function IndustryCodeService(http) {
        var _this = _super.call(this, 'industry-code', http) || this;
        _this.http = http;
        return _this;
    }
    IndustryCodeService.prototype.ngOnInit = function () {
    };
    IndustryCodeService.prototype.getAllAppHelpByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + searchCondition + "&sort=updated,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    IndustryCodeService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    IndustryCodeService.prototype.getAllCouponTransactionByPaging = function (searchCondition, paging) {
        // 带问号了
        return this.http.get(this.url + "/get-coupon-transaction?" + paging.getPagingStr()).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    IndustryCodeService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    IndustryCodeService.prototype.publishCouponById = function (id) {
        return this.http.post(this.url + "/" + id + "/dispatch", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return IndustryCodeService;
}(BaseService));
export { IndustryCodeService };
