<span class="activity-dropdown" (click)="onToggle()">

    <!--<b *ngIf="myTasks&&userType==='E-Wallet'" class="badge bg-color-red"-->
    <!--style="font-size: 8px;position: relative">{{ pendingEWalletTaskSize }}</b>-->

    <nz-badge *ngIf="myTasks&&userType==='E-Wallet'" nzDot [nzShowDot]="pendingEWalletTaskSize>0" style="margin-right: 20px">
         <i [ngClass]="{'Merchant':'fa fa-tasks', 'E-Wallet':'fa fa-industry','Feedback':'fa fa-question-circle'}[userType]"
            aria-hidden="true"
            style="font-size: 20px;"
            (click)="getPendingTasks()"></i>
    </nz-badge>
     <nz-badge *ngIf="myTasks&&userType==='Merchant'" nzDot [nzShowDot]="pendingMerchantTaskSize>0">
         <i [ngClass]="{'Merchant':'fa fa-tasks', 'E-Wallet':'fa fa-industry','Feedback':'fa fa-question-circle'}[userType]"
            aria-hidden="true"
            style="font-size: 20px;"
            (click)="getPendingTasks()"></i>
    </nz-badge>
     <nz-badge *ngIf="appFeedback!=[]&&userType==='Feedback'" nzDot [nzShowDot]="pendingFeedbackTaskSize>0">
         <i [ngClass]="{'Merchant':'fa fa-tasks', 'E-Wallet':'fa fa-industry','Feedback':'fa fa-question-circle'}[userType]"
            aria-hidden="true"
            style="font-size: 20px;"
            (click)="getPendingTasks()"></i>
    </nz-badge>
    <!--<b *ngIf="myTasks&&userType==='Merchant'" class="badge bg-color-red"-->
    <!--style="font-size: 8px">{{ pendingMerchantTaskSize }}</b>-->
    <!--<b *ngIf="appFeedback!=[]&&userType==='Feedback'" class="badge bg-color-red"-->
    <!--style="font-size: 8px">{{ pendingFeedbackTaskSize }}</b>-->
</span>
<style>
    .ajax-dropdown-merchant:before, .ajax-dropdown-merchant:after {
        right: 33%;
    !important;
    }

    .ajax-dropdown-eWallet:before, .ajax-dropdown-eWallet:after {
        right: 44%;
    !important;
    }

    .ajax-dropdown-feedback:before, .ajax-dropdown-feedback:after {
        right: 44%;
    !important;
    }
</style>
<div style="float: right;alignment: right">
    <div [ngClass]="{'Merchant':'ajax-dropdown-merchant', 'E-Wallet':'ajax-dropdown-eWallet','Feedback':'ajax-dropdown-feedback'}[userType]"
         class="ajax-dropdown" #dropdown style="left:68%;width: 450px; ::after:">
        <h3 style="font-weight: bold; text-align: center;"> {{userType}} </h3>
        <div class="btn-group btn-group-justified" data-toggle="buttons">
            <label *ngIf="userType !== 'Feedback'" class="btn btn-default" (click)="tab = 3"
                   [ngClass]="tab === 3 ? 'active': ''">
                <input type="radio" name="activity"/>
                Pending Tasks
            </label>
            <label *ngIf="userType !== 'Feedback'" class="btn btn-default" (click)="tab = 1"
                   [ngClass]="tab === 1 ? 'active': ''">
                <input type="radio" name="activity"/>
                Unassigned Tasks
            </label>
            <label *ngIf="userType !== 'Feedback'" class="btn btn-default" (click)="tab = 2"
                   [ngClass]="tab === 2 ? 'active': ''">
                <input type="radio" name="activity"/>
                My Tasks
            </label>
            <label *ngIf="userType === 'Feedback'" class="btn btn-default" (click)="tab = 4"
                   [ngClass]="tab === 4 ? 'active': ''">
                <input type="radio" name="activity"/>
                App Feedback
            </label>
        </div>
        <!-- notification content -->
        <div class="ajax-notifications custom-scroll">
            <ul class="notification-body" *ngIf="tab === 4">
                <li *ngFor="let feedback of appFeedback">
                <span class="unread">
                    <a class="msg">
                        <!--<img alt="" class="air air-top-left margin-top-5" height="40" width="40" src="assets/img/avatars/check.png">-->
                        <div class="air air-top-left margin-top-5" style="height: 40px; width: 40px;">
                            <i *ngIf="feedback.userId != null" class="fa fa-user-plus fa-fw"
                               style="font-size: 30px;"></i>
                        </div>
                        <span class="from">{{feedback.categoryId}} - {{feedback.userId}}<i
                                class="icon-paperclip"></i></span>
                        <!--<span  class="label bg-color-yellow txt-color-white pull-right"-->
                        <!--style="position: absolute; top: 3px; right: 0;">{{feedback.userId}}</span>-->
                          <span class="label bg-color-yellow txt-color-white pull-right"
                                style="position: absolute; top: 3px; right: 0;">{{feedback.status === 1 ? '已读' : '未读'}}</span>

                        <!--<span *ngIf="task.status == 'IGNORED'"-->
                        <!--class="label bg-color-grayDark txt-color-white pull-right"-->
                        <!--style="position: absolute; top: 3px; right: 0;">IGNORED</span>-->
                        <!--<span *ngIf="task.status == 'COMPLETED'" class="label bg-color-green txt-color-white pull-right"-->
                        <!--style="position: absolute; top: 3px; right: 0;">COMPLETED</span>-->
                        <span style="white-space: normal;">{{feedback.content}}</span>
                        <span>
                            {{feedback.created * 1000 | date: dateTimeFormat}}
                            <span class="pull-right"
                                  [innerHTML]="getTimePassed(feedback.created) | keepHtml"></span>
                        </span>

                             <a target="_blank" (click)="viewFeedback(feedback)"
                                href="/#/pages/e-wallet/app-feedback/edit/{{feedback.id}}"
                                class="btn btn-xs btn-primary margin-top-5 pull-right">View</a>

                        <!--<button (click)="viewFeedback(feedback)"-->
                        <!--class="btn btn-xs btn-primary margin-top-5">View Feedback</button>-->
                        <!-- <span class="subject">{{task.source}}</span> -->
                        <!--<span class="msg-body" style="white-space: normal;"-->
                        <!--[innerHTML]="getTaskDescription(task)"></span>-->
                        <!--<button (click)="assignToMe(task)"-->
                        <!--class="btn btn-xs btn-primary margin-top-5">Assign to me</button>-->
                        <!--&lt;!&ndash;<button class="btn btn-xs btn-danger margin-top-5">ignore</button>&ndash;&gt;-->
                        <!--<a target="_blank" href="{{feedback.url}}" class="btn btn-xs btn-primary margin-top-5 pull-right">View</a>-->
                    </a>
                </span>
                </li>
            </ul>
            <ul class="notification-body" *ngIf="tab === 1">
                <li *ngFor="let task of unassignedTasks">
                <span class="unread">
                    <a class="msg">
                        <!--<img alt="" class="air air-top-left margin-top-5" height="40" width="40" src="assets/img/avatars/check.png">-->
                        <div class="air air-top-left margin-top-5" style="height: 40px; width: 40px;">
                            <i *ngIf="task.taskType.taskTypeName == 'ONBOARD'" class="fa fa-user-plus fa-fw"
                               style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'BANK_ACCOUNT'" class="fa fa-credit-card fa-fw"
                               style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'INVOICE_VERIFY'" class="fa fa-file-text-o fa-fw"
                               style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'FULL_VERSION' || task.taskType.taskTypeName == 'TRIAL_VERSION'"
                               class="fa fa-user fa-fw" style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'REALNAME'" class="fa fa-user fa-fw"
                               style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'START_VERIFICATION'"
                               class="fa fa-file-archive-o fa-fw" style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'VERIFICATION_COMPLETED'"
                               class="fa fa-file-archive-o fa-fw" style="font-size: 30px;"></i>
                        </div>
                        <span class="from">{{task.taskType?.taskTypeName}}<i class="icon-paperclip"></i></span>
                        <span *ngIf="task.status == 'PENDING'" class="label bg-color-yellow txt-color-white pull-right"
                              style="position: absolute; top: 3px; right: 0;">PENDING</span>
                        <span *ngIf="task.status == 'IGNORED'"
                              class="label bg-color-grayDark txt-color-white pull-right"
                              style="position: absolute; top: 3px; right: 0;">IGNORED</span>
                        <span *ngIf="task.status == 'COMPLETED'" class="label bg-color-green txt-color-white pull-right"
                              style="position: absolute; top: 3px; right: 0;">COMPLETED</span>
                        <span>
                            {{task.created * 1000 | date : dateTimeFormat}}
                            <span *ngIf="task.status == 'PENDING'" class="pull-right"
                                  [innerHTML]="getTimePassed(task.created) | keepHtml"></span>
                        </span>
                        <!-- <span class="subject">{{task.source}}</span> -->
                        <span style="white-space: normal;"
                              [innerHTML]="getTaskDescription(task)"></span>
                        <button (click)="assignToMe(task)"
                                class="btn btn-xs btn-primary margin-top-5">Assign to me</button>
                        <!--<button class="btn btn-xs btn-danger margin-top-5">ignore</button>-->
                        <a target="_blank" href="{{task.url}}" class="btn btn-xs btn-primary margin-top-5 pull-right">View</a>
                    </a>
                </span>
                </li>
            </ul>
            <ul class="notification-body" *ngIf="tab === 2">
                <li *ngFor="let task of myTasks">
                <span class="">
                    <a class="msg">
                        <!--<img alt="" class="air air-top-left margin-top-5" height="40" width="40" src="assets/img/avatars/check.png">-->
                        <div class="air air-top-left margin-top-5" style="height: 40px; width: 40px;">
                            <i *ngIf="task.taskType.taskTypeName == 'ONBOARD'" class="fa fa-user-plus fa-fw"
                               style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'BANK_ACCOUNT'" class="fa fa-credit-card fa-fw"
                               style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'INVOICE_VERIFY'" class="fa fa-file-text-o fa-fw"
                               style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'FULL_VERSION' || task.taskType.taskTypeName == 'TRIAL_VERSION'"
                               class="fa fa-user fa-fw" style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'REALNAME'" class="fa fa-user fa-fw"
                               style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'START_VERIFICATION'"
                               class="fa fa-file-archive-o fa-fw" style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'VERIFICATION_COMPLETED'"
                               class="fa fa-file-archive-o fa-fw" style="font-size: 30px;"></i>
                        </div>
                        <span class="from">{{task.taskType?.taskTypeName}}<i class="icon-paperclip"></i></span>
                        <span *ngIf="task.status == 'PENDING'" class="label bg-color-yellow txt-color-white pull-right"
                              style="position: absolute; top: 3px; right: 0;">PENDING</span>
                        <span *ngIf="task.status == 'IGNORED'"
                              class="label bg-color-grayDark txt-color-white pull-right"
                              style="position: absolute; top: 3px; right: 0;">IGNORED</span>
                        <span *ngIf="task.status == 'COMPLETED'" class="label bg-color-green txt-color-white pull-right"
                              style="position: absolute; top: 3px; right: 0;">COMPLETED</span>
                        <span>
                            {{task.created * 1000 | date : dateTimeFormat}}
                            <span *ngIf="task.status == 'PENDING'" class="pull-right"
                                  [innerHTML]="getTimePassed(task.created) | keepHtml"></span>
                        </span>
                        <!-- <span class="subject">{{task.source? task.source : ''}}</span> -->
                        <span style="white-space: normal;"
                              [innerHTML]="getTaskDescription(task)"></span>
                        <button *ngIf="task.status != 'COMPLETED'" (click)="complete(task)"
                                class="btn btn-xs btn-primary margin-top-5">Complete</button>
                        <!--<button class="btn btn-xs btn-danger margin-top-5">handle</button>-->
                        <a target="_blank" href="{{task.url}}" class="btn btn-xs btn-primary margin-top-5 pull-right">View</a>
                    </a>
                </span>
                </li>
            </ul>
            <ul class="notification-body" *ngIf="tab === 3">
                <li *ngFor="let task of pendingTasks">
                <span class="class">
                    <a class="msg">
                        <div class="air air-top-left margin-top-5" style="height: 40px; width: 40px;">
                            <i *ngIf="task.taskType.taskTypeName == 'ONBOARD'" class="fa fa-user-plus fa-fw"
                               style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'BANK_ACCOUNT'" class="fa fa-credit-card fa-fw"
                               style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'INVOICE_VERIFY'" class="fa fa-file-text-o fa-fw"
                               style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'FULL_VERSION' || task.taskType.taskTypeName == 'TRIAL_VERSION'"
                               class="fa fa-user fa-fw" style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'REALNAME'" class="fa fa-user fa-fw"
                               style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'START_VERIFICATION'"
                               class="fa fa-file-archive-o fa-fw" style="font-size: 30px;"></i>
                            <i *ngIf="task.taskType.taskTypeName == 'VERIFICATION_COMPLETED'"
                               class="fa fa-file-archive-o fa-fw" style="font-size: 30px;"></i>
                        </div>
                        <span class="from">{{task.taskType?.taskTypeName}}<i class="icon-paperclip"></i> <i
                                *ngIf="task.adminUser">(Assigned to: {{ task.adminUser?.name }})</i></span>
                        <span *ngIf="task.status == 'PENDING'" class="label bg-color-yellow txt-color-white pull-right"
                              style="position: absolute; top: 3px; right: 0;">PENDING</span>
                        <span *ngIf="task.status == 'IGNORED'"
                              class="label bg-color-grayDark txt-color-white pull-right"
                              style="position: absolute; top: 3px; right: 0;">IGNORED</span>
                        <span *ngIf="task.status == 'COMPLETED'" class="label bg-color-green txt-color-white pull-right"
                              style="position: absolute; top: 3px; right: 0;">COMPLETED</span>
                        <span>
                            {{ task.created * 1000 | date : dateTimeFormat}}
                            <span *ngIf="task.status == 'PENDING'" class="pull-right"
                                  [innerHTML]="getTimePassed(task.created) | keepHtml"></span>
                        </span>
                        <!-- <span class="subject">{{task.source? task.source : ''}}</span> -->
                        <span style="white-space: normal;"
                              [innerHTML]="getTaskDescription(task)"></span>
                        <button *ngIf="!task.adminUser" (click)="assignToMe(task)"
                                class="btn btn-xs btn-primary margin-top-5">Assign to me</button>
                        <button *ngIf="task.status != 'COMPLETED'" (click)="complete(task)"
                                class="btn btn-xs btn-primary margin-top-5">Complete</button>
                        <!--<button class="btn btn-xs btn-danger margin-top-5">handle</button>-->
                        <a target="_blank" href="{{task.url}}" class="btn btn-xs btn-primary margin-top-5 pull-right">View</a>
                    </a>
                </span>
                </li>
            </ul>
        </div>
        <!-- end notification content -->
        <!-- footer: refresh area -->
        <span> Last updated on: {{lastUpdate | moment : "hh:mm:ss a"}}
            <button type="button" (click)="update()" class="btn btn-xs btn-default pull-right">
            <i class="fa fa-refresh" *ngIf="!dropDownLoading"></i>
            <span *ngIf="dropDownLoading">Loading...</span>
            <i class="fa fa-refresh fa-spin" *ngIf="dropDownLoading"></i>
        </button>
    </span>
        <!-- end footer -->
    </div>
</div>
