import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
var GatewayService = /** @class */ (function (_super) {
    tslib_1.__extends(GatewayService, _super);
    function GatewayService(http) {
        var _this = _super.call(this, 'payment-gateway', http) || this;
        _this.http = http;
        return _this;
    }
    GatewayService.prototype.ngOnInit = function () {
    };
    GatewayService.prototype.getGatewaysFromServer = function () {
        var _this = this;
        return this.getAll().subscribe(function (resp) {
            console.log(resp);
            _this.gateways = resp;
        });
    };
    GatewayService.prototype.getGatewaysFromLocal = function () {
        return this.gateways;
    };
    return GatewayService;
}(BaseService));
export { GatewayService };
