import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers, Http, RequestOptions } from '@angular/http';
var SupportTicketService = /** @class */ (function (_super) {
    tslib_1.__extends(SupportTicketService, _super);
    function SupportTicketService(http, httpP) {
        var _this = _super.call(this, 'merchant/support-ticket', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        return _this;
    }
    SupportTicketService.prototype.ngOnInit = function () {
    };
    SupportTicketService.prototype.getAllTickets = function (searchCondtion, paging, userId) {
        var userIdStr = '&adminUserId=' + userId;
        return this.http.get(this.url + "?" + paging.getPagingStr() + (searchCondtion + userIdStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.getAllUnreadTickets = function (searchCondtion, paging, userId) {
        var userIdStr = '&adminUserId=' + userId;
        return this.http.post(this.url + "/get-unread?" + (searchCondtion + userIdStr), '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.getAllUnreadTicketList = function (searchCondtion, paging, userId) {
        var userIdStr = '&adminUserId=' + userId;
        return this.http.post(this.url + "/get-unread?" + (searchCondtion + userIdStr), '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.getAllTicketsWithoutAdmin = function (searchCondtion, paging) {
        return this.http.get(this.url + "?" + paging.getPagingStr() + searchCondtion).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.getAllTicketByMerchantId = function (searchCondtion, paging, orgId, userId) {
        var str = '&merchantId=' + orgId + '&adminUserId=' + userId;
        return this.http.get(this.url + "?" + paging.getPagingStr() + (searchCondtion + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.addTicket = function (value) {
        return this.http.post(this.url + "/add-ticket", value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.updateSupportTicket = function (value) {
        return this.http.post(this.url + "/update-ticket", value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.getConversations = function (id, paging, userId) {
        var userStr = '&adminUserId=' + userId;
        return this.http.post(this.url + "/get-conversation/" + id + "?" + (paging.getPagingStr() + userStr), '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.addMessage = function (id, value) {
        return this.http.post(this.url + "/add-conversation/" + id, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.updateMessage = function (id, value) {
        return this.http.post(this.url + "/update-conversation/" + id, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.getDraft = function (id) {
        return this.http.post(this.url + "/get-draft/" + id, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.get = function (id) {
        return this.http.get(this.url + "/get-all/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.complete = function (id) {
        return this.http.get(this.url + "/complete/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.reject = function (id) {
        return this.http.get(this.url + "/reject/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.checkEmail = function (email) {
        return this.http.post(this.url + "/check-email/" + email, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.searchCompanyByName = function (name) {
        return this.http.post(this.url + "/search-company-by-name/" + name, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.getCompanyByNZBN = function (name) {
        return this.http.post(this.url + "/get-company-by-nzbn/" + name, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.updatePerson = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-person/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.addPerson = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/add-person/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.deletePerson = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/delete-person/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.updateAccount = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-account/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.updateStore = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-store/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.addAccount = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/add-account/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.updateOnboardStatus = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-onboard-status/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.updateOnboardContactInfo = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-contact-info/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.updateOnboardCompanyInfo = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-company-info/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.checkCompanyInfo = function (value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/check-company-number", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    SupportTicketService.prototype.manualSignUp = function (value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/sign-up", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return SupportTicketService;
}(BaseService));
export { SupportTicketService };
