import {Injectable, OnInit} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs';

@Injectable()
export class CouponUsedService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('coupon_used', http);
    }

    ngOnInit(): void {
    }

    getAllByOrderIds(orderIds: string[]) {
        const tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        const idSortStr = 'sort=id,asc&';
        let orderIdsStr = '';
        if (orderIds) {
            orderIds.forEach(openId => {
                orderIdsStr += `&f_transactionOrderId=${openId}`;
            })
        }
        if (orderIdsStr) {
            orderIdsStr += '&f_transactionOrderId_op=IN';
        }
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${this.embeddedStr}${orderIdsStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByCouponCodes(couponCodes: string[]) {
        const tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        const idSortStr = 'sort=id,asc&';
        let couponCodesStr = '';
        if (couponCodes) {
            couponCodes.forEach(code => {
                couponCodesStr += `&f_couponCode=${code}`;
            })
        }
        if (couponCodesStr) {
            couponCodesStr += '&f_couponCode_op=IN';
        }
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${this.embeddedStr}${couponCodesStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
