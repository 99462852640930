import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Paging } from '../../models/latipay/paging.model';
import { Headers, Http, RequestOptions } from '@angular/http';
var StaticQrCodeService = /** @class */ (function (_super) {
    tslib_1.__extends(StaticQrCodeService, _super);
    function StaticQrCodeService(http, httpP) {
        var _this = _super.call(this, 'static-qr-code', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        return _this;
        // this.embeddedStr = '&embedded=currency,organisation,organisation.primary-currency';
    }
    StaticQrCodeService.prototype.ngOnInit = function () {
    };
    StaticQrCodeService.prototype.getAllCashWallet = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=createDate,desc&';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (this.embeddedStr + searchStr + idSortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    //  /v1/wallet?f_organisation.id=18&f_organisation.id_op==
    StaticQrCodeService.prototype.getAllByPagingByOrgId = function (orgId, searchCondition, paging) {
        var sortStr = '&sort=createDate,desc';
        var embededStr = '&embedded=organisation';
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(this.baseUrl + "wallet?" + paging.getPagingStr() + (this.embeddedStr + str + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    StaticQrCodeService.prototype.getAllByPaging = function (searchCondition, paging) {
        var sortStr = '&sort=modifyDate,desc';
        // const embededStr = '&embedded=organisation';
        // const search = '&f_accountName=钱多多';
        return this.http.get(this.baseUrl + "prepaid-wallet?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    StaticQrCodeService.prototype.saveWallet = function (payeaseType, isOnline, bankAccountId, merchantUserId, value) {
        return this.http.post(this.url + "?merchantUserId=" + merchantUserId + "&payeaseType=" + payeaseType + "&isOnline=" + isOnline + "&bankAccountId=" + bankAccountId, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    StaticQrCodeService.prototype.updateWallet = function (payeaseType, isOnline, bankAccountId, id, value) {
        return this.http.put(this.url + "/" + id + "?payeaseType=" + payeaseType + "&isOnline=" + isOnline + "&bankAccountId=" + bankAccountId, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    StaticQrCodeService.prototype.adjustWalletBalance = function (id, value) {
        return this.http.put(this.url + "/adjust/" + id, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    StaticQrCodeService.prototype.getAllWalletByOpenIds = function (openIds, paging) {
        var openIdsStr = '';
        if (openIds) {
            openIds.forEach(function (openId) {
                openIdsStr += "&openid=" + openId;
            });
        }
        return this.http.get(this.url + "/openids?" + paging.getPagingStr() + openIdsStr);
    };
    // generateCode(id: number, value: any, token: string) {
    //     const header: Headers = new Headers();
    //     header.append('Content-Type', 'application/json');
    //     header.append('Authorization', token);
    //     const options = new RequestOptions({headers: header});
    //     return this.httpP.post(`${this.url}/${id}/add-sessions`, value, options).pipe(
    //         map((response) => response), catchError((res: any) => observableThrowError(res)));
    // }
    StaticQrCodeService.prototype.generateCode = function (value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/generate-qr-code", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    StaticQrCodeService.prototype.updateCode = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.patch(this.url + "/" + id + "/update-qr-code", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return StaticQrCodeService;
}(BaseService));
export { StaticQrCodeService };
