
import {tap} from 'rxjs/operators';
import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {AuthenticationService} from './authentication.service';
import {MyNotifyService} from "./my-notify.service";

@Injectable()
export class RespInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService,
                public myNotifyService: MyNotifyService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const started = Date.now();
        return next
            .handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    const elapsed = Date.now() - started;

                    if (event.status === 404 || event.status >= 500) {
                        this.myNotifyService.notifyFail('Our server is experiencing some error, please try again later.');
                    } else if (event.status === 401) {
                        this.myNotifyService.notifyFail('Login Id or password is wrong, please try again later.');
                    } else if (event.status === 403) {
                        this.myNotifyService.notifyFail('Your login is expired, please logout and re-login.');
                    }
                }
            }));
    }
}
