import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../services/base.service';
var ExportFileService = /** @class */ (function (_super) {
    tslib_1.__extends(ExportFileService, _super);
    function ExportFileService(http) {
        var _this = _super.call(this, 'export-file', http) || this;
        _this.http = http;
        return _this;
    }
    ExportFileService.prototype.ngOnInit = function () {
    };
    return ExportFileService;
}(BaseService));
export { ExportFileService };
