import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
var CouponService = /** @class */ (function (_super) {
    tslib_1.__extends(CouponService, _super);
    function CouponService(http) {
        var _this = _super.call(this, 'coupon', http) || this;
        _this.http = http;
        return _this;
    }
    CouponService.prototype.ngOnInit = function () {
    };
    CouponService.prototype.getAllCouponByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get(this.url + "/get-coupons" + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    CouponService.prototype.getAllCouponByBusinessIdByPaging = function (searchCondition, businessId, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var idStr = '&f_businessId_op==&f_businessId=' + businessId;
        var sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get(this.url + "/get-coupons" + sortStr + paging.getPagingStr() + (this.embeddedStr + idStr + searchCondition) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    CouponService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    CouponService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    CouponService.prototype.publishCouponById = function (id) {
        return this.http.post(this.url + "/" + id + "/dispatch", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return CouponService;
}(BaseService));
export { CouponService };
