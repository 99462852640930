import {Component, OnInit, ElementRef, Renderer, OnDestroy, Input} from '@angular/core';
import {ActivitiesService} from './activities.service';
import {TaskService} from '../../../../pages/tasks/task.service';
import {Task} from 'app/pages/tasks/task.model';
import {UserService} from 'app/services/user.service';
import {AdminUser} from 'app/models/latipay/admin-user.model';
import {IntervalObservable} from 'rxjs/observable/IntervalObservable';
import {MessageService} from '../../../../services/message.service';
import {BaseComponent} from '../../../../theme/shared/base-component/base.component';
import {AppFeedbackService} from '../../../../services/latipay/app-feedback.service';
import {AppFeedback} from '../../../../models/latipay/app-feedback.model';
import {Router} from '@angular/router';
import {AppHelpCategoryService} from '../../../../services/latipay/app-help-category.service';
import {AppHelpCategory} from '../../../../models/latipay/app-help-category.model';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';

declare var $: any;

@Component({
    selector: 'sa-activities',
    templateUrl: './activities.component.html',
    providers: [ActivitiesService],
})
export class ActivitiesComponent extends BaseComponent implements OnInit, OnDestroy {
    pendingTasks: Task[];
    unassignedTasks: Task[];
    myTasks: Task[];
    user: AdminUser;
    count: number;
    feedtab: number;
    loading: any;
    lastUpdate: any;
    active: boolean;
    activities: any;
    currentActivity: any;
    tasks: Task[];
    showTaskVisible = false;
    appFeedback: AppFeedback[] = [];
    appHelpCategory: AppHelpCategory[] = [];
    tab: number;
    dropDownLoading: boolean;
    aliveNew = true;
    pendingTaskSize: any;
    pendingMerchantTaskSize: number;
    pendingEWalletTaskSize: number;
    pendingFeedbackTaskSize: number;
    private documentSub: any;
    @Input() userType: string;

    constructor(private el: ElementRef,
                private renderer: Renderer,
                public router: Router,
                public appHelpCategoryService: AppHelpCategoryService,
                public appFeedbackService: AppFeedbackService,
                private activitiesService: ActivitiesService,
                private messageService: MessageService,
                private userService: UserService,
                private taskService: TaskService) {
        super();

        this.active = false;
        this.activities = [];
        this.count = 0;
        this.lastUpdate = new Date();
        this.pendingMerchantTaskSize = 0;
        this.pendingEWalletTaskSize = 0;
        this.pendingFeedbackTaskSize = 0;
        this.tab = 1;
        this.feedtab = 4;
    }

    ngOnInit() {
        // this.activitiesService.getActivities().subscribe(data => {
        //     this.activities = data;
        //     this.count = data.reduce((sum, it) => sum + it.data.length, 0);
        //     this.currentActivity = data[0];
        // });
        this.getFeeBackCategory();
        this.getAllPendingTaskSize();
        this.getFeedback();
        this.getPendingTasks();
        this.startTaskTimer();
    }

    getFeeBackCategory() {
        this.dropDownLoading = true;
        this.appHelpCategoryService.getAll().subscribe((res: any) => {
            this.appHelpCategory = res.content;
            this.paging.totalSize = res.totalElements;


            this.dropDownLoading = false;
        }, err => {
            this.dropDownLoading = false;
        });
    }

    getAllPendingTaskSize() {
        this.taskService.getAllPendingTaskSize().take(1).subscribe(resp => {
            this.pendingTaskSize = resp;
            console.log('ttttt');
            this.pendingTaskSize.map((t) => {
                if (t.taskType > 6) {
                    this.pendingEWalletTaskSize += parseInt(t.size, 10);
                } else {
                    this.pendingMerchantTaskSize += parseInt(t.size, 10);
                }
            });
        })
    }

    ngOnDestroy(): void {
        this.aliveNew = false;
        this.documentUnsub();
    }

    startTaskTimer() {
        // IntervalObservable.create(120000).subscribe(() => {
        //     this.pendingMerchantTaskSize = 0;
        //     this.pendingEWalletTaskSize = 0;
        //     this.pendingFeedbackTaskSize = 0;
        //     this.getAllPendingTaskSize();
        //
        //     this.getFeedback();
        // })


        Observable.timer(0, 900000)
            .takeWhile(() => this.aliveNew)
            .subscribe(() => {
                this.pendingMerchantTaskSize = 0;
                this.pendingEWalletTaskSize = 0;
                this.pendingFeedbackTaskSize = 0;
                console.log('ffffff');
                this.getAllPendingTaskSize();

                this.getFeedback();
            });

    }

    getUser() {
        this.userService.getUserProfile().subscribe((user: any) => {
            this.user = user;
            this.getMyTasks();
        })
    }

    getPendingTasks() {
        this.dropDownLoading = true;
        this.taskService.getAll('&f_status=PENDING&f_status_op==').subscribe((resp: any) => {
            this.lastUpdate = new Date();
            if (this.userType === 'Merchant') {
                this.pendingTasks = resp.content.filter(t => t.taskType.taskType < 7);
                this.getTaskUrl(this.pendingTasks);

            } else if (this.userType === 'E-Wallet') {
                this.pendingTasks = resp.content.filter(t => t.taskType.taskType > 6);
                this.getTaskUrl(this.pendingTasks);

            }
            // this.getTaskUrl(this.pendingTasks);
            this.getUser();
            this.getUnassignedTasks();
            this.dropDownLoading = false;
        }, err => {
            this.dropDownLoading = false;
        })
    }

    getFeedback() {
        this.dropDownLoading = true;
        this.paging.pageSize = 50;
        this.pendingFeedbackTaskSize = 0;
        this.appFeedbackService.getAllFeekBacks().take(1).subscribe((resp: any) => {
            if (resp) {

                this.appFeedback = resp.content;
                for (let i in this.appFeedback) {
                    if (this.appFeedback[i].status === 0) {

                        this.pendingFeedbackTaskSize = this.pendingFeedbackTaskSize + 1;
                    }

                    if (this.appHelpCategory.length !== 0) {

                        for (const j  in this.appHelpCategory) {
                            if (this.appHelpCategory[j].id === this.appFeedback[i].categoryId) {


                                this.appFeedback[i].categoryId = this.appHelpCategory[j].nameCn;

                                // i['categoryId'] = j['code'];
                            } else if (this.appFeedback[i].categoryId === 9999) {
                                this.appFeedback[i].categoryId = '其他';
                            }
                        }
                    } else {
                        this.appFeedback[i].categoryId = '用户反馈';
                    }
                }


            }
            this.dropDownLoading = false;
        }, err => {
            this.dropDownLoading = false;
        });
    }

    getUnassignedTasks() {
        if (this.pendingTasks && this.pendingTasks.length > 0) {
            if (this.userType === 'Merchant') {
                this.unassignedTasks = this.pendingTasks.filter(t => !t.adminUser && t.taskType.taskType < 7);
            } else if (this.userType === 'E-Wallet') {
                this.unassignedTasks = this.pendingTasks.filter(t => !t.adminUser && t.taskType.taskType > 6);
            }
        } else {
            this.unassignedTasks = null;
        }
    }

    getMyTasks() {
        if (this.pendingTasks && this.pendingTasks.length > 0) {
            if (this.userType === 'Merchant') {
                this.myTasks = this.pendingTasks.filter(t => t.adminUser && t.adminUser.id === this.user.id && t.taskType.taskType < 7);
            } else if (this.userType === 'E-Wallet') {
                this.myTasks = this.pendingTasks.filter(t => t.adminUser && t.adminUser.id === this.user.id && t.taskType.taskType > 6);
            }
        } else {
            this.myTasks = null;
        }
    }

    getTimePassed(created: number) {
        const diff = (Math.floor((Date.now() / 1000)) - created);
        const diffStr = this.millisecondsToStr(diff * 1000);
        if (diff / 60 <= 30) { // half an hour
            return `<span style="color: green;">${diffStr}</span>`;
        } else if (diff / 60 > 30 && diff / 60 <= 60) { // half an hour to one hour
            return `<span style="color: orange;">${diffStr}</span>`;
        } else if (diff / 60 > 60 && diff / 60 <= 240) {
            return `<span style="color: purple;">${diffStr}</span>`;
        } else {
            return `<span style="color: red;">${diffStr}</span>`;
        }
    }

    millisecondsToStr(milliseconds): string {
        function numberEnding(number) {
            return (number > 1) ? 's' : '';
        }

        let temp = Math.floor(milliseconds / 1000);
        const years = Math.floor(temp / 31536000);
        if (years) {
            return years + ' year' + numberEnding(years);
        }
        const days = Math.floor((temp %= 31536000) / 86400);
        if (days) {
            return days + ' day' + numberEnding(days);
        }
        const hours = Math.floor((temp %= 86400) / 3600);
        if (hours) {
            return hours + ' hour' + numberEnding(hours);
        }
        const minutes = Math.floor((temp %= 3600) / 60);
        if (minutes) {
            return minutes + ' minute' + numberEnding(minutes);
        }
        const seconds = temp % 60;
        if (seconds) {
            return seconds + ' second' + numberEnding(seconds);
        }
        return 'just now';
    }

    viewFeedback(feedback: AppFeedback) {
        console.log(feedback);
        this.loading = true;
        feedback.status = 1;


        this.appFeedbackService.get(feedback.id).subscribe((res: any) => {


            if (res) {

                res.status = 1;
                this.appFeedbackService.update(feedback.id, res).subscribe((resp: any) => {

                    if (resp) {

                        this.router.navigate(['/pages/e-wallet/app-feedback/edit/' + feedback.id]);
                    }
                    this.loading = false;
                }, err => {
                    this.loading = false;
                });
                this.getFeedback();
            }
            this.loading = false;
        }, err => {
            this.loading = false;
        });
        // this.getFeedback();

    }

    assignToMe(task: Task) {
        task.adminUser = new AdminUser();
        task.adminUser.id = this.user.id;
        // this.selectedTask.description = this.selectedAdminUserDesc;
        this.taskService.update(task.id, task).subscribe((resp: any) => {
            this.update();
        })
    }

    ignore(task: Task) {
        task.status = 'IGNORED';
        this.taskService.update(task.id, task).subscribe((resp: any) => {
            ////console.log(resp);
            this.update();
        })
    }

    complete(task: Task) {
        task.status = 'COMPLETED';
        this.taskService.update(task.id, task).subscribe((resp: any) => {
            ////console.log(resp);
            this.update();
        })
    }

    setActivity(activity) {
        this.currentActivity = activity;
    }

    onToggle() {
        const dropdown = $('.ajax-dropdown', this.el.nativeElement);
        this.active = !this.active;
        if (this.active) {
            dropdown.fadeIn();

            this.documentSub = this.renderer.listenGlobal('document', 'mouseup', (event) => {
                if (!this.el.nativeElement.contains(event.target)) {
                    dropdown.fadeOut();
                    this.active = false;
                    this.documentUnsub()
                }
            });

        } else {
            dropdown.fadeOut();

            this.documentUnsub();
        }
    }

    update() {
        this.getPendingTasks();
        this.messageService.sendTaskUpdateEvent('TaskChanged');
    }


    documentUnsub() {
        if (this.documentSub && this.documentSub()) {
            this.documentSub = null;
        }
    }

    getTaskUrl(tasks: Task[] | any) {
        tasks.map(t => {
            const parameter = JSON.parse(t.parameter);

            if (parameter.organisation_id) {
                t.targetOrganisationId = parameter.organisation_id;
            }
            if (parameter.invoice_id) {
                t.targetInvoiceId = parameter.invoice_id;
            }
            if (parameter.bank_account_id) {
                t.targetBankAccountId = parameter.bank_account_id;
            }
            if (parameter.id) {
                t.targetId = parameter.id;
            }

            if (t.taskType.taskType === 1 || t.taskType.taskType === 2) {
                t.url = `/#/pages/onboard/detail/${t.targetOrganisationId}?taskId=${t.id}`;
            } else if (t.taskType.taskType === 3) {
                t.url = `/#/pages/invoice/invoice-detail/${t.targetInvoiceId}?taskId=${t.id}`;
            } else if (t.taskType.taskType === 4) {
                t.url = `/#/pages/onboard/full-version/detail/${t.targetId}?taskId=${t.id}`;
            } else if (t.taskType.taskType === 5) {
                t.url = `/#/pages/organisations/detail/${t.targetOrganisationId}?tab=bankAccount&taskId=${t.id}`;
            } else if (t.taskType.taskType === 6) {
                t.url = `/#/pages/organisations/detail/${parameter.id}?taskId=${t.id}`;
            } else if (t.taskType.taskType === 7 || t.taskType.taskType === 8) {
                t.url = `/#/pages/e-wallet/merchants/details/${parameter.merchant_id}?tab=Document`;
            }
        });
    }

    getTaskDescription(task: Task | any) {
        if (task.taskType.taskType === 1 || task.taskType.taskType === 2) {
            return task.taskType.description.replace('${id}', task.targetOrganisationId);
        } else if (task.taskType.taskType === 3) {
            return task.taskType.description.replace('${id}', task.targetInvoiceId);
        } else if (task.taskType.taskType === 4) {
            return task.taskType.description.replace('${id}', task.targetId);
        } else if (task.taskType.taskType === 5) {
            return task.taskType.description.replace('${id}', task.targetOrganisationId).replace('${bank_account_id}', task.targetBankAccountId);
        } else if (task.taskType.taskType === 6) {
            const parameter = JSON.parse(task.parameter);
            return task.taskType.description.replace('${id}', parameter.id).replace('${url}', '<a href="' + parameter.url + '" target="_blank">Show Photo</a>');
        } else if (task.taskType.taskType === 7 || task.taskType.taskType === 8) {
            const parameter = JSON.parse(task.parameter);
            return task.taskType.description.replace('${userId}', parameter.user_id);
        }
    }

    showTask() {
        this.showTaskVisible = true;
    }
}
