import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Store } from '@ngrx/store';
var CurrencyService = /** @class */ (function (_super) {
    tslib_1.__extends(CurrencyService, _super);
    function CurrencyService(http, store) {
        var _this = _super.call(this, 'currency', http) || this;
        _this.http = http;
        _this.store = store;
        return _this;
    }
    CurrencyService.prototype.ngOnInit = function () {
    };
    CurrencyService.prototype.getCurrenciesFromServer = function () {
        var _this = this;
        this.getAll().subscribe(function (resp) {
            console.log(resp);
            _this.currencies = resp;
        });
    };
    CurrencyService.prototype.getCurrenciesFromLocal = function () {
        return this.currencies;
    };
    // fetchCurrencies() {
    //     this.store.dispatch(new SystemOptionActions.FetchCurrencies({}));
    // }
    //
    CurrencyService.prototype.getCurrenciesFromStore = function () {
        return this.store.select(function (o) { return o.systemOptions; });
    };
    return CurrencyService;
}(BaseService));
export { CurrencyService };
