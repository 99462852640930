import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Paging } from '../../models/latipay/paging.model';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
var UserAccountService = /** @class */ (function (_super) {
    tslib_1.__extends(UserAccountService, _super);
    function UserAccountService(http) {
        var _this = _super.call(this, 'user-account', http) || this;
        _this.http = http;
        return _this;
    }
    UserAccountService.prototype.ngOnInit = function () {
    };
    UserAccountService.prototype.getAll = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var embededstr = '&embedded=merchant-user,wallet';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (embededstr + searchStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return UserAccountService;
}(BaseService));
export { UserAccountService };
