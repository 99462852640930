import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paging } from "../../models/latipay/paging.model";
import { BaseService } from "../base.service";
var BankService = /** @class */ (function (_super) {
    tslib_1.__extends(BankService, _super);
    function BankService(http) {
        var _this = _super.call(this, 'bank', http) || this;
        _this.http = http;
        return _this;
    }
    BankService.prototype.ngOnInit = function () {
    };
    BankService.prototype.updateBank = function (id, bank) {
        return this.http.put(this.url + '/' + id, bank).pipe(map(function (response) { return response; }));
    };
    BankService.prototype.addBank = function (bank) {
        return this.http.post(this.url, bank).pipe(map(function (response) { return response; }));
    };
    BankService.prototype.getAllAbledBanks = function () {
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=id,desc&';
        var statusStr = '&f_status=1&f_status_op==';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (this.embeddedStr + statusStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return BankService;
}(BaseService));
export { BankService };
