import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {Constants} from '../constants/app.constant';
import {User} from '../models/user.model';
import {LoginResp} from '../models/admin/login.resp.model';
import {AuthenticationService} from '../services/authentication.service';
import {PermissionService} from '../services/permission.service';

@Injectable()
export class AuthGuard implements CanActivate {

    public user: User;

    constructor(public router: Router,
                public permissionService: PermissionService,
                public authService: AuthenticationService) {
        this.router.events.subscribe((resp: any) => {
                this.checkExpired();
            }
        );
    }

    canActivate() {
        if (localStorage.getItem(Constants.CurrentUser)) {

            this.user = JSON.parse(localStorage.getItem(Constants.CurrentUser));
            // logged in so return true
            this.checkExpired();
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigate(['/auth/login']);
        return false;
    }

    checkExpired() {
        if (localStorage.getItem(Constants.CurrentUser)) {
            const loginResp: LoginResp = JSON.parse(localStorage.getItem(Constants.CurrentUser));
            const now = new Date().getTime() / 1000;
            if (now - loginResp.loginSecond > loginResp.expires_in) {
                alert('Your session has expired, please login again!')
                this.authService.logout();
                this.permissionService.clearAuthKeys();
                this.authService.logout();
                this.router.navigate(['/auth/login']);
            }
        }
    }

}
