import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';
import {Headers, Http, RequestOptions} from '@angular/http';

@Injectable()
export class PromoCategoryLinkService extends BaseService implements OnInit {

    constructor(public http: HttpClient,
                public httpP: Http) {
        super('promo-category-link', http);
    }

    ngOnInit(): void {
    }

    getAllCategoryLink(promoId: number, searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = '&sort=queue,asc';
        const embededStrr = '&embedded=promos';
        const str = '&f_promos.id=' + promoId + '&f_promos.id_op==';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${embededStrr + str + idSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    getAllCategoryLinkSwap(categoryId: number, searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = '&sort=queue,asc';
        const embededStrr = '&embedded=promos';
        const str = '&f_categoryId=' + categoryId + '&f_categoryId_op==';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${embededStrr + str + idSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllTopicByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByBusinessIdByPaging(searchCondition: string, businessId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }

        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc` + (businessId ? `&f_businessId=${businessId}&f_businessId_op==` : '')).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByWalletIdsByPaging(walletIds: string[], searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,asc';
        let walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(id => {
                walletIdsStr += `&f_acceptedMerchants=${id}`;
            })
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${walletIdsStr}${sortStr}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res))
        );
    }

    publishCouponById(id: string) {
        return this.http.post(`${this.url}/${id}/dispatch`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    swapCategoryQueue(origin: string, target: string) {
        return this.http.post(`${this.url}/${origin}/${target}/swap`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    goCategoryQueue(value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/go-sort`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    setTop(origin: string, target: string) {
        return this.http.post(`${this.url}/${origin}/${target}/set-top`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    copyLink(original: number, promoId: number) {
        return this.http.post(`${this.url}/${original}/${promoId}/copy-link`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateLink(promoId: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/${promoId}/update-link`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

}
