import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';

@Injectable()
export class AppBusinessDefaultCommissionService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('app-business-default-commission', http);
    }

    ngOnInit(): void {
    }

    getAllAppFeedbackByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${searchCondition}&sort=created,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    getAllActivityByPaging(searchCondition: string, paging: Paging) {
        let idSortStr = '&sort=queue,asc';
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition + idSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllByQueueByPaging(searchCondition: string, paging: Paging) {
        let idSortStr = '&sort=queue,asc';
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition + idSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    getAllByBusinessIdByPaging(searchCondition: string, businessId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }

        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc` + (businessId ? `&f_businessId=${businessId}&f_businessId_op==` : '')).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByWalletIdsByPaging(walletIds: string[], searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,asc';
        let walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(id => {
                walletIdsStr += `&f_acceptedMerchants=${id}`;
            })
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${walletIdsStr}${sortStr}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res))
        );
    }

    publishCouponById(id: string) {
        return this.http.post(`${this.url}/${id}/dispatch`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateStatus(id: number, enabled: number) {
        return this.http.post(`${this.url}/${id}/${enabled}/update-status`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    adjustDelivery(id: number, count: number) {
        return this.http.post(`${this.url}/${id}/${count}/adjust-delivery`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateAllPrizes(id: number) {
        return this.http.post(`${this.url}/${id}/update-all-prizes`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getEstTransactions(id: number) {
        return this.http.post(`${this.url}/${id}/get-estimate-transactions`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    addBudget(id: number, budget: number) {
        return this.http.post(`${this.url}/${id}/${budget}/add-budget`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllMerchantByPaging(searchCondition: string, raffleId: number, paging: Paging) {
        let idSortStr = '&sort=id,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        let str = '&f_type_op==&f_type=2&f_raffleId_op==&f_raffleId=' + raffleId;
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition + idSortStr + str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllLatipayByPaging(searchCondition: string, raffleId: number, paging: Paging) {
        let idSortStr = '&sort=id,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        let str = '&f_raffleId_op==&f_raffleId=' + raffleId;
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition + idSortStr + str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }
    getAllPrizesByPaging(searchCondition: string, raffleId: number, paging: Paging) {
        let idSortStr = '&sort=id,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        let str = '&f_raffleId_op==&f_raffleId=' + raffleId;
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition + idSortStr + str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    setTop(id: number) {
        return this.http.post(`${this.url}/${id}/set-top`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    goUp(id: number) {
        return this.http.post(`${this.url}/${id}/go-up`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    goDown(id: number) {
        return this.http.post(`${this.url}/${id}/go-down`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

}
