import * as tslib_1 from "tslib";
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
var TagCategoryService = /** @class */ (function (_super) {
    tslib_1.__extends(TagCategoryService, _super);
    function TagCategoryService(http) {
        var _this = _super.call(this, 'tag-category', http) || this;
        _this.http = http;
        return _this;
    }
    return TagCategoryService;
}(BaseService));
export { TagCategoryService };
