import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Headers, Http, RequestOptions} from '@angular/http';

@Injectable()
export class WechatMerchantMaintainService extends BaseService implements OnInit {

    checkStatus(id: any) {
        return this.http.get(`${this.url}/query/${id}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateMaintain(id: any) {
        return this.http.get(`${this.url}/update/${id}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    constructor(public http: HttpClient,
                public httpP: Http) {
        super('wechat-merchant-maintain', http);
    }

    ngOnInit(): void {
    }

    getAllByPaging(searchCondition: string, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}?${this.embeddedStr}&${paging.getPagingStr()}${searchCondition}&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    upLoad(id: any) {
        return this.http.get(`${this.url}/upload-new/${id}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    upLoadUpdate(id: any) {
        return this.http.get(`${this.url}/upload-new-update/${id}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    maintainWallet(id: any) {
        return this.http.post(`${this.url}/maintain-wallet/${id}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    maintainWechatS(id: any) {
        return this.http.post(`${this.url}/maintain-wechat/${id}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    maintainWechatByOrg(id: any, bc: any) {
        return this.http.post(`${this.url}/maintain-wechat-org/${id}`, bc).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    sync() {
        return this.http.get(`${this.url}/sync`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    uploadAllMaintain() {
        return this.http.get(`${this.url}/upload-all`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    checkAll() {
        return this.http.get(`${this.url}/query-all`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateBySubMchId(id: string, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/update-comment/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    uploadWallet(value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/upload-wallet`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


}
