import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';

@Injectable()
export class SpecialService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('topic-item', http);
    }

    ngOnInit(): void {
    }

    getAllSpecialByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=queue,asc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByBusinessIdByPaging(searchCondition: string, businessId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }

        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc` + (businessId ? `&f_businessId=${businessId}&f_businessId_op==` : '')).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByWalletIdsByPaging(walletIds: string[], searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,asc';
        let walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(id => {
                walletIdsStr += `&f_acceptedMerchants=${id}`;
            })
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${walletIdsStr}${sortStr}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res))
        );
    }

    publishCouponById(id: string) {
        return this.http.post(`${this.url}/${id}/dispatch`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    deleteBusinessRels(id: number) {
        return this.http.post(`${this.url}/${id}/delete-business`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    goPromoUp(id: number, topicId: number) {
        return this.http.post(`${this.url}/${id}/${topicId}/go-promo-up`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    goPromoDown(id: number, topicId: number) {
        return this.http.post(`${this.url}/${id}/${topicId}/go-promo-down`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    goPromoTop(id: number, topicId: number) {
        return this.http.post(`${this.url}/${id}/${topicId}/go-promo-top`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    goBusinessUp(id: number, topicId: number) {
        return this.http.post(`${this.url}/${id}/${topicId}/go-business-up`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    goBusinessDown(id: number, topicId: number) {
        return this.http.post(`${this.url}/${id}/${topicId}/go-business-down`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    goBusinessTop(id: number, topicId: number) {
        return this.http.post(`${this.url}/${id}/${topicId}/go-business-top`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    deleteTopicPromo(id: number) {
        return this.http.get(`${this.url}/${id}/delete-promo-topic`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));

    }
}
