import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';

@Injectable()
export class AppPrizeRedeemWalletService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('app-prize-redeem-wallet', http);
    }

    ngOnInit(): void {
    }

    getAllAppFeedbackByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${searchCondition}&sort=created,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    getAllByBusinessIdByPaging(searchCondition: string, businessId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }

        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc` + (businessId ? `&f_businessId=${businessId}&f_businessId_op==` : '')).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByWalletIdsByPaging(walletIds: string[], searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,asc';
        let walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(id => {
                walletIdsStr += `&f_acceptedMerchants=${id}`;
            })
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${walletIdsStr}${sortStr}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res))
        );
    }

    deleteByPrizeId(id: number) {
        return this.http.post(`${this.url}/${id}/delete`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    addBudget(id: number, budget: number) {
        return this.http.post(`${this.url}/${id}/${budget}/add-budget`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

}
