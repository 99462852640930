
import {throwError as observableThrowError, Observable, Subject, BehaviorSubject} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Http, Headers, Response, RequestOptions} from '@angular/http';

import {RatePair} from '../models/rate-pair.model';
import {Constants} from '../constants/app.constant';
import {MyHttp} from './http.client.service';
import {AuthenticationService} from './authentication.service';
import {TransactionSubmit} from '../models/transactionSubmit.model';
import {HttpClient} from "@angular/common/http";


@Injectable()
export class HomeService {
  public url = Constants.API_ENDPOINT + 'v1/rate/real-time';
  public ratePairUrl = Constants.API_ENDPOINT + 'v1/rate/currency-pair';
  public customerRateUrl = Constants.API_ENDPOINT + 'v1/customer-currency-pair';
  public trxUrl = Constants.API_ENDPOINT + 'v1/transaction';
  public canTradeUrl = Constants.API_ENDPOINT + 'v1/sys-config/can-trade';

  public enableRealTimeRate = true;
  public space: Subject<RatePair[]> = new BehaviorSubject<RatePair[]>(null);
  public returnRatePair: RatePair;

  constructor(private http: HttpClient, public authenticationService: AuthenticationService) {
  }


  getAll() {
    // get users from api
    return this.http.get(this.url).pipe(
      map((response) => response));
  }

  getCanTrade() {
    return this.http.get(this.canTradeUrl).pipe(
      map((response) => response));
  }

  getCustomerRatePairs() {
    // get users from api
    return this.http.get(this.customerRateUrl).pipe(
      map((response) => response));
  }

  removeCustomerRate(id: number) {
    return this.http.delete(this.customerRateUrl + '/' + id);
  }

  getRatePairs() {
    // get users from api
    return this.http.get(this.ratePairUrl).pipe(
      map((response) => response));
  }

  saveCustomerRatePair(rate: RatePair) {
    return this.http.post(this.customerRateUrl, rate);
  }

  /**
   * -------- Real Time Rate BEGIN--------
   * */
  startRealtimeRateByRecursion() {
    this.enableRealTimeRate = true;
    this.realtimeRateByRecursion();
  }

  stopRealtimeRateByRecursion() {
    this.enableRealTimeRate = false;
  }

  realtimeRateByRecursion() {
    if (this.enableRealTimeRate) {
      this.getRealTimeRate().subscribe((resp: any) => {
        this.broadcastChange(resp);
        // console.log(resp);
        setTimeout(() => this.realtimeRateByRecursion(), 10000)
      }, err => {
        console.log(err);
        setTimeout(() => this.realtimeRateByRecursion(), 10000)
      });
    }
  }

  broadcastChange(data: RatePair[]) {
    this.space.next(data);
  }

  getRealTimeRate() {
    // get users from api
    return this.http.get(this.url).pipe(
      map((response) => response));
  }

  /**
   * -------- Real Time Rate END--------
   * */

  getCurrenyRatePair = function (pairs: RatePair[], buyCurrency: string, sellCurrency: string) {
    let returnRatePair: any;
    pairs.map(function (p) {
      if (p.buyCurrency === buyCurrency && p.sellCurrency === sellCurrency) {
        p.isCurrencyReversed = false;
        returnRatePair = p;
      } else if (p.buyCurrency === sellCurrency && p.sellCurrency === buyCurrency) {
        p.isCurrencyReversed = true;
        returnRatePair = p;
      }
    });

    return returnRatePair;
  };


  // ---------helper functions----------

  getCurrentOptions(pairs: RatePair[]) {
    const arr = [];
    pairs.map(function (pair) {
      arr.push(pair.buyCurrency);
      arr.push(pair.sellCurrency);
    });
    // debugger;
    return arr.filter(function (value, index) {
      return arr.indexOf(value) === index
    });
  }

  getSellOptionsByBuy(buyCurrency: string, currencyPairs: RatePair[]) {
    const sellCurrencyOptions = [];
    currencyPairs.map(function (pair) {
      // debugger;
      if (pair.buyCurrency === buyCurrency) {
        sellCurrencyOptions.push(pair.sellCurrency)
      }
      if (pair.sellCurrency === buyCurrency) {
        sellCurrencyOptions.push(pair.buyCurrency)
      }
    });
    return sellCurrencyOptions;
  };


  saveTransaction(trx: TransactionSubmit) {
    return this.http.post(this.trxUrl, trx).pipe(
      map((response) => response),
      catchError((res: any) => observableThrowError(res)),);
  }
}
