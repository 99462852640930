import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers, Http, RequestOptions } from '@angular/http';
var WechatMerchantMaintainService = /** @class */ (function (_super) {
    tslib_1.__extends(WechatMerchantMaintainService, _super);
    function WechatMerchantMaintainService(http, httpP) {
        var _this = _super.call(this, 'wechat-merchant-maintain', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        return _this;
    }
    WechatMerchantMaintainService.prototype.checkStatus = function (id) {
        return this.http.get(this.url + "/query/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatMerchantMaintainService.prototype.updateMaintain = function (id) {
        return this.http.get(this.url + "/update/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatMerchantMaintainService.prototype.ngOnInit = function () {
    };
    WechatMerchantMaintainService.prototype.getAllByPaging = function (searchCondition, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + this.embeddedStr + "&" + paging.getPagingStr() + searchCondition + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatMerchantMaintainService.prototype.upLoad = function (id) {
        return this.http.get(this.url + "/upload-new/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatMerchantMaintainService.prototype.upLoadUpdate = function (id) {
        return this.http.get(this.url + "/upload-new-update/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatMerchantMaintainService.prototype.maintainWallet = function (id) {
        return this.http.post(this.url + "/maintain-wallet/" + id, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatMerchantMaintainService.prototype.maintainWechatS = function (id) {
        return this.http.post(this.url + "/maintain-wechat/" + id, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatMerchantMaintainService.prototype.maintainWechatByOrg = function (id, bc) {
        return this.http.post(this.url + "/maintain-wechat-org/" + id, bc).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatMerchantMaintainService.prototype.sync = function () {
        return this.http.get(this.url + "/sync").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatMerchantMaintainService.prototype.uploadAllMaintain = function () {
        return this.http.get(this.url + "/upload-all").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatMerchantMaintainService.prototype.checkAll = function () {
        return this.http.get(this.url + "/query-all").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatMerchantMaintainService.prototype.updateBySubMchId = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-comment/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatMerchantMaintainService.prototype.uploadWallet = function (value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/upload-wallet", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return WechatMerchantMaintainService;
}(BaseService));
export { WechatMerchantMaintainService };
