import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
var AppActivityPrizeService = /** @class */ (function (_super) {
    tslib_1.__extends(AppActivityPrizeService, _super);
    function AppActivityPrizeService(http) {
        var _this = _super.call(this, 'app-activity-prize', http) || this;
        _this.http = http;
        return _this;
    }
    AppActivityPrizeService.prototype.ngOnInit = function () {
    };
    AppActivityPrizeService.prototype.getAllAppFeedbackByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + searchCondition + "&sort=created,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.getAllByQueueByPaging = function (searchCondition, paging) {
        var idSortStr = '&sort=queue,asc';
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + idSortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.publishCouponById = function (id) {
        return this.http.post(this.url + "/" + id + "/dispatch", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.updateStatus = function (id, enabled) {
        return this.http.post(this.url + "/" + id + "/" + enabled + "/update-status", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.adjustDelivery = function (id, count) {
        return this.http.post(this.url + "/" + id + "/" + count + "/adjust-delivery", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.updateAllPrizes = function (id) {
        return this.http.post(this.url + "/" + id + "/update-all-prizes", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.getEstTransactions = function (id) {
        return this.http.post(this.url + "/" + id + "/get-estimate-transactions", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.addBudget = function (id, budget) {
        return this.http.post(this.url + "/" + id + "/" + budget + "/add-budget", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.getAllMerchantByPaging = function (searchCondition, raffleId, paging) {
        var idSortStr = '&sort=id,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_type_op==&f_type=2&f_raffleId_op==&f_raffleId=' + raffleId;
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + idSortStr + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.getAllLatipayByPaging = function (searchCondition, raffleId, paging) {
        var idSortStr = '&sort=id,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_raffleId_op==&f_raffleId=' + raffleId;
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + idSortStr + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.getAllPrizesByPaging = function (searchCondition, activityId, paging) {
        var idSortStr = '&sort=createdOn,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_activityId_op==&f_activityId=' + activityId;
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + idSortStr + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.getAblePrizesByPaging = function (searchCondition, activityId, paging) {
        var idSortStr = '&sort=createdOn,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_enabled_op==&f_enabled=1&f_activityId_op==&f_activityId=' + activityId;
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + idSortStr + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.getDisablePrizesByPaging = function (searchCondition, activityId, paging) {
        var idSortStr = '&sort=createdOn,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_enabled_op==&f_enabled=0&f_activityId_op==&f_activityId=' + activityId;
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + idSortStr + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.setTop = function (id) {
        return this.http.post(this.url + "/" + id + "/set-top", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.goUp = function (id) {
        return this.http.post(this.url + "/" + id + "/go-up", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppActivityPrizeService.prototype.goDown = function (id) {
        return this.http.post(this.url + "/" + id + "/go-down", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return AppActivityPrizeService;
}(BaseService));
export { AppActivityPrizeService };
