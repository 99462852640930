import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
var BranchService = /** @class */ (function (_super) {
    tslib_1.__extends(BranchService, _super);
    function BranchService(http) {
        var _this = _super.call(this, 'branch', http) || this;
        _this.http = http;
        return _this;
    }
    BranchService.prototype.ngOnInit = function () {
    };
    // update(id: number, branch: any) {
    //     return this.http.put(this.url + '/' + id, branch)
    //         .map((response) => response);
    // }
    // add(branch: Branch) {
    //     return this.http.post(this.url, branch)
    //         .map((response) => response);
    // }
    BranchService.prototype.updateBranch = function (id, value) {
    };
    BranchService.prototype.getAllBranchByPaging = function (searchCondition, paging) {
        var idSortStr = '&sort=id,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + idSortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return BranchService;
}(BaseService));
export { BranchService };
