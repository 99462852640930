import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';

@Injectable()
export class AppMessageTaskService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('app-message-task', http);
    }

    ngOnInit(): void {
    }

    getAllMessageByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        // const str = '&f_type_op==&f_type=1';
        const bed = '&embedded=message';
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition  + bed}&sort=modifyDate,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllPopUpByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const bed = '&embedded=message';
        const str = '&f_type_op==&f_type=2';
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition + str + bed}&sort=modifyDate,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByBusinessIdByPaging(searchCondition: string, businessId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }

        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc` + (businessId ? `&f_businessId=${businessId}&f_businessId_op==` : '')).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByWalletIdsByPaging(walletIds: string[], searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,asc';
        let walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(id => {
                walletIdsStr += `&f_acceptedMerchants=${id}`;
            })
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${walletIdsStr}${sortStr}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res))
        );
    }

    publishCouponById(id: string) {
        return this.http.post(`${this.url}/${id}/dispatch`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    deleteById(id: number) {
        return this.http.get(`${this.url}/delete/${id}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
