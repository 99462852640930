
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';
import {Customer} from '../../models/latipay/customer.model';

@Injectable()
export class CustomerService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('customer', http);
    }

    ngOnInit(): void {
    }

    getCustomerByUserId(userId: String) {
        return this.http.get(`${this.baseUrl}customer?f_userId=${userId}&f_userId_op==`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    updateCustomer(id: number, data: Customer) {
        return this.http.put(`${this.baseUrl}customer/${id}`, data).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }
}
