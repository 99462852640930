import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Http, Headers, Response, RequestOptions} from '@angular/http';
import {Observable, throwError as observableThrowError} from 'rxjs';

import {LocalStoreService} from './localStore.service';
import {Constants} from '../constants/app.constant';
import {User} from '../models/user.model';
import {MyHttp} from './http.client.service';
import {HttpErrorHaddle} from '../theme/shared/http-error-handle/http.error.haddle';
import {LoginResp} from '../models/admin/login.resp.model';
import {LoginPResp} from '../models/admin/loginP.resp.model';



@Injectable()
export class ParasiteAuthenticationService {
    public user: User;
    public ptoken: string;
    public poToken: string;


    constructor(private http: Http) {
        // set token if saved in local storage
        const currentPUser = JSON.parse(localStorage.getItem(Constants.CurrentPUser));
        this.ptoken = currentPUser && currentPUser.token;
    }

    login(): Observable<boolean> {
        // const params = new URLSearchParams;
        // params.append('username', username);
        // params.append('password', password);
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const options = new RequestOptions({headers: headers});

        const postData = {
            'username': 'admin'
            ,
            'password': '8DarbyLatipay'

        };
        console.log(postData);
        let url = Constants.API_PARA_ENDPOINT + 'api/token-get/';
        return this.http.post(url, postData, options).pipe(
            map((response: Response) => {
                console.log('=======parasite token=====');
                console.log(response.json());
                // login successful if there's a jwt token in the response
                const loginResp: LoginPResp = response.json();
                // console.log(token);
                if (loginResp.token) {
                    // set token property
                    this.ptoken = loginResp.token;


                    // store username and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem(Constants.CurrentPUser, JSON.stringify(loginResp));

                    // return true to indicate successful login
                    return true;
                } else {
                    console.log('lol');
                    return false;
                }
            }), catchError(HttpErrorHaddle.handleError),);
    }



    getToken() {

        const t: LoginPResp = JSON.parse(localStorage.getItem(Constants.CurrentPUser));
        if (t) {
            return t.token;
        }
    }





    // logout(): void {
    //     // clear token remove user from local storage to log user out
    //     this.ptoken = null;
    //     localStorage.removeItem(Constants.CurrentPUser);
    //     localStorage.removeItem(Constants.CurrentPUserInfo);
    //     this.http.post(Constants.API_ENDPOINT + 'logout', null);
    //
    // }

    storeUserInfo(user: User) {
        localStorage.setItem(Constants.CurrentUserInfo, JSON.stringify(user));
    }

    getUserInfo() {
        let user: User = new User();
        user = JSON.parse(localStorage.getItem(Constants.CurrentUserInfo));
        return user;
    }

    checkIsBroker() {
        this.user = this.getUserInfo();
        if (this.user && this.user.userType === Constants.USER.broker) {
            return true;
        } else {
            return false;
        }
    }

}
