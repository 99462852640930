
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Constants} from '../constants/app.constant';
import {MyHttp} from './http.client.service';
import {Payee} from '../models/payee.model';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class PayeeService implements OnInit {


    public payeeUrl = Constants.API_ENDPOINT + 'v1/payee';

    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {

    }

    disablePayee(payeeId: number, bool: boolean) {
        const data = {
            enable: bool
        };
        return this.http.patch(this.payeeUrl + '/' + payeeId, data).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getPayee(id: number): any {
        const embedded = '?embedded=attachments,payer,payee-bank-accounts';
        return this.http.get(this.payeeUrl + '/' + id + embedded).pipe(map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getPayeeByPayerId(payerId: number) {
        return this.http.get(Constants.API_ENDPOINT + 'v1/payer/' + payerId + '/payee').pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getPayeeByPayerIdForAdmin(payerId: number, userId: number) {
        return this.http.get(Constants.API_ENDPOINT + 'v1/payer/' + payerId + '/payee?userId=' + userId).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    // http://api.ccfxtrader.com/v1/payee-bank-account?f_currency=NZD&f_currency_op=%3D&f_payee.id=137&f_payee.id_op=%3D
    getBankAccountsByPayeeId(payeeId: number, currency: string) {
        return this.http
            .get(Constants.API_ENDPOINT + 'v1/payee-bank-account?f_currency=' + currency + '&f_currency_op=%3D&f_payee.id=' + payeeId + '&f_payee.id_op=%3D').pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }


    getTrx(id: number) {
        return this.http.get(this.payeeUrl + '/' + id).pipe(map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }


    getAllByPaging(pageNumber: number, pageSize: number, status: string[]) {
        const queryString = status.join('&f_status=');
        return this.http.get(this.payeeUrl
            + '?page=' + pageNumber
            + '&size=' + pageSize
            + '&f_status=' + queryString).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    // http://api.ccfxtrader.com/v1/payer/2/payee?page=1&size=12
    getAllByPagingAndPayerId(id: number, payeeName: string, pageNumber: number, pageSize: number, status: string[]) {
        let search = '';
        if (payeeName && payeeName !== undefined) {
            search = '&f_name=' + payeeName;
        } else {
            search = '';
        }
        const embedded = '?embedded=creator,payer';

        const queryString = status.join('&f_status=');
        return this.http.get(`${Constants.API_ENDPOINT}v1/payer/${id}/payee${embedded}&page=${pageNumber}&size=${pageSize}${search}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    // url: API.url + 'v1/payer/:payerId/payee',
    addPayee(payerId: number, value: Payee) {
        return this.http.post(Constants.API_ENDPOINT + 'v1/payer/' + payerId + '/payee', value).pipe(map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    // url: API.url + 'v1/payer/:payerId/payee',
    updatePayee(payeeId: number, value: Payee) {
        return this.http.put(this.payeeUrl + '/' + payeeId, value).pipe(map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    verifyPayees(selectedIds: string) {
        return this.http.put(`${this.payeeUrl}/verify?ids=${selectedIds}`, {}).pipe(
            map((response) => response),
            catchError((res: any) => observableThrowError(res)),);
    }
}
