import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Headers, Http, RequestOptions } from '@angular/http';
var WalletBankAccountsService = /** @class */ (function (_super) {
    tslib_1.__extends(WalletBankAccountsService, _super);
    function WalletBankAccountsService(http, httpP) {
        var _this = _super.call(this, 'wallet-bank-accounts', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        return _this;
    }
    WalletBankAccountsService.prototype.ngOnInit = function () {
    };
    WalletBankAccountsService.prototype.getAllByPagingByWalletId = function (walletId, searchCondition, paging) {
        var sortStr = '&sort=id,desc';
        var embededStr = '&embedded=bank-account';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_walletId=' + walletId;
        return this.http.get(this.url + "?" + paging.getPagingStr() + (str + sortStr + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletBankAccountsService.prototype.addByList = function (code, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/add-by-list/" + code, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletBankAccountsService.prototype.delete = function (id) {
        return this.http.post(this.url + "/" + id + "/delete", {}).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletBankAccountsService.prototype.setDefault = function (id) {
        return this.http.post(this.url + "/" + id + "/set-default", {}).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletBankAccountsService.prototype.getAllExisting = function (id, code, paging) {
        return this.http.post(this.url + "/" + id + "/" + code + "/except-existing?" + paging.getPagingStr(), '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return WalletBankAccountsService;
}(BaseService));
export { WalletBankAccountsService };
