import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Paging } from '../../models/latipay/paging.model';
var WalletService = /** @class */ (function (_super) {
    tslib_1.__extends(WalletService, _super);
    function WalletService(http) {
        var _this = _super.call(this, 'wallet', http) || this;
        _this.http = http;
        _this.embeddedStr = '&embedded=currency,organisation,organisation.primary-currency';
        return _this;
    }
    WalletService.prototype.ngOnInit = function () {
    };
    WalletService.prototype.getAllWalletApiByPaging = function (searchCondition, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "/api-wallet-list?" + paging.getPagingStr() + (this.embeddedStr + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.getAllWalletByPaging = function (searchCondition, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.getAllByPagingByOrgIdFT = function (orgId, searchCondition, paging, pageNum) {
        var sortStr = '&f_disabled_op==&f_disabled=0&sort=createDate,desc';
        var embededStr = '&embedded=organisation';
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        paging.pageNumber = pageNum;
        return this.http.get(this.baseUrl + "wallet?" + paging.getPagingStr() + (this.embeddedStr + str + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.getAllByOrgId = function (orgId) {
        var sortStr = '&sort=createDate,desc';
        var embededStr = '&embedded=organisation';
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        return this.http.get(this.baseUrl + "wallet?" + tempPaging.getPagingStr() + (embededStr + sortStr + str)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    //  /v1/wallet?f_organisation.id=18&f_organisation.id_op==
    WalletService.prototype.getAllByPagingByOrgId = function (orgId, searchCondition, paging) {
        var sortStr = '&f_disabled_op==&f_disabled=0&sort=createDate,desc';
        var embededStr = '&embedded=organisation';
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(this.baseUrl + "wallet?" + paging.getPagingStr() + (this.embeddedStr + str + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.getAllDByPagingByOrgId = function (orgId, searchCondition, paging) {
        var sortStr = '&f_disabled_op==&f_disabled=1&sort=createDate,desc';
        var embededStr = '&embedded=organisation';
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(this.baseUrl + "wallet?" + paging.getPagingStr() + (this.embeddedStr + str + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.getAllMaintainWalletsByPagingByOrgId = function (orgId, searchCondition, paging) {
        var sortStr = '&sort=createDate,desc';
        var embededStr = '&embedded=organisation';
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(this.baseUrl + "wallet/maintain-list?" + paging.getPagingStr() + (this.embeddedStr + str + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.getAllSpecificByPaging = function (searchCondition, paging) {
        var sortStr = '&sort=createDate,desc';
        var embededStr = '&embedded=organisation';
        return this.http.get(this.baseUrl + "wallet?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.saveWallet = function (payeaseType, isOnline, bankAccountId, merchantUserId, value) {
        var embededStr = '&embedded=organisation,primary-currency';
        return this.http.post(this.url + "?merchantUserId=" + merchantUserId + "&payeaseType=" + payeaseType + "&isOnline=" + isOnline + "&bankAccountId=" + bankAccountId + this.embeddedStr, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.generateDefault = function (value) {
        return this.http.post(this.url + "/generate-default", value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.getAllSubMchID = function (id) {
        return this.http.post(this.url + "/get-wechat-id/" + id, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.verifyWallet = function (code) {
        return this.http.post(this.url + "/" + code + "/verify", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.updateWallet = function (payeaseType, isOnline, bankAccountId, id, value) {
        var embededStr = '&embedded=organisation,primary-currency';
        return this.http.put(this.url + "/" + id + "?payeaseType=" + payeaseType + "&isOnline=" + isOnline + "&bankAccountId=" + bankAccountId + this.embeddedStr, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.adjustWalletBalance = function (id, value) {
        return this.http.put(this.url + "/adjust/" + id, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.updateAccountName = function (id, value) {
        return this.http.put(this.url + "/update-name/" + id, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.disableWallet = function (id, value) {
        return this.http.put(this.url + "/disable/" + id, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.getAllWalletByOpenIds = function (openIds, paging) {
        var openIdsStr = '';
        if (openIds) {
            openIds.forEach(function (openId) {
                openIdsStr += "&openid=" + openId;
            });
        }
        return this.http.get(this.url + "/openids?" + paging.getPagingStr() + openIdsStr);
    };
    WalletService.prototype.getUnreadWallet = function (id, paging) {
        return this.http.post(this.url + "/get-new-wallet/" + id + "?" + paging.getPagingStr(), '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WalletService.prototype.readWallet = function (id, walletId) {
        return this.http.post(this.url + "/read-new-wallet/" + id + "/" + walletId, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return WalletService;
}(BaseService));
export { WalletService };
