import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../constants/app.constant';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';
import {BaseService} from '../base.service';

@Injectable()
export class OrderService  implements OnInit {


    public orderUrl = Constants.API_ENDPOINT + 'v1/transaction-order';
    embeddedStr = '';

    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {

    }

    getAll() {
        const embeddedStr = 'embedded=organisation,wallet,merchant-user';
        return this.http.get(`${this.orderUrl}?${embeddedStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    getAllByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        const embeddedStr = 'embedded=organisation,wallet,pay-merchant-user,merchant-user,organisation.branch';
        const dateSortStr = '&sort=createDate,desc';
        const sortStr = sortOprions.getSortString(); // 带问号了
        return this.http.get(`${this.orderUrl}${sortStr + embeddedStr}&${paging.getPagingStr()}${searchCondition + dateSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }
    getAllWithoutJoinByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        const embeddedStr = 'embedded=organisation,wallet,pay-merchant-user,merchant-user,organisation.branch';
        const dateSortStr = '&sort=createDate,desc';
        const sortStr = sortOprions.getSortString(); // 带问号了
        return this.http.get(`${this.orderUrl}${sortStr + embeddedStr}&${paging.getPagingStr()}${searchCondition + dateSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllAdjustByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        const embeddedStr = 'embedded=organisation,wallet,pay-merchant-user,merchant-user,organisation.branch';
        const dateSortStr = '&sort=createDate,desc';
        const adjustStr = '&f_type=Adjust';
        const sortStr = sortOprions.getSortString(); // 带问号了
        return this.http.get(`${this.orderUrl}${sortStr + embeddedStr}&${paging.getPagingStr()}${searchCondition + dateSortStr + adjustStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllMerchantTnxByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        const embeddedStr = 'embedded=organisation,wallet,merchant-user,organisation.branch';
        const merchantTypeStr = '&f_organisation.merchantType=COMPANY&f_organisation.merchantType=INDIVIDUAL&f_organisation.merchantType_op=IN';
        const dateSortStr = '&sort=createDate,desc';
        const sortStr = sortOprions.getSortString(); // 带问号了
        return this.http.get(`${this.orderUrl}${sortStr + embeddedStr}&${paging.getPagingStr()}${searchCondition + merchantTypeStr + dateSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    getAllEWalletTnxByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        const embeddedStr = 'embedded=organisation,wallet,pay-organisation,pay-wallet,pay-merchant-user,merchant-user,organisation.branch,coupon-take';
        // const merchantTypeStr = '&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const inAppStr = '&f_inApp=1&f_inApp_op==';
        const dateSortStr = '&sort=createDate,desc';
        const sortStr = sortOprions.getSortString(); // 带问号了
        return this.http.get(`${this.orderUrl}${sortStr + embeddedStr}&${paging.getPagingStr()}${searchCondition + inAppStr + dateSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }
    getAllEWalletTnxWithoutJoinByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        const embeddedStr = 'embedded=wallet,pay-organisation,pay-wallet,pay-merchant-user,organisation.branch,coupon-take';
        // const merchantTypeStr = '&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const inAppStr = '&f_inApp=1&f_inApp_op==';
        const dateSortStr = '&sort=createDate,desc';
        const sortStr = sortOprions.getSortString(); // 带问号了
        return this.http.get(`${this.orderUrl}${sortStr + embeddedStr}&${paging.getPagingStr()}${searchCondition + inAppStr + dateSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllEWalletTnxCountByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        const embeddedStr = 'embedded=organisation,wallet,pay-organisation,pay-wallet,pay-merchant-user,merchant-user,organisation.branch';
        // const merchantTypeStr = '&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const inAppStr = '&f_inApp=1&f_inApp_op==';
        const dateSortStr = '&sort=createDate,desc';
        // 带问号了
        return this.http.get(`${this.orderUrl}?${paging.getPagingStr()}${searchCondition + inAppStr + dateSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllByMerchantIdsByPaging(orgIds, searchCondition: string, sortOprions: Sorts, paging: Paging) {
        const orgIdsSearchArray = [];
        orgIds.forEach(id => {
            orgIdsSearchArray.push('f_organisation.id=' + id);
        });
        let orgIdsSearchStr = '';
        if (orgIdsSearchArray.length > 0) {
            orgIdsSearchStr = orgIdsSearchArray.join('&');
            orgIdsSearchStr = '&' + orgIdsSearchStr + '&f_organisation.id_op=IN';
        }

        const embeddedStr = 'embedded=organisation,wallet,merchant-user,organisation.branch';
        const dateSortStr = '&sort=createDate,desc';
        const sortStr = sortOprions.getSortString(); // 带问号了
        return this.http.get(`${this.orderUrl}${sortStr + embeddedStr}&${paging.getPagingStr()}${searchCondition + dateSortStr}${orgIdsSearchStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllByPagingByWalletId(walletId: string, searchCondition: string, sortOprions: Sorts, paging: Paging) {
        const embeddedStr = 'tag=by-wallet&embedded=organisation,wallet,pay-merchant-user,merchant-user,organisation.branch';
        const dateSortStr = '&sort=createDate,desc';
        const sortStr = sortOprions.getSortString(); // 带问号了
        const str = '&f_accountId=' + walletId + '&f_accountId_op==';
        // const str = '&f_wallet.id=' + walletId + '&f_wallet.id_op==' + '&f_payWallet.id=' + walletId + '&f_payWallet.id_op==';
        return this.http.get(`${this.orderUrl}${sortStr + embeddedStr + str}&${paging.getPagingStr()}${searchCondition + dateSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getDdFailByPagingByWalletId(walletId: string, searchCondition: string, sortOprions: Sorts, paging: Paging) {
        const embeddedStr = 'embedded=organisation,wallet,merchant-user,organisation.branch';
        const dateSortStr = '&sort=createDate,desc';
        const sortStr = sortOprions.getSortString(); // 带问号了
        const str = '&f_wallet.id=' + walletId + '&f_wallet.id_op==';
        return this.http.get(`${this.orderUrl}${sortStr + embeddedStr + str}&${paging.getPagingStr()}${searchCondition + dateSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllByInvoiceIds(customerOrderIds: any[]) {
        let parametemrs = 'f_type=Invoice&f_customerOrderId_op=IN';
        for (const orderId of customerOrderIds) {
            parametemrs += '&f_customerOrderId=' + orderId;
        }
        console.log(`${this.orderUrl}?${parametemrs}`);
        return this.http.get(`${this.orderUrl}?${parametemrs}`).pipe(
            map((response) => response), catchError(response => observableThrowError(response)),);
    }

    getInvoiceTransactionByInvoiceId(customerOrderId) {
        const embeddedStr = 'f_customerOrderId=' + customerOrderId + '&f_status=success&f_customerOrderId_op==&f_type=Invoice&f_type_op==&embedded=organisation,wallet,merchant-user&sort=modifyDate,desc';
        return this.http.get(`${this.orderUrl}?${embeddedStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    //
    // getAllByPagingByWalletId(walletId: string, searchCondition: string, paging: Paging) {
    //     const embeddedStr = 'embedded=organisation,wallet,merchant-user';
    //     const str = '&f_wallet.id=' + walletId + '&f_wallet.id_op==';
    //     return this.http.get(`${this.orderUrl}?${paging.getPagingStr() + embeddedStr}${this.embeddedStr + str + searchCondition}`)
    //         .map((response) => response).catch((res: any) => Observable.throw(res));
    // }

    verify(id: string | number) {
        return this.http.post(`${this.orderUrl}/verify/${id}`, {}).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllByPagingByTransationId(transationId: string, searchCondition: string, paging: Paging) {
        const embeddedStr = '?embedded=organisation,wallet,merchant-user,organisation.branch';
        const dateSortStr = '&sort=createDate,desc';
        const str = '&f_orderId=' + transationId;
        return this.http.get(`${this.orderUrl}${embeddedStr + str}&${paging.getPagingStr()}${searchCondition + dateSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    get(id, paging) {
        const embeddedStr = 'embedded=organisation,wallet,pay-merchant-user,merchant-user,organisation.branch';
        const str = embeddedStr ? `${id}` : id;
        return this.http.get(`${this.orderUrl}/get-basic/${str}&${paging.getPagingStr()}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    searchOrder(id: string, type: string) {
        return this.http.post(`${this.orderUrl}/search-order/${id}/${type}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    manualApproveTransaction(id: string) {
        return this.http.post(`${this.orderUrl}/manual/${id}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
