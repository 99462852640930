import {Ingredient} from '../../shared/ingredient.model';
import {AdminUserActions} from './actions/admin-user.actions';
import {AdminUserActionConstants} from './actions/admin-user-action.constant';
import {AdminUserStateInterface} from './interfaces/state.interface';
import {AdminUserInitialStateConstant} from "./admin-user-initial-state.constant";




export function adminUserListReducer(state = AdminUserInitialStateConstant, action: AdminUserActions) {
    switch (action.type) {
        case (AdminUserActionConstants.SET_ADMINUSERS):
            console.log('AdminUserActions.SET_ADMINUSERS');
            return {
                ...state,
                adminUsers: [...action.payload]
            };
        // case (AdminUserActionConstants.ADD_ADMINUSER):
        //     return {
        //         ...state,
        //         adminUsers: [...state.adminUsers, action.payload]
        //     };
        case (AdminUserActionConstants.UPDATE_ADMINUSER):
            const adminUser = state.adminUsers[action.payload.index];
            const updatedAdminUser = {
                ...adminUser,
                ...action.payload.updatedAdminUser
            };
            const adminUsers = [...state.adminUsers];
            adminUsers[action.payload.index] = updatedAdminUser;
            return {
                ...state,
                adminUsers: adminUsers
            };
        case (AdminUserActionConstants.DELETE_ADMINUSER):
            const oldAdminUsers = [...state.adminUsers];
            oldAdminUsers.splice(action.payload, 1);
            return {
                ...state,
                adminUsers: oldAdminUsers
            };
        default:
            return state;
    }
}
