import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Constants} from '../../constants/app.constant';
import {Paging} from '../../models/latipay/paging.model';

@Injectable()
export class SettlementService implements OnInit {

    public orderUrl = Constants.API_ENDPOINT + 'v1/settle-order';
    public recordUrl = Constants.API_ENDPOINT + 'v1/settle-record';
    private settleRecord: any;

    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {

    }

    get(id) {
        const embeddedStr = 'embedded=';
        return this.http.get(`${this.orderUrl}/${id}?${embeddedStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllWithoutDDByPaging(searchCondition: string, paging: Paging) {
        const embeddedStr = 'embedded=';
        const typeWithoutBackup = '&f_type=0&f_type=1&f_type=2&f_type_op==&sort=modifyDate,asc';
        return this.http.get(`${this.orderUrl}?${paging.getPagingStr()}${searchCondition + typeWithoutBackup}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getRateWithCurrency(from: string, to: string, amount: number) {

        return this.http.get(`https://data.fixer.io/api/convert?access_key=8f015d44177ab99a1f2b4d3afce94f1d&from=${from}&to=${to}&amount=${amount}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllWithoutDDCompletedByPaging(searchCondition: string, paging: Paging) {
        const embeddedStr = 'embedded=';
        const typeWithoutBackup = '&f_type=0&f_type=1&f_type=2&f_type_op==&sort=modifyDate,desc';
        return this.http.get(`${this.orderUrl}?${paging.getPagingStr()}${searchCondition + typeWithoutBackup}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllByPaging(searchCondition: string, paging: Paging) {
        const embeddedStr = 'embedded=';
        const typeWithoutBackup = '&f_type=0&f_type=1&f_type=2&f_type=4&f_type=5&f_type_op==';
        return this.http.get(`${this.orderUrl}?${paging.getPagingStr()}${searchCondition + typeWithoutBackup}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    approve(settlementIds: string, bankId: string) {
        const params = {'settlementIds': settlementIds, 'bankId': bankId};
        return this.http.put(this.orderUrl + '/approve', params).pipe(
            map((response) => response));
    }

    reject(settlementIds: string, comment: String) {
        const params = {'settlementIds': settlementIds, 'comment': comment};
        return this.http.put(this.orderUrl + '/reject', params).pipe(
            map((response) => response));
    }

    getRecord(id) {
        const embeddedStr = 'embedded=archive-file';
        return this.http.get(`${this.recordUrl}/${id}?${embeddedStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllRecordsByPaging(searchCondition: string, paging: Paging) {
        const embeddedStr = 'embedded=archive-file';
        return this.http.get(`${this.recordUrl}?${embeddedStr}&${paging.getPagingStr()}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getVerifyReport(paging: Paging) {
        return this.http.post(`${this.recordUrl}/check-report?${paging.getPagingStr()}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllReportDetail(recordId: number) {
        return this.http.post(`${this.recordUrl}/store-settle/${recordId}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getDdRefundByPaging(searchCondition: string, paging: Paging) {
        const embeddedStr = 'embedded=';
        const typeWithoutBackup = '&f_type=4&f_type_op==';
        return this.http.get(`${this.orderUrl}?${paging.getPagingStr()}${searchCondition + typeWithoutBackup}`, {headers: new HttpHeaders({timeout: `${500000}`})}).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getPolipayRefundByPaging(searchCondition: string, paging: Paging) {
        const embeddedStr = 'embedded=';
        const typeWithoutBackup = '&f_type=5&f_type_op==';
        return this.http.get(`${this.orderUrl}?${paging.getPagingStr()}${searchCondition + typeWithoutBackup}`, {headers: new HttpHeaders({timeout: `${500000}`})}).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    saveValue(value: any) {
        this.settleRecord = value;
    }

    getInputValue() {
        return this.settleRecord;
    }
}
