import {Component, ViewContainerRef} from '@angular/core';
import {AppReadyEvent} from './app-ready.component';
import {GatewayService} from './services/latipay/gateway.service';
import {PermissionService} from './services/permission.service';
import {CurrencyService} from './services/latipay/currency.service';
import {CountryService} from './services/latipay/country.service';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent {
    public title = 'app works!';

    public constructor(private viewContainerRef: ViewContainerRef,
                       private appReadyEvent: AppReadyEvent,
                       public gatewayService: GatewayService,
                       public permissionService: PermissionService,
                       public currencyService: CurrencyService,
                       public countryService: CountryService) {
        appReadyEvent.trigger();
        // currencyService.getCurrenciesFromServer();
        // gatewayService.getGatewaysFromServer();
        // currencyService.getCurrenciesFromServer();
    }
}
