import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
var AppCouponService = /** @class */ (function (_super) {
    tslib_1.__extends(AppCouponService, _super);
    function AppCouponService(http) {
        var _this = _super.call(this, 'app-coupon', http) || this;
        _this.http = http;
        return _this;
    }
    AppCouponService.prototype.ngOnInit = function () {
    };
    AppCouponService.prototype.getAllAppCouponByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCouponService.prototype.getAllCoupons = function (walletId, sortOprions, paging) {
        var sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get(this.url + "/get-coupons/" + walletId + sortStr + paging.getPagingStr() + this.embeddedStr + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCouponService.prototype.getAllLatipayCouponByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        var str = '&f_issuer_op==&f_issuer=0';
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition + str) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCouponService.prototype.getAllCouponByBusinessIdByPaging = function (searchCondition, businessId, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var idStr = '&f_businessId_op==&f_businessId=' + businessId;
        var sortStr = sortOprions.getSortString();
        // this.embeddedStr = '&embedded=coupons-promo';
        return this.http.get(this.url + "/get-coupons" + sortStr + paging.getPagingStr() + (this.embeddedStr + idStr + searchCondition) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCouponService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCouponService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCouponService.prototype.publishCouponById = function (id) {
        return this.http.post(this.url + "/" + id + "/dispatch", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCouponService.prototype.addExtra = function (id) {
        return this.http.post(this.url + "/" + id + "/create-extra", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCouponService.prototype.applyCoupon = function (id) {
        return this.http.post(this.url + "/" + id + "/apply", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCouponService.prototype.copyCoupon = function (id) {
        return this.http.post(this.url + "/" + id + "/copy", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCouponService.prototype.closeCoupon = function (id) {
        return this.http.post(this.url + "/" + id + "/close", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCouponService.prototype.adjust = function (id, data) {
        return this.http.put(this.url + "/" + id + "/adjust-number", data).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppCouponService.prototype.get = function (id) {
        var str = this.embeddedStr ? id + "?" + this.embeddedStr : id;
        var idd = '&embedded=prepaid-wallet';
        return this.http.get(this.url + "/" + str + "?" + idd).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return AppCouponService;
}(BaseService));
export { AppCouponService };
