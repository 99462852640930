import * as tslib_1 from "tslib";
import { AdminUserActionConstants } from './actions/admin-user-action.constant';
import { AdminUserInitialStateConstant } from "./admin-user-initial-state.constant";
export function adminUserListReducer(state, action) {
    if (state === void 0) { state = AdminUserInitialStateConstant; }
    switch (action.type) {
        case (AdminUserActionConstants.SET_ADMINUSERS):
            console.log('AdminUserActions.SET_ADMINUSERS');
            return tslib_1.__assign({}, state, { adminUsers: action.payload.slice() });
        // case (AdminUserActionConstants.ADD_ADMINUSER):
        //     return {
        //         ...state,
        //         adminUsers: [...state.adminUsers, action.payload]
        //     };
        case (AdminUserActionConstants.UPDATE_ADMINUSER):
            var adminUser = state.adminUsers[action.payload.index];
            var updatedAdminUser = tslib_1.__assign({}, adminUser, action.payload.updatedAdminUser);
            var adminUsers = state.adminUsers.slice();
            adminUsers[action.payload.index] = updatedAdminUser;
            return tslib_1.__assign({}, state, { adminUsers: adminUsers });
        case (AdminUserActionConstants.DELETE_ADMINUSER):
            var oldAdminUsers = state.adminUsers.slice();
            oldAdminUsers.splice(action.payload, 1);
            return tslib_1.__assign({}, state, { adminUsers: oldAdminUsers });
        default:
            return state;
    }
}
