import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Paging} from '../../models/latipay/paging.model';
import {OrgUser} from '../../models/latipay/org-user.model';
import {MerchantUser} from '../../models/latipay/merchant.user.model';

@Injectable()
export class OrgUserService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('org-user', http);
    }

    ngOnInit(): void {
    }


    getAllByPagingByOrgId(orgId: number, searchCondition: string, paging: Paging) {
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(`${this.baseUrl}merchant-user?${paging.getPagingStr()}${this.embeddedStr + str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    getAllByPagingByOrgIdWithWallet(orgId: number, searchCondition: string, paging: Paging) {
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        const embedded = '&embedded=user-accounts,user-accounts.wallet';
        return this.http.get(`${this.baseUrl}merchant-user?${paging.getPagingStr()}${embedded + str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    getAllActiveByPagingByOrgIdWithWallet(orgId: number, searchCondition: string, paging: Paging) {
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op=='+ '&f_disabled_op==&f_disabled=0';
        const embedded = '&embedded=user-accounts,user-accounts.wallet';
        return this.http.get(`${this.baseUrl}merchant-user?${paging.getPagingStr()}${embedded + str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    getAllDisableByPagingByOrgIdWithWallet(orgId: number, searchCondition: string, paging: Paging) {
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==' + '&f_disabled_op==&f_disabled=1';
        const embedded = '&embedded=user-accounts,user-accounts.wallet';
        return this.http.get(`${this.baseUrl}merchant-user?${paging.getPagingStr()}${embedded + str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    getAll(searchStr ?: string) {
        searchStr = searchStr ? searchStr : '';
        let tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        let idSortStr = '&sort=id,asc';
        return this.http.get(`${this.baseUrl}merchant-user?${tempPaging.getPagingStr()}${this.embeddedStr + searchStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllByOrgId(orgId: number) {
        const paging = new Paging({pageNumber: 1, pageSize: 10000});
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        const embedded = '&embedded=organisation';
        return this.http.get(`${this.baseUrl}merchant-user?${paging.getPagingStr()}${embedded + str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getRolesIdsByOrgId(orgId: number) {

        const paging = new Paging({pageNumber: 1, pageSize: 10000});
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        const str1 = '&f_rolesId=' + 0 + '&f_rolesId_op==';
        return this.http.get(`${this.baseUrl}merchant-user?${paging.getPagingStr()}${this.embeddedStr + str + str1}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getMerchantUser(id: string) {
        const embed = '&embedded=organisation&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        return this.http.get(`${this.baseUrl}merchant-user/${id}${embed}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getOneEWalletUsers(searchCondition: string, paging: Paging) {
        const embedded = '&embedded=organisation&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        // const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency,organisation.org-bind-account&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const sortId = '&sort=id,desc';
        return this.http.get(`${this.baseUrl}merchant-user?${paging.getPagingStr() + embedded + searchCondition + sortId}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getByOrgIdAndMerchantUserId(orgId: number, id: string) {
        // http://localhost:10000/v1/merchant-user?page=1&size=12&f_organisation.id=26&f_organisation.id_op==&f_id=U000000043&f_id_op==
        const paging = new Paging({pageNumber: 1, pageSize: 10000});
        let str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        str += '&f_id=' + id + '&f_id_op==';
        return this.http.get(`${this.baseUrl}merchant-user?${paging.getPagingStr()}${this.embeddedStr + str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getMerchantUserWithUserAccounts(id: string) {
        return this.http.get(`${this.baseUrl}merchant-user/${id}?embedded=user-accounts,user-accounts.wallet`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
    getMerchantUserWithOut(id: string) {
        const embedded = '&embedded=organisation';
        return this.http.get(`${this.baseUrl}merchant-user/${id}?embedded=organisation`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    patchMerchantUser(id: string, data: OrgUser) {
        return this.http.patch(`${this.baseUrl}merchant-user/${id}`, data).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateMerchantUser(id: string, data: MerchantUser) {
        return this.http.put(`${this.baseUrl}merchant-user/${id}`, data).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    saveMerchantUser(data: MerchantUser) {
        return this.http.post(`${this.baseUrl}merchant-user`, data).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    exportAnalysisUser(data: string, token: string, timeId: string) {
        return this.http.post(`${this.baseUrl}merchant-user/export-user-analysis`, data).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    copyVerifoneApiKey(id: string) {
        return this.http.put(`${this.baseUrl}merchant-user/verifone/${id}`, null).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllEWalletUsersWithoutPaging(searchCondition: string) {
        const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const sortId = '&sort=id,desc';
        const paging = new Paging({pageNumber: 1, pageSize: 10000});
        return this.http.get(`${this.baseUrl}merchant-user?${paging.getPagingStr() + embedded + searchCondition + sortId}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllEWalletUsersUseForMessage(searchCondition: string) {
        const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const sortId = '&sort=id,desc';
        const paging = new Paging({pageNumber: 1, pageSize: 10000});
        return this.http.get(`${this.baseUrl}merchant-user?${paging.getPagingStr() + embedded + searchCondition + sortId}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllEWalletUsers(searchCondition: string, paging: Paging) {
        const embedded = '&embedded=organisation,organisation.org-bind-account&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        // const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency,organisation.org-bind-account&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const sortId = '&sort=id,desc';
        return this.http.get(`${this.baseUrl}merchant-user/user-list/?${paging.getPagingStr() + embedded + searchCondition + sortId}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllMerchanttUsers(searchCondition: string, paging: Paging) {
        const embedded = '&embedded=organisation&f_organisation.merchantType=COMPANY&f_organisation.merchantType_op==';
        // const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency,organisation.org-bind-account&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const sortId = '&sort=id,desc';
        return this.http.get(`${this.baseUrl}merchant-user?${paging.getPagingStr() + embedded + searchCondition + sortId}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllEWalletInvalidUsers(searchCondition: string, paging: Paging) {
        const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const sortId = '&sort=id,desc';
        return this.http.get(`${this.baseUrl}merchant-user/get-invalid-user?${paging.getPagingStr() + embedded + searchCondition + sortId}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    getAllEWalletSMSWithDateInvalidUsers(fromStr: string, toStr: string, paging: Paging) {
        const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const sortId = '&sort=id,desc';
        return this.http.get(`${this.baseUrl}merchant-user/${fromStr}/${toStr}/get-sms-date-invalid-user?${paging.getPagingStr()}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllEWalletSMSInvalidUsers(searchCondition: string, paging: Paging) {

        return this.http.get(`${this.baseUrl}merchant-user/get-sms-invalid-user?${paging.getPagingStr() + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllEWalletValidUsers(searchCondition: string, paging: Paging) {
        const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const sortId = '&sort=id,desc';
        return this.http.get(`${this.baseUrl}merchant-user/get-valid-user?${paging.getPagingStr() + embedded + searchCondition + sortId}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllEWalletErrorUsers(searchCondition: string, paging: Paging) {
        const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const sortId = '&sort=id,desc';
        return this.http.get(`${this.baseUrl}merchant-user/get-error-user?${paging.getPagingStr() + embedded + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllEWalletUnbindUsers(searchCondition: string, paging: Paging) {
        const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const sortId = '&sort=id,desc';
        return this.http.get(`${this.baseUrl}merchant-user/get-unbind-user?${paging.getPagingStr() + embedded + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    getAllEWalletSendSMSUsers(searchCondition: string, paging: Paging) {
        const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const sortId = '&sort=id,desc';
        return this.http.get(`${this.baseUrl}merchant-user/get-send-sms-user?${paging.getPagingStr() + embedded + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    getAllEWalletNeedValidUsers(searchCondition: string, paging: Paging) {
        const embedded = '&embedded=organisation,organisation.bank-accounts,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        const sortId = '&sort=organisation.tradeName,desc';
        return this.http.get(`${this.baseUrl}merchant-user/need-valid?${paging.getPagingStr() + embedded + searchCondition + sortId}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    exportExcelUrl(searchCondition: string) {
        const sortId = '&sort=id,desc';
        const embedded = '&embedded=organisation,organisation.primary-currency&f_organisation.merchantType=EWALLET&f_organisation.merchantType_op==';
        return `v1/merchant-user/excel?${searchCondition}${embedded}${sortId}`
    }

    getPhoneCode(phone: string) {
        return this.http.get(`${this.baseUrl}merchant-user/get-phone-code/${phone}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getUserWithMerchantType(id: string) {
        return this.http.get(`${this.baseUrl}merchant-user?&f_id=${id}&embedded=organisation`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    testSms() {
        return this.http.post(`${this.baseUrl}merchant-user/test-message` , '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
