import {Sort} from './sort.model';

export class Sorts {
    sortColumns: Sort[];

    getSortString() {
        let sortString = '?';
        this.sortColumns.map(s => {
            const t = s.isAsc ? 'asc' : 'desc';
            if (s.isSortable && s.isActive) {
                sortString = sortString + 'sort=' + s.columnModel + ',' + t + '&';
            }
        });
        return sortString;
    }

    constructor() {
        this.sortColumns = []
    }
}