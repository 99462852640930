
import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Constants} from '../constants/app.constant';
import {SystemConfig} from '../models/admin/system-config.model';
import {HttpClient} from "@angular/common/http";


@Injectable()
export class SysConfigService {
    public endpoint = Constants.API_ENDPOINT + 'v1/sys-config';
    constructor(private http: HttpClient) {
    }

    page(pageNumber: number, pageSize: number, searchCondition: string) {
        return this.http.get(`${this.endpoint}?page=${pageNumber}&size=${pageSize}${searchCondition}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    get(id: number) {
        return this.http.get(`${this.endpoint}/${id}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    // updatePermission(id: any, value: Permission) {
    //     return this.http.put(`${this.permissionUrl}/${id}`, value)
    //         .map((response) => response).catch((res: any) => Observable.throw(res));
    // }

    add(value: SystemConfig) {
        return this.http.post(`${this.endpoint}`, value).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    update(id: any, value: SystemConfig) {
        return this.http.put(`${this.endpoint}/${id}`, value).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }
}
