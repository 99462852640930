
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Currency} from "../../models/latipay/currency.model";

@Injectable()
export class AssessmentService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('assessment', http);
        this.embeddedStr = 'embedded=assessment';
    }

    ngOnInit(): void {
    }

    getAllByCodeAndMerchantType(code: string, merchantType: string) {
        return this.http.get(`${this.url}?f_merchantType=${merchantType}&f_code=${code}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);

    }
}