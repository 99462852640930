import {environment} from '../../environments/environment';
import {UserType} from '../models/admin/user-type.model';
import {TrxType} from '../models/admin/trx-type.model';
import {Tab} from '../models/latipay/tab.model';
import {MerchantType} from '../models/latipay/merchant-type.model';
import {SettlementType} from '../models/admin/settlement-type.model';
import {OrderType} from '../models/latipay/order-type';
import {SettlementsTab} from '../models/latipay/settlements-tab.model';
import {Step} from '../models/latipay/onboard/step.model';

export class Constants {
    public static CurrentUser = 'latipay-admin-current-user';
    public static CurrentPUser = 'latipay-parasite-admin-current-user';
    public static CurrentUserInfo = 'latipay-admin-current-user-detail';
    public static CurrentUserPermissions = 'latipay-admin-current-user-permission';
    public static API_ENDPOINT = environment.apiUrl;
    public static API_PARA_ENDPOINT = environment.apiParaUrl;
    public static PERMISSION_CHANGE = 'latipay-admin-current-user-permission-changed';
    public static Status = {
        booked: 'BOOKED',
        payeesAdded: 'PAYEES_ADDED',
        processing: 'PROCESSING',
        received: 'RECEIVED',
        canceled: 'CANCELED',
        settled: 'SETTLED',
        statusForSpot: [
            'BOOKED',
            'PAYEES_ADDED',
            'PROCESSING',
            'CANCELED'
        ]
    };
    public static RecevedTrxType = {
        parent: 'parent-trx',
        undividableLeaf: 'leaf-trx-can-not-be-divide',
        dividableLeaf: 'leaf-trx-can-be-divide'
    };
    public static USER: UserType = {
        broker: 'BROKER',
        individual: 'INDIVIDUAL',
        company: 'COMPANY',
        backend: 'BACKEND',
        nonBackend: 'NONBACKEND'
    };

    public static ADMINUSERTYPES: string[] =
        [
            'BROKER',
            'INDIVIDUAL',
            'COMPANY',
            'BACKEND',
        ];


    public static TrxType: TrxType = {
        NonBrokerUnsettled: 'NonBrokerUnsettled',
        NonBrokerSettled: 'NonBrokerSettled',
        BrokerUnsettled: 'BrokerUnsettled',
        BrokerSettled: 'BrokerSettled',
        DealingBlotter: 'DealingBlotter',
        DealingBlotterChecked: 'DealingBlotterChecked',
        TrxPl: 'TrxPl'
    };

    public static SettlementsTab: SettlementsTab = {
        pending: 'Pending',
        completed: 'Completed',
        rejected: 'Rejected',
        failed: 'Failed'
    };

    public static TabName: Tab = {
        profile: 'Profile',
        document: 'Document',
        bdm: 'BDM',
        pricing: 'Pricing',
        user: 'User',
        wallet: 'Wallet',
        bankAccount: 'bankAccount',
        onboardAccountDetail: 'onboardAccountDetail',
        onboardDirector: 'onboardDirector',
        onboardShareholder: 'onboardShareholder',
        invoice: 'Invoice',
        compliance: 'Compliance',
        supportTicket: 'SupportTicket',
        onboard: 'Onboard'
    };

    public static MerchantType: MerchantType = {
        individual: 'INDIVIDUAL',
        company: 'COMPANY',
        ewallet: 'EWALLET'
    };

    public static OrderType: OrderType = {
        orgOrder: 'orgOrder',
        allOrder: 'allOrder'
    };

    public static StepConstant: Step[] = [
        new Step({name: 'NONE', step: 0}),
        new Step({name: 'TRIL', step: 10}),
        new Step({name: 'TRIL_PASS', step: 20}),
        new Step({name: 'FULL', step: 30}),
        new Step({name: 'FULL_PASS', step: 40}),
    ];

    public static ExcelUploadUrl = environment.apiUrl + 'v1/excel';

    // public static FormatUKWithTimeZoneOffset = 'dd/MM/yy HH:mm:ss  ';
    public static FormatUKWithTimeZoneOffset = 'yyyy-MM-dd HH:mm:ss (z) ';
    public static FormatUKWithOutTimeZoneOffset = 'yyyy-MM-dd HH:mm:ss  a (z)';
    public static FormatUKWithOutTimeZoneOffsetAndSecond = 'yyyy-MM-dd HH:mm  ';
    public static FormatUKOnlyDateZoneOffset = 'yyyy-MM-dd';
    public static FormatUSWithTimeZoneOffset = 'M/d/yy h:mm:ss a (z)';
    public static FormatISODate = 'yyyy-MM-dd HH:mm:ss';
    public static FormatUKSimple = 'yyyy-MM-dd HH:mm:ss';
    public static FormatUKOnlyDate = 'yyyy-MM-dd';
}
