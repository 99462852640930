import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers, Http, RequestOptions } from '@angular/http';
var OrgOnboardService = /** @class */ (function (_super) {
    tslib_1.__extends(OrgOnboardService, _super);
    function OrgOnboardService(http, httpP) {
        var _this = _super.call(this, 'onboard/full-version', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        return _this;
    }
    OrgOnboardService.prototype.ngOnInit = function () {
    };
    OrgOnboardService.prototype.getAllOnboarding = function (searchCondtion, token, paging, userId) {
        var userIdStr = '&adminUserId=' + userId;
        return this.http.get(this.url + "/get-all/?" + paging.getPagingStr() + (searchCondtion + userIdStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.get = function (id) {
        return this.http.get(this.url + "/get-all/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.complete = function (id) {
        return this.http.get(this.url + "/complete/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.reject = function (id) {
        return this.http.get(this.url + "/reject/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.checkEmail = function (email) {
        return this.http.post(this.url + "/check-email/" + email, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.searchCompanyByName = function (name) {
        return this.http.post(this.url + "/search-company-by-name/" + name, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.getCompanyByNZBN = function (name) {
        return this.http.post(this.url + "/get-company-by-nzbn/" + name, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.updatePerson = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-person/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.addPerson = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/add-person/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.deletePerson = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/delete-person/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.updateAccount = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-account/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.updateStore = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-store/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.addAccount = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/add-account/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.updateOnboardStatus = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-onboard-status/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.updateOnboardContactInfo = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-contact-info/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.updateOnboardCompanyInfo = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-company-info/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.checkCompanyInfo = function (value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/check-company-number", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgOnboardService.prototype.manualSignUp = function (value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/sign-up", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return OrgOnboardService;
}(BaseService));
export { OrgOnboardService };
