import {Injectable} from '@angular/core';

@Injectable()
export class HelperService {

    constructor() {
    }

    parseToFix(input: number, num: number) {
        if (input) {
            return parseFloat(parseFloat((Math.round(input * 10000) / 10000).toString()).toFixed(num));
        }
    }

    generateQueryString(o: any) {
        let str = '';
        if (o) {
            Object.keys(o).forEach(key => {
                console.log(key);
                if (o[key] !== null && o[key] !== '' && o[key] !== undefined) {
                    str = str + '&f_' + key + '=' + o[key]
                }
            });
        }

        return str;
    }
    generateQueryWithoutFString(o: any) {
        let str = '';
        if (o) {
            Object.keys(o).forEach(key => {
                console.log(key);
                if (o[key] !== null && o[key] !== '' && o[key] !== undefined) {
                    str = str + '&' + key + '=' + o[key]
                }
            });
        }

        return str;
    }

    generateQueryStringCanIgnore(o: any, ignoredKey: string[]) {
        let str = '';
        if (o) {
            Object.keys(o).forEach(key => {
                // console.log('Key:', key);
                if (!ignoredKey.includes(key)) {
                    if (o[key] !== null && o[key] !== '' && o[key] !== undefined) {
                        str = str + '&f_' + key + '=' + o[key]
                    }
                }
            });
        }

        return str;
    }

    copyProperty(objFrom: any, objTo: any) {
        if (objFrom && objTo) {
            // Object.keys()
        }
    }
}
