import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Paging} from '../../models/latipay/paging.model';

@Injectable()
export class WalletService extends BaseService implements OnInit {


    constructor(public http: HttpClient) {
        super('wallet', http);
        this.embeddedStr = '&embedded=currency,organisation,organisation.primary-currency';
    }

    ngOnInit(): void {
    }

    getAllWalletApiByPaging(searchCondition: string, paging: Paging) {

        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}/api-wallet-list?${paging.getPagingStr()}${this.embeddedStr + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllWalletByPaging(searchCondition: string, paging: Paging) {

        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllByPagingByOrgIdFT(orgId: number, searchCondition: string, paging: Paging, pageNum: number) {
        const sortStr = '&f_disabled_op==&f_disabled=0&sort=createDate,desc';
        const embededStr = '&embedded=organisation';
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        paging.pageNumber = pageNum;
        return this.http.get(`${this.baseUrl}wallet?${paging.getPagingStr()}${this.embeddedStr + str + sortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByOrgId(orgId: number) {
        const sortStr = '&sort=createDate,desc';
        const embededStr = '&embedded=organisation';
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';

        const tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});

        return this.http.get(`${this.baseUrl}wallet?${tempPaging.getPagingStr()}${embededStr + sortStr + str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);

    }


    //  /v1/wallet?f_organisation.id=18&f_organisation.id_op==
    getAllByPagingByOrgId(orgId: number, searchCondition: string, paging: Paging) {
        const sortStr = '&f_disabled_op==&f_disabled=0&sort=createDate,desc';
        const embededStr = '&embedded=organisation';
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(`${this.baseUrl}wallet?${paging.getPagingStr()}${this.embeddedStr + str + sortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllDByPagingByOrgId(orgId: number, searchCondition: string, paging: Paging) {
        const sortStr = '&f_disabled_op==&f_disabled=1&sort=createDate,desc';
        const embededStr = '&embedded=organisation';
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(`${this.baseUrl}wallet?${paging.getPagingStr()}${this.embeddedStr + str + sortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllMaintainWalletsByPagingByOrgId(orgId: number, searchCondition: string, paging: Paging) {
        const sortStr = '&sort=createDate,desc';
        const embededStr = '&embedded=organisation';
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(`${this.baseUrl}wallet/maintain-list?${paging.getPagingStr()}${this.embeddedStr + str + sortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllSpecificByPaging(searchCondition: string, paging: Paging) {
        const sortStr = '&sort=createDate,desc';
        const embededStr = '&embedded=organisation';

        return this.http.get(`${this.baseUrl}wallet?${paging.getPagingStr()}${this.embeddedStr + searchCondition + sortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    saveWallet(payeaseType: string, isOnline: boolean, bankAccountId: number, merchantUserId: string | number, value: any) {
        const embededStr = '&embedded=organisation,primary-currency';
        return this.http.post(`${this.url}?merchantUserId=${merchantUserId}&payeaseType=${payeaseType}&isOnline=${isOnline}&bankAccountId=${bankAccountId}${this.embeddedStr}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    generateDefault(value: any) {
        return this.http.post(`${this.url}/generate-default`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllSubMchID(id: number) {
        return this.http.post(`${this.url}/get-wechat-id/${id}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    verifyWallet(code: string) {
        return this.http.post(`${this.url}/${code}/verify`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateWallet(payeaseType: string, isOnline: boolean, bankAccountId: number, id: number, value: any) {
        const embededStr = '&embedded=organisation,primary-currency';
        return this.http.put(`${this.url}/${id}?payeaseType=${payeaseType}&isOnline=${isOnline}&bankAccountId=${bankAccountId}${this.embeddedStr}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    adjustWalletBalance(id: string, value: any) {
        return this.http.put(`${this.url}/adjust/${id}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateAccountName(id: string, value: any) {
        return this.http.put(`${this.url}/update-name/${id}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    disableWallet(id: string, value: any) {
        return this.http.put(`${this.url}/disable/${id}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllWalletByOpenIds(openIds: string[], paging: Paging) {
        let openIdsStr = '';
        if (openIds) {
            openIds.forEach(openId => {
                openIdsStr += `&openid=${openId}`
            })
        }
        return this.http.get(`${this.url}/openids?${paging.getPagingStr()}${openIdsStr}`)
    }


    getUnreadWallet(id: number, paging: Paging) {
        return this.http.post(`${this.url}/get-new-wallet/${id}?${paging.getPagingStr()}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    readWallet(id: number, walletId: string) {
        return this.http.post(`${this.url}/read-new-wallet/${id}/${walletId}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
