import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Paging } from '../../models/latipay/paging.model';
var BankAccountService = /** @class */ (function (_super) {
    tslib_1.__extends(BankAccountService, _super);
    function BankAccountService(http) {
        var _this = _super.call(this, 'bank-account', http) || this;
        _this.http = http;
        return _this;
    }
    BankAccountService.prototype.ngOnInit = function () {
    };
    BankAccountService.prototype.getAllByPagingByOrgId = function (orgId, searchCondition, paging) {
        var sortStr = '&sort=currency,asc&sort=createDate,desc';
        var embededStr = '&embedded=organisation';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(this.baseUrl + "bank-account?" + paging.getPagingStr() + (this.embeddedStr + str + sortStr + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BankAccountService.prototype.getAllByPagingByOrgIdByWalletId = function (orgId, walletId, searchCondition, paging) {
        var sortStr = '&sort=currency,asc&sort=createDate,desc';
        var embededStr = '&embedded=organisation,wallet-bank-accounts';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==&f_walletBankAccounts.walletId=' + walletId;
        return this.http.get(this.baseUrl + "bank-account?" + paging.getPagingStr() + (this.embeddedStr + str + sortStr + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BankAccountService.prototype.getAllByOrgId = function (orgId) {
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var sortStr = '&sort=currency,asc&sort=createDate,desc';
        var embededStr = '&embedded=organisation';
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(this.baseUrl + "bank-account?" + tempPaging.getPagingStr() + (this.embeddedStr + str + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BankAccountService.prototype.setDefault = function (id) {
        return this.http.put(this.baseUrl + "bank-account/" + id + "/set-default", {}).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BankAccountService.prototype.activeAccount = function (id) {
        return this.http.put(this.baseUrl + "bank-account/" + id + "/activate", {}).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BankAccountService.prototype.verify = function (id) {
        return this.http.put(this.baseUrl + "bank-account/" + id + "/verify", {}).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BankAccountService.prototype.delete = function (id) {
        return this.http.put(this.baseUrl + "bank-account/" + id + "/delete", {}).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return BankAccountService;
}(BaseService));
export { BankAccountService };
