
import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Constants} from '../constants/app.constant';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class EmailLogService {

    public endpoint = Constants.API_ENDPOINT + 'v1/email-log';

    constructor(private http: HttpClient) {
    }

    page(pageNumber: number, pageSize: number, searchCondition: string) {
        return this.http.get(`${this.endpoint}?fields=AUDITING&page=${pageNumber}&size=${pageSize}${searchCondition}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    get(id: number) {
        return this.http.get(`${this.endpoint}/${id}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    resend(ids: string) {
        return this.http.put(`${this.endpoint}/resend?ids=${ids}`, null).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);

    }
}
