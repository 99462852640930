import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';

@Injectable()
export class OperationService extends BaseService implements OnInit {


    constructor(public http: HttpClient) {
        super('operation', http);
    }

    ngOnInit(): void {
    }

}