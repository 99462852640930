import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../theme/shared/base-component/base.component';
import {AuthenticationService} from '../../../services/authentication.service';
import {UserService} from '../../../services/user.service';
import {MyNotifyService} from '../../../services/my-notify.service';
import {MenuItem} from 'primeng/api';
import {LayoutService} from '../layout.service';
import {IntervalObservable} from 'rxjs-compat/observable/IntervalObservable';
import schedule from 'node-schedule'
import {AppTransactionMonitorService} from '../../../services/latipay/app-transaction-monitor.service';
import {OrgOnboard} from '../../../models/latipay/org-onboard.model';
import {OrgOnboardService} from '../../../services/latipay/org-onboard.service';

@Component({
    selector: 'sa-navigation',
    templateUrl: './navigation.component.html',
    styles: [
            `
            aside {
                background-color: red;
            }

            nz-header,
            nz-footer {
                background: #7dbcea;
                color: #fff;
            }
        `
    ]
})
export class NavigationComponent extends BaseComponent implements OnInit {

    items: MenuItem[];
    @Input('needCollapsed') needCollapsed: boolean;

    monitorTransactionSize: number = 0;
    unHandleTicketSize: number = 0;
    feedbackSize: number = 0;
    appOnboardSize: number = 0;
    public pToken: string;
    public orgOnboards: OrgOnboard[] = [];


    constructor(public authService: AuthenticationService,
                public userService: UserService,
                public layoutService: LayoutService,
                private orgOnboardService: OrgOnboardService,
                public appTransactionMonitorService: AppTransactionMonitorService,
                public myNotifyService: MyNotifyService) {
        super();
    }

    ngOnInit() {
        // this.startTaskTimer();
        this.getMonitorTransactionSize();
        const schedule = require('node-schedule');
        schedule.scheduleJob('0 0 0-23 * * ?', () => {
            this.getMonitorTransactionSize();
            // this.getOnboardSize();
            // this.getFeedbackSize();
        })

    }


    // toggleCollapsed(): void {
    //     this.layoutService.onCollapseAntMenu()
    // }

    clearCache() {
        this.userService.clearCache().subscribe((resp: any) => {
            if (resp === true) {
                this.myNotifyService.notifySuccess('Cache cleared successfully.')
            }
            console.log('ClearCacheResponse:', resp);
        });
    }

    refresh() {
    }

    // startTaskTimer() {
    //     IntervalObservable.create(120000).subscribe(() => {
    //
    //
    //         this.getMonitorTransactionSize();
    //     })
    // }

    getMonitorTransactionSize() {


        // const str = '&f_status_op==&f_status=0';
        // this.appTransactionMonitorService.getAll(str).subscribe((resp: any) => {
        //     console.log('FullVersionList:', resp);
        //     if (resp && resp.content.length > 0) {
        //         this.monitorTransactionSize = resp.content.length;
        //     }
        //
        //
        // }, err => {
        //
        // });

        this.appTransactionMonitorService.getNavigationTask().subscribe((resp: any) => {
            console.log('FullVersionList:', resp);
            if (resp) {
                let task = resp.toString().split(',');
                console.log(task);
                this.monitorTransactionSize = task[0];
                this.appOnboardSize = task[1];
                this.feedbackSize = task[2];
                this.unHandleTicketSize = task[3];
            }


        }, err => {

        });


    }



    getFeedbackSize() {


        const str = '&f_status_op==&f_status=0';
        this.appTransactionMonitorService.getAll(str).subscribe((resp: any) => {
            console.log('FullVersionList:', resp);
            if (resp && resp.content.length > 0) {
                this.monitorTransactionSize = resp.content.length;
            }


        }, err => {

        });


    }

}
