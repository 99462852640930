import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class InvitationCodeDetailService extends BaseService {
    constructor(public http: HttpClient) {
        super('invitation-code-detail', http);
    }
}
