import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
var WalletDetailService = /** @class */ (function (_super) {
    tslib_1.__extends(WalletDetailService, _super);
    function WalletDetailService(http) {
        var _this = _super.call(this, 'wallet-detail', http) || this;
        _this.http = http;
        return _this;
    }
    WalletDetailService.prototype.ngOnInit = function () {
    };
    return WalletDetailService;
}(BaseService));
export { WalletDetailService };
