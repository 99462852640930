import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class TagService extends BaseService {
    constructor(public http: HttpClient) {
        super('tag', http);
    }
}
