import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Paging } from '../../models/latipay/paging.model';
var VoucherBatchService = /** @class */ (function (_super) {
    tslib_1.__extends(VoucherBatchService, _super);
    function VoucherBatchService(http) {
        var _this = _super.call(this, 'voucher-batch', http) || this;
        _this.http = http;
        return _this;
        // this.embeddedStr = '&embedded=currency,organisation,organisation.primary-currency';
    }
    VoucherBatchService.prototype.ngOnInit = function () {
    };
    VoucherBatchService.prototype.getGenerateCode = function (id) {
        return this.http.get(this.url + "/generate-code/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherBatchService.prototype.addByNum = function (value) {
        return this.http.post(this.url + "/by-number", value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherBatchService.prototype.addPrizeByNum = function (value) {
        return this.http.post(this.url + "/by-prize-number", value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherBatchService.prototype.getAllCashWallet = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=createDate,desc&';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (this.embeddedStr + searchStr + idSortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    //  /v1/wallet?f_organisation.id=18&f_organisation.id_op==
    VoucherBatchService.prototype.getAllByPagingByOrgId = function (orgId, searchCondition, paging) {
        var sortStr = '&sort=createDate,desc';
        var embededStr = '&embedded=organisation';
        var str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(this.baseUrl + "wallet?" + paging.getPagingStr() + (this.embeddedStr + str + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherBatchService.prototype.getAllVoucherBatchByPaging = function (searchCondition, paging) {
        var sortStr = '&sort=created,desc';
        // const search = '&f_accountName=钱多多';
        return this.http.get(this.baseUrl + "voucher-batch?" + paging.getPagingStr() + (searchCondition + sortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherBatchService.prototype.saveWallet = function (payeaseType, isOnline, bankAccountId, merchantUserId, value) {
        return this.http.post(this.url + "?merchantUserId=" + merchantUserId + "&payeaseType=" + payeaseType + "&isOnline=" + isOnline + "&bankAccountId=" + bankAccountId, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherBatchService.prototype.updateWallet = function (payeaseType, isOnline, bankAccountId, id, value) {
        return this.http.put(this.url + "/" + id + "?payeaseType=" + payeaseType + "&isOnline=" + isOnline + "&bankAccountId=" + bankAccountId, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherBatchService.prototype.adjustWalletBalance = function (id, value) {
        return this.http.put(this.url + "/adjust/" + id, value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherBatchService.prototype.getAllWalletByOpenIds = function (openIds, paging) {
        var openIdsStr = '';
        if (openIds) {
            openIds.forEach(function (openId) {
                openIdsStr += "&openid=" + openId;
            });
        }
        return this.http.get(this.url + "/openids?" + paging.getPagingStr() + openIdsStr);
    };
    VoucherBatchService.prototype.getPrefix = function (id) {
        return this.http.get(this.url + "/get-prefix/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherBatchService.prototype.adjust = function (id, data) {
        return this.http.put(this.url + "/" + id + "/adjust-number", data).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return VoucherBatchService;
}(BaseService));
export { VoucherBatchService };
