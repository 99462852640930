import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
var AssessmentService = /** @class */ (function (_super) {
    tslib_1.__extends(AssessmentService, _super);
    function AssessmentService(http) {
        var _this = _super.call(this, 'assessment', http) || this;
        _this.http = http;
        _this.embeddedStr = 'embedded=assessment';
        return _this;
    }
    AssessmentService.prototype.ngOnInit = function () {
    };
    AssessmentService.prototype.getAllByCodeAndMerchantType = function (code, merchantType) {
        return this.http.get(this.url + "?f_merchantType=" + merchantType + "&f_code=" + code).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return AssessmentService;
}(BaseService));
export { AssessmentService };
