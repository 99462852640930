import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
var OperationOrganisationService = /** @class */ (function (_super) {
    tslib_1.__extends(OperationOrganisationService, _super);
    function OperationOrganisationService(http) {
        var _this = _super.call(this, 'operation-organisation', http) || this;
        _this.http = http;
        return _this;
    }
    OperationOrganisationService.prototype.ngOnInit = function () {
    };
    OperationOrganisationService.prototype.getAllByPagingByMerchantId = function (merchantId, searchCondition, paging) {
        var sortStr = '&sort=id,desc';
        var embeddedStr = '&embedded=organisation,operation';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_organisation.id=' + merchantId + '&f_organisation.id_op==';
        return this.http.get(this.baseUrl + "operation-organisation?" + paging.getPagingStr() + (embeddedStr + str + sortStr + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return OperationOrganisationService;
}(BaseService));
export { OperationOrganisationService };
