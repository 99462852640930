import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';

@Injectable()
export class FullVersionService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('onboard/full-version', http);
    }

    ngOnInit(): void {
    }

    getAllByPaging(searchCondition: string, paging: Paging) {
        const sortStr = '&sort=modifyDate,desc';
        // const embededStr = '&embedded=organisation';
        // const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + sortStr + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    createIntoMerchant(id: string) {
        return this.http.post(`${this.url}/convertToMerchant/${id}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateStatus(id: string) {
        return this.http.post(`${this.url}/generate/${id}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
