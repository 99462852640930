import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';

@Injectable()
export class PreCouponService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('pre-coupon', http);
    }

    ngOnInit(): void {
    }

    getAllPreCouponByPaging(searchCondition: string, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllPreCouponByBusinessIdByPaging(searchCondition: string, businessId: number, sortOprions: Sorts, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        const idStr = '&f_businessId_op==&f_businessId=' + businessId;
        const sortStr = sortOprions.getSortString();
        return this.http.get(`${this.url}${sortStr}${paging.getPagingStr()}${this.embeddedStr + idStr + searchCondition}&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByBusinessIdByPaging(searchCondition: string, businessId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }

        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc` + (businessId ? `&f_businessId=${businessId}&f_businessId_op==` : '')).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByWalletIdsByPaging(walletIds: string[], searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,asc';
        let walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(id => {
                walletIdsStr += `&f_acceptedMerchants=${id}`;
            })
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${walletIdsStr}${sortStr}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res))
        );
    }

    publishCouponById(id: number, name: string, promoId: number) {
        return this.http.post(`${this.url}/${id}/${promoId}/dispatch?`, name).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    disablePreCoupon(id: number | string, value: any) {
        return this.http.put(`${this.url}/disable/${id}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateStatus(id: string, status: number) {
        return this.http.post(`${this.url}/${id}/${status}/update-status`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    addOld(value: any) {
        return this.http.post(`${this.url}/old`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    copyPreCoupon(id: number) {
        return this.http.post(`${this.url}/${id}/copy-precoupon`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

}
