import {Role} from "../admin/role.model";
import {Branch} from "./branch.model";

export class AdminUser {
    activate: boolean | number;
    code: string;
    disabled: boolean | number;
    locked: boolean | number;
    loginCount: number;
    password: string;
    phoneNum: string;
    preferredLanguage: string;
    rolesId: number;
    salt: string;
    secretKey: string;
    isSelected: boolean;

    id: number;
    email: string;
    name: string;
    userType: string;
    username: string;
    verify: boolean;
    version: number;
    role: Role;
    branch: Branch;


}