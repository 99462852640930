import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../services/base.service';
var TaskTypeService = /** @class */ (function (_super) {
    tslib_1.__extends(TaskTypeService, _super);
    function TaskTypeService(http) {
        var _this = _super.call(this, 'task-type', http) || this;
        _this.http = http;
        return _this;
    }
    TaskTypeService.prototype.ngOnInit = function () {
    };
    return TaskTypeService;
}(BaseService));
export { TaskTypeService };
