
import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Constants} from '../constants/app.constant';
import {Beneficiary} from '../models/beneficiary.model';
import {MyHttp} from './http.client.service';
import {PayerSubmit} from '../models/payer.submit.model';
import {Role} from "../models/admin/role.model";
import {NzdPayment} from "../models/nzd-payment.model";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class NzdPaymentService {
    public endpoint = `${Constants.API_ENDPOINT}v1/nzd-payment`;
    constructor(private http: HttpClient) {
    }


    page(pageNumber: number, pageSize: number) {
        return this.http.get(`${this.endpoint}?page=${pageNumber}&size=${pageSize}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    get(id: number) {
        return this.http.get(`${this.endpoint}/${id}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    post(nzdPayment: NzdPayment) {
        return this.http.post(`${this.endpoint}`,nzdPayment).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }
}
