/* tslint:disable:member-ordering */
import {Directive, ElementRef, HostListener, Input, OnInit, Renderer} from '@angular/core';
import {PermissionService} from '../../../services/permission.service';

@Directive({
    selector: '[permission]'
})
export class PermissionDirective implements OnInit {
    constructor(public el: ElementRef, public renderer: Renderer, public permissionService: PermissionService) {
        // Use renderer to render the element with styles
        // renderer.setElementStyle(el.nativeElement, 'display', 'none');
    }

    @Input('permission') permission: string;

    ngOnInit(): void {
        if (this.permissionService.getAuthKeysFromLocal() && this.permission && this.permissionService.getAuthKeysFromLocal().indexOf(this.permission.toString()) < 0) {
            this.renderer.setElementStyle(this.el.nativeElement, 'display', 'none');
        }
    }
}
