import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Paging} from '../../models/latipay/paging.model';

@Injectable()
export class TicketAgentService extends BaseService implements OnInit {


    constructor(public http: HttpClient) {
        super('ticket-agent', http);
        // this.embeddedStr = '&embedded=currency,organisation,organisation.primary-currency';
    }

    ngOnInit(): void {
    }


    //  /v1/wallet?f_organisation.id=18&f_organisation.id_op==
    getAllByPagingByOrgId(orgId: number, searchCondition: string, paging: Paging) {
        const sortStr = '&sort=createDate,desc';
        const embededStr = '&embedded=organisation';
        const str = '&f_organisation.id=' + orgId + '&f_organisation.id_op==';
        return this.http.get(`${this.baseUrl}wallet?${paging.getPagingStr()}${this.embeddedStr + str + sortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllVoucherByPaging(searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,desc';

        // const search = '&f_accountName=钱多多';

        return this.http.get(`${this.baseUrl}voucher?${paging.getPagingStr()}${searchCondition + sortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    saveWallet(payeaseType: string, isOnline: boolean, bankAccountId: number, merchantUserId: string | number, value: any) {
        return this.http.post(`${this.url}?merchantUserId=${merchantUserId}&payeaseType=${payeaseType}&isOnline=${isOnline}&bankAccountId=${bankAccountId}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateWallet(payeaseType: string, isOnline: boolean, bankAccountId: number, id: number, value: any) {
        return this.http.put(`${this.url}/${id}?payeaseType=${payeaseType}&isOnline=${isOnline}&bankAccountId=${bankAccountId}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    adjustWalletBalance(id: string, value: any) {
        return this.http.put(`${this.url}/adjust/${id}`, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllWalletByOpenIds(openIds: string[], paging: Paging) {
        let openIdsStr = '';
        if (openIds) {
            openIds.forEach(openId => {
                openIdsStr += `&openid=${openId}`
            })
        }
        return this.http.get(`${this.url}/openids?${paging.getPagingStr()}${openIdsStr}`)
    }

    getPrefixAndMax(id: string) {
        const str = `${id}`;
        return this.http.get(`${this.url}/get-prefix/${str}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }
}
