import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paging } from '../../models/latipay/paging.model';
import { Headers, Http, RequestOptions } from '@angular/http';
var BusinessService = /** @class */ (function (_super) {
    tslib_1.__extends(BusinessService, _super);
    function BusinessService(http, httpP) {
        var _this = _super.call(this, 'business', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        return _this;
    }
    BusinessService.prototype.ngOnInit = function () {
    };
    BusinessService.prototype.getAllBusiness = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=id,desc&';
        this.embeddedStr = '&embedded=images';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (this.embeddedStr + searchStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAllNormalBusiness = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=id,desc&';
        this.embeddedStr = '&embedded=images';
        return this.http.get(this.url + "/normal-get?" + tempPaging.getPagingStr() + (this.embeddedStr + searchStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAllTopicBusiness = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=id,desc&';
        this.embeddedStr = '&embedded=images';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr()).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAllMessagessBusiness = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=id,desc&';
        var str = '&f_enabled_op==&f_enabled=1';
        return this.http.get(this.url + "/normal-get?" + tempPaging.getPagingStr() + (str + searchStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAllTypeBusiness = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=id,desc&';
        var str = '&f_type_op==&f_type=1&f_enabled_op==&f_enabled=1';
        return this.http.get(this.url + "/normal-get?" + tempPaging.getPagingStr() + (str + searchStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAllNoBusiness = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=id,desc&';
        return this.http.get(this.url + "/normal-get?" + tempPaging.getPagingStr() + searchStr).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getSelectbusiness = function (searchCondition, activityId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var embededStrr = '&embedded=area';
        return this.http.get(this.url + "/all-select-business/" + activityId + "/?" + paging.getPagingStr() + searchCondition + "&sort=id,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getSelectOther = function (searchCondition, activityId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var embededStrr = '&embedded=area';
        return this.http.get(this.url + "/all-select-latipay-merchant/" + activityId + "/?" + paging.getPagingStr() + searchCondition + "&sort=id,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAllBusinessIDByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        var embededStrr = '&embedded=category-id,images,area';
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (searchCondition + embededStrr) + "&sort=categoryId.queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAllMerchantByPaging = function (searchCondition, paging) {
        this.embeddedStr = '&embedded=prepaid-wallet';
        if (!searchCondition) {
            searchCondition = '';
        }
        var str = '&f_prepaidWallet.code=-';
        return this.http.get(this.url + "/normal-get?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + str) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAllMerchantHasByPaging = function (searchCondition, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "/prepaid-wallet?" + paging.getPagingStr() + searchCondition + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAllClawByPaging = function (searchCondition, paging) {
        this.embeddedStr = '&embedded=images';
        if (!searchCondition) {
            searchCondition = '';
        }
        var filterStr = '&f_type_op==&f_type=2';
        return this.http.get(this.url + "/all-business/?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + filterStr) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAllByPaging = function (searchCondition, paging) {
        // this.embeddedStr = '&embedded=images,wallet';
        var enbed = '&embedded=images,category-id,area';
        if (!searchCondition) {
            searchCondition = '';
        }
        var filterStr = '&f_type_op==&f_type=1';
        return this.http.get(this.url + "?" + paging.getPagingStr() + (enbed + searchCondition) + "&sort=categoryId.queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAllTicketsByPaging = function (searchCondition, paging) {
        this.embeddedStr = '&embedded=images';
        if (!searchCondition) {
            searchCondition = '';
        }
        var ticketTag = '&f_tags_op==&f_tags=Ticket';
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + ticketTag) + "&sort=id,desc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.get = function (id) {
        this.embeddedStr = 'embedded=images,area';
        return this.http.get(this.url + "/" + id + "?" + this.embeddedStr).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.updateBusiness = function (token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/tools/import/search-business/?modules=business';
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.updateBusinessCategory = function (token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/tools/import/search-business/?modules=business_category';
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.updateKeyWords = function (token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/tools/import/search-business/?modules=promo,coupon,pre_coupon';
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.updateKeyWord = function (token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/tools/import/search-business/?modules=keywords';
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.updateCoupon = function (token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/tools/import/search-business/?modules=coupon';
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.updatePrecoupon = function (token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/tools/import/search-business/?modules=pre_coupon';
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAddressCo = function (address) {
        // const header: Headers = new Headers();
        // header.append('Content-Type', 'application/json');
        // const options = new RequestOptions({headers: header});
        var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=AIzaSyA4YtZga1UtkMDVAtljhNVPOiSxg8Z0UEs';
        return this.httpP.get("" + url).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.getAllBusinessByPagingFirst = function (searchCondition, sortOprions, paging, pageNum) {
        if (!searchCondition) {
            searchCondition = '';
        }
        if (pageNum) {
            paging.pageNumber = pageNum;
        }
        var sortStr = sortOprions.getSortString();
        var embededStrr = '&embedded=category-id,images,area';
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (searchCondition + embededStrr) + "&sort=categoryId.queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    BusinessService.prototype.updateShareLink = function (id) {
        return this.http.post(this.url + "/" + id + "/update-share-link", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return BusinessService;
}(BaseService));
export { BusinessService };
