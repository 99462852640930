import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paging } from '../../models/latipay/paging.model';
var AppAdvertisementService = /** @class */ (function (_super) {
    tslib_1.__extends(AppAdvertisementService, _super);
    function AppAdvertisementService(http) {
        var _this = _super.call(this, 'app-advertisement', http) || this;
        _this.http = http;
        return _this;
    }
    AppAdvertisementService.prototype.ngOnInit = function () {
    };
    AppAdvertisementService.prototype.getAllAdv = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=queue,desc&';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (this.embeddedStr + searchStr) + "&sort=queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvertisementService.prototype.getAllByPaging = function (searchCondition, paging) {
        this.embeddedStr = '&embedded=category-id';
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=categoryId.queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvertisementService.prototype.getAllSelectEnabledByPaging = function (searchCondition, paging) {
        this.embeddedStr = '&embedded=category-id';
        if (!searchCondition) {
            searchCondition = '';
        }
        var strr = '&f_active_op==&f_active=1';
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition + strr) + "&sort=categoryId.queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvertisementService.prototype.generateNewLink = function () {
        return this.http.post(this.url + "/new-link", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvertisementService.prototype.get = function (id) {
        var embeddedStr = '&embedded=category-id';
        return this.http.get(this.url + "/" + id + "?" + embeddedStr).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return AppAdvertisementService;
}(BaseService));
export { AppAdvertisementService };
