import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Constants} from '../constants/app.constant';
import {Beneficiary} from '../models/beneficiary.model';
import {MyHttp} from './http.client.service';
import {PayerSubmit} from '../models/payer.submit.model';
import {Role} from '../models/admin/role.model';
import {Http} from '@angular/http';
import {HttpClient} from '@angular/common/http';
import {AdminUser} from '../models/latipay/admin-user.model';


@Injectable()
export class UserService {
    public token: string;
    public adminUserUrl = Constants.API_ENDPOINT + 'v1/admin-user';
    public ruleUrl = Constants.API_ENDPOINT + 'v1/rule';
    public rulePermissionUrl = Constants.API_ENDPOINT + 'v1/role-permission';
    public priceCategoryUrl = Constants.API_ENDPOINT + 'v1/price-category';
    public ibUrl = Constants.API_ENDPOINT + 'v1/user?f_role.name=ib';
    public userTypeUrl = Constants.API_ENDPOINT + 'v1/user/types';
    public userProfileUrl = Constants.API_ENDPOINT + 'v1/user/profile';

    // latipay
    public userUrl = Constants.API_ENDPOINT + 'v1/admin-user';

    constructor(private http: HttpClient) {
    }

    /**
     *   -----------  latipay ---------------
     * */

    clearCache() {
        return this.http.post(`${this.adminUserUrl}/cache-clear`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    getAdminUser(id: number) {
        const embedded = '?embedded=role,branch';
        return this.http.get(this.adminUserUrl + '/' + id + embedded).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAdminUsersByPagingForShared(searchCondition: string, pageNumber: number, pageSize: number) {
        const embedded = '&embedded=role,branch';
        return this.http.get(`${Constants.API_ENDPOINT}v1/admin-user?page=${pageNumber}&size=${pageSize}${searchCondition + embedded}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getUsersByPagingForShared(searchCondition: string, pageNumber: number, pageSize: number) {
        return this.http.get(`${Constants.API_ENDPOINT}v1/user?embedded=role,introduced-by&page=${pageNumber}&size=${pageSize}${searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllAdminUsers() {
        const embedded = '&embedded=role,branch';
        return this.http.get(`${Constants.API_ENDPOINT}v1/admin-user?page=0&size=100000${embedded}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getAllVerifiedAdminUsers() {
        const verifiedOnly = '&f_verify=true&f_verify_op==';
        const embedded = '&embedded=role,branch';
        return this.http.get(`${Constants.API_ENDPOINT}v1/admin-user?page=0&size=100000${embedded}${verifiedOnly}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    /**
     *   -----------  latipay ---------------
     * */

    // latipay
    getAll() {
        return this.http.get(`${this.userUrl}?embedded=role`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    // old
    getUserTypes() {
        return this.http.get(this.userTypeUrl).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getIbs() {
        return this.http.get(this.ibUrl).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getPriceCategories() {
        return this.http.get(this.priceCategoryUrl).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getCustomers() {
        return this.http.get(this.adminUserUrl).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    addUser(value: AdminUser) {
        return this.http.post(this.adminUserUrl, value).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    updateUser(userId: number, user: AdminUser) {
        return this.http.put(this.adminUserUrl + '/' + userId, user).pipe(
            map((response) => response));
    }

    resetPassword(oldPassword: string, newPassword: string, confirmPassword: string) {
        return this.http.post(`${this.adminUserUrl}/password?oldPassword=${oldPassword}&newPassword=${newPassword}&confirmPassword=${confirmPassword}`, {}).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    resetPasswordByAdmin(user: AdminUser) {
        return this.http.put(this.adminUserUrl + '/' + user.id + ' ', {}).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getCustomersByPaging(searchCondition: string, pageNumber: number, pageSize: number) {
        const userTypeString = `&f_userType=${Constants.USER.broker}&f_userType=${Constants.USER.company}&f_userType=${Constants.USER.individual}`;
        return this.http.get(`${Constants.API_ENDPOINT}v1/user?embedded=role,introduced-by&page=${pageNumber}&size=${pageSize}${searchCondition + userTypeString}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getUsersByPaging(searchCondition: string, pageNumber: number, pageSize: number) {
        const userTypeString = '&f_userType=' + Constants.USER.backend;
        return this.http.get(`${Constants.API_ENDPOINT}v1/user?embedded=role,introduced-by&page=${pageNumber}&size=${pageSize}${searchCondition + userTypeString}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    updateRole(id: number, role: Role) {
        return this.http.put(this.adminUserUrl + '/' + id, role).pipe(
            map((response) => response));
    }

    getRules() {
        return this.http.get(this.adminUserUrl).pipe(
            map((response) => response));
    }

    getRolePermissionByRoleId(id: number) {
        return this.http.get(this.rulePermissionUrl + '/' + id).pipe(
            map((response) => response));
    }

    getCustomer(id: number) {
        const embedded = '?embedded=price-category,role,introduced-by,attachments';
        return this.http.get(this.adminUserUrl + '/' + id + embedded).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    verify(ids: string) {
        return this.http.put(`${this.adminUserUrl}/verify?ids=${ids}`, null).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getUserProfile() {
        const embedded = '?embedded=role,branch';
        return this.http.get(this.userProfileUrl + embedded).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    unVerify(ids: any) {
        return this.http.put(`${this.adminUserUrl}/unverify?ids=${ids}`, null).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

}
