import {Payee} from "./payee.model";
import {User} from "./user.model";
import {Attachment} from "./attachment.model";

export class Payer {
    address: string;
    city: string;
    country: string;
    createdAt: number;
    id: number;
    name: string;
    updatedAt: number;
    verify: boolean;
    attachments: Attachment[];
    creator: User;
    payees: Payee[];
    isSelected: boolean;
}
