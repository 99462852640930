import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Paging} from '../../models/latipay/paging.model';
import {Headers, Http, RequestOptions} from '@angular/http';

@Injectable()
export class WalletBankAccountsService extends BaseService implements OnInit {


    constructor(public http: HttpClient,
                public httpP: Http) {
        super('wallet-bank-accounts', http);
    }

    ngOnInit(): void {
    }

    getAllByPagingByWalletId(walletId: string, searchCondition: string, paging: Paging) {
        const sortStr = '&sort=id,desc';
        const embededStr = '&embedded=bank-account';
        if (!searchCondition) {
            searchCondition = '';
        }
        const str = '&f_walletId=' + walletId;
        return this.http.get(`${this.url}?${paging.getPagingStr()}${ str + sortStr + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    addByList(code: string, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/add-by-list/${code}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    delete(id: number) {
        return this.http.post(`${this.url}/${id}/delete`, {}).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    setDefault(id: number) {
        return this.http.post(`${this.url}/${id}/set-default`, {}).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }
    getAllExisting(id: number, code: string, paging: Paging) {
        return this.http.post(`${this.url}/${id}/${code}/except-existing?${paging.getPagingStr()}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }





}
