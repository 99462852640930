import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';
import {Sorts} from '../../models/admin/sorts.model';
import {Headers, Http, RequestOptions, Response} from '@angular/http'
import {PromoKey} from '../../models/latipay/promo-key.model';
import {PromoKeyWords} from '../../models/latipay/promo-key-words.model';
import {PricingPlan} from '../../models/latipay/pricing-plan.model';
import {BusinessKey} from '../../models/latipay/business-key.model';
import {Constants} from '../../constants/app.constant';
import {LoginPResp} from '../../models/admin/loginP.resp.model';
import {HttpErrorHaddle} from '../../theme/shared/http-error-handle/http.error.haddle';


@Injectable()
export class OrgOnboardService extends BaseService implements OnInit {

    constructor(public http: HttpClient,
                public httpP: Http) {
        super('onboard/full-version', http);
    }

    ngOnInit(): void {
    }

    getAllOnboarding(searchCondtion: string, token: string, paging: Paging, userId: number) {
        const userIdStr = '&adminUserId=' + userId;
        return this.http.get(`${this.url}/get-all/?${paging.getPagingStr()}${searchCondtion + userIdStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    get(id) {
        return this.http.get(`${this.url}/get-all/${id}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    complete(id) {
        return this.http.get(`${this.url}/complete/${id}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    reject(id) {
        return this.http.get(`${this.url}/reject/${id}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    checkEmail(email) {
        return this.http.post(`${this.url}/check-email/${email}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    searchCompanyByName(name: string) {
        return this.http.post(`${this.url}/search-company-by-name/${name}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }

    getCompanyByNZBN(name: string) {
        return this.http.post(`${this.url}/get-company-by-nzbn/${name}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }


    updatePerson(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/update-person/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    addPerson(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/add-person/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    deletePerson(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/delete-person/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    updateAccount(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/update-account/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateStore(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/update-store/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    addAccount(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/add-account/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


    updateOnboardStatus(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/update-onboard-status/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateOnboardContactInfo(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/update-contact-info/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    updateOnboardCompanyInfo(id: number, value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/update-company-info/${id}`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    checkCompanyInfo(value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/check-company-number`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    manualSignUp(value: any, token: string) {
        const header: Headers = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        const options = new RequestOptions({headers: header});
        return this.httpP.post(`${this.url}/sign-up`, value, options).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }


}
