import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
var WechatUserService = /** @class */ (function (_super) {
    tslib_1.__extends(WechatUserService, _super);
    function WechatUserService(http) {
        var _this = _super.call(this, 'wechat-user', http) || this;
        _this.http = http;
        _this.embeddedStr = '&embedded=wechat-user';
        return _this;
    }
    WechatUserService.prototype.ngOnInit = function () {
    };
    WechatUserService.prototype.assignWallet = function (walletIds, wechatUserOpenid) {
        return this.http.post(this.url + "/assignWallet?walletIds=" + walletIds + "&wechatUserOpenid=" + wechatUserOpenid, null).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatUserService.prototype.unassignWallet = function (walletId, wechatUserOpenid) {
        return this.http.post(this.url + "/unassignWallet?walletId=" + walletId + "&wechatUserOpenid=" + wechatUserOpenid, null).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    WechatUserService.prototype.getAllByPaging = function (searchCondition, paging) {
        var sortStr = '&sort=subscribeTime,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + sortStr + (this.embeddedStr + searchCondition)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return WechatUserService;
}(BaseService));
export { WechatUserService };
