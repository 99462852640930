import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
var CustomerVerificationService = /** @class */ (function (_super) {
    tslib_1.__extends(CustomerVerificationService, _super);
    function CustomerVerificationService(http) {
        var _this = _super.call(this, 'customer-verification', http) || this;
        _this.http = http;
        return _this;
    }
    CustomerVerificationService.prototype.ngOnInit = function () {
    };
    CustomerVerificationService.prototype.getCustomerVerificationByUserId = function (userId) {
        return this.http.get(this.baseUrl + "customer-verification?f_userId=" + userId + "&f_userId_op==").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    CustomerVerificationService.prototype.updateCustomerVerification = function (action, data) {
        return this.http.post(this.baseUrl + "customer-verification?action=" + action, data).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return CustomerVerificationService;
}(BaseService));
export { CustomerVerificationService };
