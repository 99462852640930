import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paging } from '../../models/latipay/paging.model';
import { Headers, Http, RequestOptions } from '@angular/http';
var PromoItemService = /** @class */ (function (_super) {
    tslib_1.__extends(PromoItemService, _super);
    function PromoItemService(http, httpP) {
        var _this = _super.call(this, 'promo-item', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        return _this;
    }
    PromoItemService.prototype.ngOnInit = function () {
    };
    PromoItemService.prototype.getAllNormalPromoItemByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        return this.http.get(this.url + "/get-promos/" + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllPromoItemByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.addPromoKeyword = function (token, promoKeyBody) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-promo-item/';
        return this.httpP.post("" + url, promoKeyBody, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.patchKeyword = function (token, promoKeyBody, id) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-keyword/' + id + '/';
        return this.httpP.patch("" + url, promoKeyBody, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getSinglePromoKeyword = function (token, id) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-promo-item/?promo_id=' + id;
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getSingleBusinessKeyword = function (token, id) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-business-item/?business_id=' + id;
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.patchPromoKeyword = function (token, promoKeyBody, id) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-promo-item/' + id + '/';
        return this.httpP.patch("" + url, promoKeyBody, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.patchBusinessKeyword = function (token, promoKeyBody, id) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-business-item/' + id + '/';
        return this.httpP.patch("" + url, promoKeyBody, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getPromoByKeyword = function (token, searchP, paging) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-promo-item/?search=' + searchP + '&embed=item_detail' + '&ordering=id&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString();
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getBusinessByKeyword = function (token, searchP, paging) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-business-item/?search=' + searchP + '&embed=item_detail' + '&ordering=id&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString();
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllPromoByKeyword = function (token, paging) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-promo-item/' + '?embed=item_detail' + '&ordering=id&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString();
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllBusinessByKeyword = function (token, paging) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-business-item/' + '?embed=item_detail' + '&ordering=id&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString();
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.insertAllPromoKeywords = function (token, searchP) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        // if (!searchP) {
        //     searchP = '';
        // } else {
        //     searchP = '?promo_id=' + searchP;
        // }
        var url = this.baseParaUrl + 'api/search-promo-item/?promo_id__in=' + searchP + '&ordering=-promo_id';
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.insertAllBusinessKeywords = function (token, searchP) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        // if (!searchP) {
        //     searchP = '';
        // } else {
        //     searchP = '?promo_id=' + searchP;
        // }
        var url = this.baseParaUrl + 'api/search-business-item/?business_id__in=' + searchP + '&ordering=-business_id';
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllPromoKeyword = function (token, searchP) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-promo-item/?promo_id=' + searchP;
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllBusinessKeyword = function (token, searchP) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-business-item/?business_id=' + searchP;
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllPromoKeywordsWithoutPaging = function (token, searchP) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-keyword/?search=' + searchP;
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllPromoKeywords = function (token, searchP, kPaging) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-keyword/?ordering_statistically=searchIn30&limit=50&offset=' + (+(+kPaging.getPagingNumber() - 1) * 50).toString();
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllBusinessKeywords = function (token, searchP, kPaging) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-keyword/?ordering_statistically=searchIn30&limit=50&offset=' + (+(+kPaging.getPagingNumber() - 1) * 50).toString();
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getPromoKeywordsByName = function (token, searchP, paging) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-keyword/?search=' + searchP + '&ordering_statistically=searchIn30&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString();
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.addKeyWords = function (token, newKeyword) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', 'Bearer ' + token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-keyword/';
        return this.httpP.post("" + url, newKeyword, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.uploadImage = function (token, file) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', 'Bearer ' + token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseUrl + 'admin-upload';
        return this.httpP.post("" + url, file, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    // getPromoKeys(id: number) {
    //
    //     const keyUrl = 'https://parasite-staging.latipay.net/api/search-promo-item/?promo_id=';
    //     const str = this.embeddedStr ? `${id}?${this.embeddedStr}` : id;
    //     return this.http.get(`${keyUrl}${str}`).pipe(
    //         map((response) => response), catchError((res: any) => observableThrowError(res)));
    // }
    PromoItemService.prototype.getAllPromoItemIDByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        var embededStrr = '&embedded=category-id';
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (searchCondition + embededStrr) + "&sort=categoryId.queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllPromoItemIDByPagingFirst = function (searchCondition, sortOprions, paging, pageNum) {
        if (!searchCondition) {
            searchCondition = '';
        }
        if (pageNum) {
            paging.pageNumber = pageNum;
        }
        var sortStr = sortOprions.getSortString();
        var embededStrr = '&embedded=category-id';
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (searchCondition + embededStrr) + "&sort=categoryId.queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.publishCouponById = function (id) {
        return this.http.post(this.url + "/" + id + "/dispatch", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.applyPromo = function (id) {
        return this.http.post(this.url + "/" + id + "/apply", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.closePromo = function (id) {
        return this.http.post(this.url + "/" + id + "/close", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllHotKeywords = function (token, searchP) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-keyword/?hot=' + searchP;
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllNewKeywords = function (token, searchP) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-keyword/?new=' + searchP;
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllPromoHotKeywords = function (token, kPaging) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-keyword/?ordering_statistically=searchIn30&hot=true&limit=50&offset=' + (+(+kPaging.getPagingNumber() - 1) * 50).toString();
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllPromoNewKeywords = function (token, kPaging) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-keyword/?ordering_statistically=searchIn30&new=true&limit=50&offset=' + (+(+kPaging.getPagingNumber() - 1) * 50).toString();
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllCouponTransactionWithoutPaging = function (token, searchP) {
        var header = new Headers();
        token = 'Bearer ' + token;
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'para/api/coupon-statistics/?create_date=' + searchP;
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllCouponTransaction = function (token, searchP, paging) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/search-keyword/?ordering=id&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString();
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.sendSMSToUsers = function (token, smsBody) {
        token = 'Bearer ' + token;
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/sms/';
        return this.httpP.post("" + url, smsBody, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.addPricingPlan = function (token, pricingPlanBody) {
        var header = new Headers();
        token = 'Bearer ' + token;
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'para/api/pricing-plan/';
        return this.httpP.post("" + url, pricingPlanBody, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.updatePricingPlan = function (token, pricingPlanBody, id) {
        var header = new Headers();
        token = 'Bearer ' + token;
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'para/api/pricing-plan/' + id + '/';
        return this.httpP.patch("" + url, pricingPlanBody, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllPromoItem = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = '&sort=queue,asc';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (this.embeddedStr + searchStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getTotalPromoExpiry = function () {
        return this.http.get(this.url + "/get-total-days").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllFilterUser = function (condtions, token, paging) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/app-user/' + '?ordering=-create_date&limit=50&offset=' + (+(+paging.getPagingNumber() - 1) * 50).toString() + '&conditions=' + condtions;
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    PromoItemService.prototype.getAllFilterUserWithoutPaging = function (condtions, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/app-user/' + '?ordering=-create_date' + '&conditions=' + condtions;
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return PromoItemService;
}(BaseService));
export { PromoItemService };
