
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Paging} from "../../models/latipay/paging.model";

@Injectable()
export class AutoServiceConfigService extends BaseService implements OnInit {


    constructor(public http: HttpClient) {
        super('auto-service-config', http);
    }

    ngOnInit(): void {
    }

    getAutoConfig(id) {
        return this.http.get(`${this.url}/${id}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    getAllByPagingByOrgId(orgId: number, searchCondition: string, paging: Paging) {
        const sortStr = '&sort=createDate,desc';
        let customStr = '';
        if (orgId > 0) {
            customStr = '&f_orgId=' + orgId + '&f_orgId_op==';
        }
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition + customStr + sortStr}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }


    removeWithdrawal(item: number) {
        return this.http.post(`${this.url}/${item}/delete-config`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    // updateBank(id: number, bank: Bank) {
    //     return this.http.put(this.url + '/' + id, bank)
    //         .map((response) => response);
    // }
    //
    // addBank(bank: Bank) {
    //     return this.http.post(this.url, bank)
    //         .map((response) => response);
    // }

}
