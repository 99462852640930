import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../../../services/base.service';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs';
import {Paging} from '../../../models/latipay/paging.model';

@Injectable()
export class PricingPlanDefaultService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('pricing-plan-default', http);
    }

    ngOnInit(): void {
    }


    getAllExisting(id: number, searchCondition: string, code: string, paging: Paging) {
        return this.http.post(`${this.url}/${id}/${code}/except-existing?${paging.getPagingStr()}${searchCondition}`, '').pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
