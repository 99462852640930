import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Currency} from '../../models/latipay/currency.model';
import {Store} from '@ngrx/store';
import {SystemOptionsFeatureStateInterface} from '../../pages/system-options/store/interfaces/feature-state.interface';

@Injectable()
export class CurrencyService extends BaseService implements OnInit {
    private currencies: any | Currency[];


    constructor(public http: HttpClient,
                private store: Store<SystemOptionsFeatureStateInterface>) {
        super('currency', http);
    }

    ngOnInit(): void {

    }

    getCurrenciesFromServer() {
        this.getAll().subscribe((resp: any) => {
            console.log(resp);
            this.currencies = resp;
        });
    }

    getCurrenciesFromLocal() {
        return this.currencies;
    }

    // fetchCurrencies() {
    //     this.store.dispatch(new SystemOptionActions.FetchCurrencies({}));
    // }
    //
    getCurrenciesFromStore() {
        return this.store.select(o => o.systemOptions);
    }
}
