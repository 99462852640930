
import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Constants} from '../constants/app.constant';
import {PricePolicy} from "../models/admin/price-policy.model";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class PricePolicyService {
    public endpoint = Constants.API_ENDPOINT + 'v1/price-policy';

    constructor(private http: HttpClient) {
    }


    page(pageNumber: number, pageSize: number) {
        return this.http.get(`${this.endpoint}?embedded=price-category&page=${pageNumber}&size=${pageSize}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    get(id: number) {
        const embedded = '?embedded=price-category';
        return this.http.get(`${this.endpoint}/${id + embedded}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    add(pp: PricePolicy) {
        return this.http.post(`${this.endpoint}`, pp).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

}
