import {Injectable, OnInit} from '@angular/core';
import {Payee} from '../models/payee.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {Branch} from '../../models/latipay/branch.model';
import {Paging} from '../../models/latipay/paging.model';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs';

@Injectable()
export class BranchService extends BaseService implements OnInit {


    constructor(public http: HttpClient) {
        super('branch', http);
    }

    ngOnInit(): void {
    }

    // update(id: number, branch: any) {
    //     return this.http.put(this.url + '/' + id, branch)
    //         .map((response) => response);
    // }

    // add(branch: Branch) {
    //     return this.http.post(this.url, branch)
    //         .map((response) => response);
    // }

    updateBranch(id: number, value: Branch) {

    }

    getAllBranchByPaging(searchCondition: string, paging: Paging) {
        let idSortStr = '&sort=id,desc';
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition + idSortStr}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)),);
    }
}
