import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paging } from '../../models/latipay/paging.model';
import { Headers, Http, RequestOptions } from '@angular/http';
var AppAdvCategoryService = /** @class */ (function (_super) {
    tslib_1.__extends(AppAdvCategoryService, _super);
    function AppAdvCategoryService(http, httpP) {
        var _this = _super.call(this, 'app-adv-category', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        return _this;
    }
    AppAdvCategoryService.prototype.ngOnInit = function () {
    };
    AppAdvCategoryService.prototype.getAllCategoryLink = function (promoId, searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = '&sort=queue,asc';
        var embededStrr = '&embedded=adv';
        var str = '&f_adv.id=' + promoId + '&f_adv.id_op==';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (embededStrr + str + idSortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvCategoryService.prototype.getAllCategoryLinkSwap = function (categoryId, searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = '&sort=queue,asc';
        var embededStrr = '&embedded=adv';
        var str = '&f_categoryId=' + categoryId + '&f_categoryId_op==';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (embededStrr + str + idSortStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvCategoryService.prototype.getAllTopicByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvCategoryService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvCategoryService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvCategoryService.prototype.publishCouponById = function (id) {
        return this.http.post(this.url + "/" + id + "/dispatch", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvCategoryService.prototype.swapCategoryQueue = function (origin, target) {
        return this.http.post(this.url + "/" + origin + "/" + target + "/swap", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvCategoryService.prototype.goCategoryQueue = function (value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/go-sort", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvCategoryService.prototype.setTop = function (origin, target) {
        return this.http.post(this.url + "/" + origin + "/" + target + "/set-top", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvCategoryService.prototype.copyLink = function (original, promoId) {
        return this.http.post(this.url + "/" + original + "/" + promoId + "/copy-link", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    AppAdvCategoryService.prototype.updateLink = function (businessId, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/" + businessId + "/update-links", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return AppAdvCategoryService;
}(BaseService));
export { AppAdvCategoryService };
