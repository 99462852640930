import {PermissionConstant} from '../models/admin/permission-constant.model';

export class PermissionConstants {
    public static constants: PermissionConstant = {
        INDEX_BRANCH: 'Index branch',
        CREATE_BRANCH: 'Create branch',
        READ_BRANCH: 'Read branch',
        UPDATE_BRANCH: 'Update branch',
        DELETE_BRANCH: 'Delete branch',
        INDEX_PERMISSION: 'Index permission',
        CREATE_PERMISSION: 'Create permission',
        READ_PERMISSION: 'Read permission',
        UPDATE_PERMISSION: 'Update permission',
        DELETE_PERMISSION: 'Delete permission',
        INDEX_ROLE: 'Index role',
        CREATE_ROLE: 'Create role',
        READ_ROLE: 'Read role',
        UPDATE_ROLE: 'Update role',
        DELETE_ROLE: 'Delete role',
        INDEX_ROLE_PERMISSION: 'Index role-permission',
        CREATE_ROLE_PERMISSION: 'Create role-permission',
        READ_ROLE_PERMISSION: 'Read role-permission',
        UPDATE_ROLE_PERMISSION: 'Update role-permission',
        DELETE_ROLE_PERMISSION: 'Delete role-permission',
        INDEX_RULE: 'Index rule',
        CREATE_RULE: 'Create rule',
        READ_RULE: 'Read rule',
        UPDATE_RULE: 'Update rule',
        DELETE_RULE: 'Delete rule',
        INDEX_TRANSACTION_ORDER: 'Index transaction-order',
        CREATE_TRANSACTION_ORDER: 'Create transaction-order',
        READ_TRANSACTION_ORDER: 'Read transaction-order',
        UPDATE_TRANSACTION_ORDER: 'Update transaction-order',
        DELETE_TRANSACTION_ORDER: 'Delete transaction-order',
        INDEX_ADMIN_USER: 'Index admin-user',
        CREATE_ADMIN_USER: 'Create admin-user',
        READ_ADMIN_USER: 'Read admin-user',
        UPDATE_ADMIN_USER: 'Update admin-user',
        DELETE_ADMIN_USER: 'Delete admin-user',
        INDEX_ORGANISATION: 'Index organisation',
        CREATE_ORGANISATION: 'Create organisation',
        READ_ORGANISATION: 'Read organisation',
        UPDATE_ORGANISATION: 'Update organisation',
        DELETE_ORGANISATION: 'Delete organisation',
        INDEX_USER: 'Index user',
        READ_USER: 'Read user',
        INDEX_TRANSACTION_ORDER_EXPORT: 'Index transaction-order-export',
        INDEX_PAYMENT_GATEWAY: 'Index payment gateway',
        INDEX_COUNTRY: 'Index country',
        INDEX_CURRENCY: 'Index currency',
        INDEX_MERCHANT_USER: 'Index merchant user',
        INDEX_ORG_WALLET: 'Index org wallet',
        INDEX_ORG_USER: 'Index org user',
        INDEX_PRICING_PLAN_BY_MERCHANT_ID: 'Index pricing plan by merchant id',
        INDEX_PRICING_PLAN: 'Index pricing plan',
        INDEX_TASK: 'Index task',
        INDEX_SETTLE_RECORD: 'Index settle record',
        INDEX_BANK: 'Index bank',
        INDEX_SETTLE_ORDER: 'index settle order',
        PUT_SETTLE_ORDER: 'put settle order',
        DELETE_AGENT: 'Delete agent',
        UPDATE_AGENT: 'Update agent',
        READ_AGENT: 'Read agent',
        CREATE_AGENT: 'Create agent',
        INDEX_AGENT: 'Index agent',
        CREATE_WALLET: 'Create wallet',
        UPDATE_ORG_USER: 'update org user',
        PATCH_ORG_USER: 'patch org user',
        UPDATE_WALLET: 'Update wallet',
        DELETE_EMAIL_LOG: 'Delete email-log',
        UPDATE_EMAIL_LOG: 'Update email-log',
        READ_EMAIL_LOG: 'Read email-log',
        INDEX_EMAIL_LOG: 'Index email-log',
        CREATE_EMAIL_LOG: 'Create email-log',
        READ_BANK: 'Read bank',
        INDEX_ORGANISATION_ASSESSMENT: 'Index organisation-assessment',
        READ_ORGANISATION_ASSESSMENT: 'Read organisation-assessment',
        READ_ASSESSMENT: 'Read assessment',
        INDEX_ASSESSMENT: 'Index assessment',
        INDEX_ASSESSMENT_QUESTION: 'Index assessment-question',
        READ_ASSESSMENT_QUESTION: 'Read assessment-question',
        READ_ASSESSMENT_QUESTION_OPTION: 'Read assessment-question-option',
        INDEX_ASSESSMENT_QUESTION_OPTION: 'Index assessment-question-option',
        READ_ORGANISATION_ASSESSMENT_ANSWER: 'Read organisation-assessment-answer',
        INDEX_ORGANISATION_ASSESSMENT_ANSWER: 'Index organisation-assessment-answer',
        READ_ORG_WALLET: 'Read org wallet',
        READ_ORGANISATION_EXPORT: 'Read organisation export',
        INDEX_WECHAT_USER: 'Index wechat-user',
        INDEX_PAY_COMPANY_INFO: 'Index pay-company-info',
        INDEX_AUTO_SERVICE_CONFIG: 'Index auto-service-config',
        READ_AUTO_SERVICE_CONFIG: 'Read auto-service-config',
        READ_CONFIGURATION: 'Read configuration',
        INDEX_CONFIGURATION: 'Index configuration',
        CREATE_CONFIGURATION: 'Create configuration',
        UPDATE_CONFIGURATION: 'Update configuration',
        DELETE_CONFIGURATION: 'Delete configuration',
        CREATE_AUTO_SERVICE_CONFIG: 'Create auto-service-config',
        UPDATE_AUTO_SERVICE_CONFIG: 'Update auto-service-config',
        DELETE_AUTO_SERVICE_CONFIG: 'Delete auto-service-config',
        DELETE_OPERATIONOPERATION_ORGANISATION: 'Delete operationoperation-organisation',
        UPDATE_OPERATION: 'Update operation',
        READ_OPERATION: 'Read operation',
        READ_SETTLE_ORDER: 'Read settle order',
        UPDATE_OPERATION_ORGANISATION: 'Update operation-organisation',
        READ_OPERATION_ORGANISATION: 'Read operation-organisation',
        INDEX_OPERATION_ORGANISATION: 'Index operation-organisation',
        INDEX_OPERATION: 'Index operation',
        DELETE_OPERATION: 'Delete operation',
        UPDATE_BANK_ACCOUNT: 'Update bank-account',
        INDEX_BANK_ACCOUNT: 'Index bank-account',
        READ_BANK_ACCOUNT: 'Read bank-account',
        DELETE_BANK_ACCOUNT: 'Delete bank-account',
        UPDATE_PRICING_PLAN_DEFAULT: 'Update pricing-plan-default',
        INDEX_PRICING_PLAN_DEFAULT: 'Index pricing-plan-default',
        READ_PRICING_PLAN_DEFAULT: 'Read pricing-plan-default',
        DELETE_PRICING_PLAN_DEFAULT: 'Delete pricing-plan-default',
        UPDATE_ONBOARD: 'Update onboard',
        READ_ONBOARD: 'Read onboard',
        INDEX_ONBOARD: 'Index onboard',
        DELETE_ONBOARD: 'Delete onboard',
        DELETE_EXPORT_FILE: 'Delete export-file',
        READ_EXPORT_FILE: 'Read export-file',
        UPDATE_EXPORT_FILE: 'Update export-file',
        INDEX_EXPORT_FILE: 'Index export-file',
        UPDATE_INVOICE: 'Update invoice',
        READ_INVOICE: 'Read invoice',
        INDEX_INVOICE: 'Index invoice',
        DELETE_INVOICE: 'Delete invoice',
        UPDATE_TASK_TYPE: 'Update task-type',
        READ_TASK_TYPE: 'Read task-type',
        DELETE_TASK_TYPE: 'Delete task-type',
        INDEX_TASK_TYPE: 'Index task-type',
        CREATE_MERCHANT_USER: 'Create merchant user',
        READ_ONBOARD_FULL_VERSION: 'Read onboard-full-version',
        INDEX_ONBOARD_FULL_VERSION: 'Index onboard-full-version',
        READ_MERCHANT_USER: 'Read merchant user',
        UPDATE_MERCHANT_USER: 'Update merchant user',
        PATCH_MERCHANT_USER: 'Patch merchant user',
        INDEX_USER_ACCOUNT: 'Index user account',
        READ_SETTLE_RECORD: 'Read settle record',
        INDEX_WALLET_PAYEASE_TYPE: 'Index wallet payease type',
        CREATE_BANK_ACCOUNT: 'Create bank-account',
        INDEX_WALLET_BANK_ACCOUNT: 'Index wallet bank account',
        CREATE_OPERATION_ORGANISATION: 'Create operation-organisation',
        INDEX_WALLET_DETAIL: 'Index wallet detail',
        INDEX_TAG: 'Index tag',
        INDEX_TAG_CATEGORY: 'Index tag category',
        CREATE_TAG: 'Create tag',
        PATCH_TAG: 'Patch tag',
        READ_TAG: 'Read tag',
        UPDATE_TAG: 'Update tag',
        UPDATE_PRICING_PLAN: 'Update pricing plan',
        CREATE_PRICING_PLAN_DEFAULT: 'Create pricing-plan-default',
        INDEX_ORGANISATION_TAG: 'Index organisation tag',
        CREATE_ORGANISATION_TAG: 'Create organisation tag',
        INDEX_INVITATION_CODE_DETAIL: 'Index invitation code detail',
        DISPLAY_TASK_LIST: 'Display Task List',
        DISPLAY_ALL_WALLET_LIST: 'Display All Wallet List',
        DISPLAY_TAG_LIST: 'Display Tag List',
        INDEX_CUSTOMER: 'Index customer',
        READ_CUSTOMER: 'Read customer',
        INDEX_CUSTOMER_VERIFICATION: 'Index customer verification',
        READ_CUSTOMER_VERIFICATION: 'Read customer verification',
        DISPLAY_EWALLET_MERCHANTS: 'Display EWallet Merchants',
        DISPLAY_EWALLET_SECTION: 'Display EWallet Section',
        UPDATE_CUSTOMER: 'Update customer',
        INDEX_BUSINESS: 'Index business',
        READ_BUSINESS: 'Read business',
        DISPLAY_PRE_COUPON_SECTION: 'Display PreCoupon Section',
        DISPLAY_COUPON_SECTION: 'Display Coupon Section',
        DISPLAY_BUSINESSES: 'Display Businesses',
        DISPLAY_COUPONS: 'Display Coupons',
        CREATE_BUSINESS: 'Create business',
        UPDATE_BUSINESS: 'Update business',
        INDEX_COUPON: 'Index coupon',
        READ_COUPON: 'Read coupon',
        CREATE_COUPON: 'Create coupon',
        UPDATE_COUPON: 'Update coupon',
        INDEX_COUPON_TAKEN: 'Index coupon taken',
        READ_COUPON_TAKEN: 'Read coupon taken',
        CREATE_COUPON_TAKEN: 'Create coupon taken',
        UPDATE_COUPON_TAKEN: 'Update coupon taken',
        DISPLAY_COUPON_TAKENS: 'Display Coupon Takens',
        INDEX_COUPON_USED: 'Index coupon used',
        READ_COUPON_USED: 'Read coupon used',
        CREATE_COUPON_USED: 'Create coupon used',
        UPDATE_COUPON_USED: 'Update coupon used',
        DISPLAY_COUPON_USEDS: 'Display Coupon Useds',
        DISPLAY_EWALLET_TRANSACTIONS: 'Display EWallet Transactions',
        DISPLAY_EWALLET_VIRTUAL_GOOD_LIST: 'Display EWallet Virtual Good List',
        INDEX_EWALLET_VIRTUAL_GOOD: 'Index EWallet Virtual Good',
        READ_EWALLET_VIRTUAL_GOOD: 'Read EWallet Virtual Good',
        CREATE_EWALLET_VIRTUAL_GOOD: 'Create EWallet Virtual Good',
        UPDATE_EWALLET_VIRTUAL_GOOD: 'Update EWallet Virtual Good',
        DISPLAY_EWALLET_VIRTUAL_GOOD_ORDER_LIST: 'Display EWallet Virtual Good Order List',
        INDEX_EWALLET_VIRTUAL_GOOD_ORDER: 'Index EWallet Virtual Good Order',
        READ_EWALLET_VIRTUAL_GOOD_ORDER: 'Read EWallet Virtual Good Order',
        CREATE_EWALLET_VIRTUAL_GOOD_ORDER: 'Create EWallet Virtual Good Order',
        UPDATE_EWALLET_VIRTUAL_GOOD_ORDER: 'Update EWallet Virtual Good Order',
        DISPLAY_WALLET_BALANCE: 'Display Wallet Balance',
        DISPLAY_EWALLET_USERS: 'Display Ewallet Users',
        RETRY_DD_FAIL: 'Retry DD Fail',
        INDEX_AM: 'Index Am',
        DISPLAY_EWALLET_FOR_ADVISER: 'Display Ewallet For Adviser'
    }
}
