import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers, Http, RequestOptions } from '@angular/http';
var TicketTypesService = /** @class */ (function (_super) {
    tslib_1.__extends(TicketTypesService, _super);
    function TicketTypesService(http, httpP) {
        var _this = _super.call(this, 'ticket-types', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        return _this;
    }
    TicketTypesService.prototype.ngOnInit = function () {
    };
    TicketTypesService.prototype.getAllTicketTypesByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.getAllTicketTypesSearchByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        return this.http.get(this.url + "/search?" + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.enabledStatus = function (id) {
        return this.http.post(this.url + "/" + id + "/enable", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.disableStatus = function (id) {
        return this.http.post(this.url + "/" + id + "/disable", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.applyShip = function (id, shipId) {
        return this.http.post(this.url + "/" + id + "/" + shipId + "/apply-ship", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.publishCouponById = function (id) {
        return this.http.post(this.url + "/" + id + "/dispatch", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.publishTicketById = function (id, action) {
        return this.http.post(this.url + "/" + id + "/dispatch?", action).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.updateStatus = function (id, value) {
        return this.http.post(this.url + "/" + id + "/update-status?", value).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.getTemplateField = function (token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/template-field/';
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.getTemplatePdf = function (token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/ticket-pdf-template/';
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.exportPdf = function (token, id) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'para/ticket/preview/pdf/' + id + '/' + '?start_from_dash=2019-11-23&valid_to_dash=2020-11-22&voucher_code=AB12ED12' +
            '&customer_mobile=1234567890&customer_name=XiaoMing&valid_to_std=2019-11-23&valid_to_eng=2020-11-22&valid_to_chinese=2019-11-23&start_from_std=2019-11-23'
            + '&start_from_eng=2019-11-23&start_from_chinese=2019-11-23';
        return this.httpP.get("" + url, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.updatePdf = function (token, pdf, id) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/ticket-pdf-template/' + id + '/';
        return this.httpP.patch("" + url, pdf, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.addPDF = function (token, pdf) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        var url = this.baseParaUrl + 'api/ticket-pdf-template/';
        return this.httpP.post("" + url, pdf, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.importProduct = function (businessId, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/" + businessId + "/import-product", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.setTop = function (id) {
        return this.http.post(this.url + "/" + id + "/set-top", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.setUp = function (id) {
        return this.http.post(this.url + "/" + id + "/go-up", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    TicketTypesService.prototype.setDown = function (id) {
        return this.http.post(this.url + "/" + id + "/go-down", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return TicketTypesService;
}(BaseService));
export { TicketTypesService };
