import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paging } from '../../models/latipay/paging.model';
var VoucherInventoryService = /** @class */ (function (_super) {
    tslib_1.__extends(VoucherInventoryService, _super);
    function VoucherInventoryService(http) {
        var _this = _super.call(this, 'voucher-inventory', http) || this;
        _this.http = http;
        return _this;
    }
    VoucherInventoryService.prototype.ngOnInit = function () {
    };
    VoucherInventoryService.prototype.getAllTopic = function (searchStr) {
        searchStr = searchStr ? searchStr : '';
        var tempPaging = new Paging({ pageNumber: 1, pageSize: 1000000 });
        var idSortStr = 'sort=queue,desc&';
        return this.http.get(this.url + "?" + tempPaging.getPagingStr() + (this.embeddedStr + searchStr) + "&sort=queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherInventoryService.prototype.getAllTopicByPaging = function (searchCondition, sortOprions, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        var sortStr = sortOprions.getSortString();
        return this.http.get("" + this.url + sortStr + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=queue,asc").pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherInventoryService.prototype.getAllByBusinessIdByPaging = function (searchCondition, businessId, paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + (this.embeddedStr + searchCondition) + "&sort=id,desc" + (businessId ? "&f_businessId=" + businessId + "&f_businessId_op==" : '')).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherInventoryService.prototype.getAllByWalletIdsByPaging = function (walletIds, searchCondition, paging) {
        var sortStr = '&sort=id,asc';
        var walletIdsStr = '';
        if (walletIds) {
            walletIds.forEach(function (id) {
                walletIdsStr += "&f_acceptedMerchants=" + id;
            });
        }
        if (walletIdsStr) {
            walletIdsStr += '&f_acceptedMerchants_op=IN';
        }
        return this.http.get(this.url + "?" + paging.getPagingStr() + walletIdsStr + sortStr + searchCondition).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    VoucherInventoryService.prototype.publishCouponById = function (id) {
        return this.http.post(this.url + "/" + id + "/dispatch", '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return VoucherInventoryService;
}(BaseService));
export { VoucherInventoryService };
