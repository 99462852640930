
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {Constants} from '../constants/app.constant';
 import {Payee} from '../models/payee.model';
import {Balance} from "../models/admin/balance.model";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class BalanceService implements OnInit {


    public balanceUrl = Constants.API_ENDPOINT + 'v1/balance';

    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {

    }

    // http://api.ccfxtrader.com/v1/payer/2/payee?page=1&size=12
    getAllByPagingForAdmin(userId: number, pageNumber: number, pageSize: number) {
        const embedded = '&embedded=balance-incomings,balance-settlements';
        return this.http.get(`${this.balanceUrl}?f_user.id=${userId}&f_user.id_op==&page=${pageNumber}&size=${pageSize}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    get(id: number): any {
        const embedded = '?embedded=balance-incomings,balance-settlements';
        return this.http.get(this.balanceUrl + '/' + id + embedded).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    addBalanceForAdmin(balance: Balance) {
        return this.http.post(this.balanceUrl, balance).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    updateBalanceForAdmin(id: number, balance: Balance) {
        return this.http.put(this.balanceUrl + '/' + id, balance).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }


}
