import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constants} from '../constants/app.constant';
import {saveAs} from 'file-saver';
import {MyNotifyService} from './my-notify.service';

@Injectable()
export class DownloadService {
    constructor(private http: HttpClient, private myNotifyService: MyNotifyService) {

    }

    downloadFile(url: string, fileName: string) {
        console.log(url);
        this.getFileBlob(url).subscribe(blob => {
            // console.log('Download response:', blob);
            saveAs(blob, fileName);
        });
    }

    getFileBlob(url: string): Observable<Blob> {
        const options: {
            headers?: HttpHeaders,
            observe?: 'body',
            params?: HttpParams,
            reportProgress?: boolean,
            responseType: 'blob',
            withCredentials?: boolean
        } = {
            responseType: 'blob'
        };
        return this.http.get(Constants.API_ENDPOINT + url, options);
    }

    downloadBase64EncodedFile(url: string, fileName: string) {
        return this.http.get(Constants.API_ENDPOINT + url).subscribe((resp) => {
            // console.log('Download response:', resp);
            if (resp) {
                const blob = new Blob( [ this.base64ToArrayBuffer(resp) ], { type: 'octet/stream' } );
                saveAs(blob, fileName);

            } else {
                console.log('Failed to download');
                this.myNotifyService.notifyFail('Failed to download file');
            }
        });
    }

    base64ToArrayBuffer(base64) {
        const binaryString =  window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++)        {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

}
