import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';
import {Paging} from '../../models/latipay/paging.model';

@Injectable()
export class CouponTakenService extends BaseService implements OnInit {

    constructor(public http: HttpClient) {
        super('coupon_taken', http);

    }

    ngOnInit(): void {
    }

    getAllByPaging(searchCondition: string, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }
        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByCouponIdByPaging(searchCondition: string, couponId: number, paging: Paging) {
        if (!searchCondition) {
            searchCondition = '';
        }

        return this.http.get(`${this.url}?${paging.getPagingStr()}${this.embeddedStr + searchCondition}&sort=id,desc` + (couponId ? `&f_couponId=${couponId}&f_couponId_op==` : '')).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }

    getAllByCouponIds(couponIds: number[]) {
        let searchCondition = '';
        if (couponIds && couponIds.length > 0) {
            searchCondition = '&f_couponId_op=IN';
            couponIds.forEach(id => {
                searchCondition += '&f_couponId=' + id;
            });
        }
        const tempPaging = new Paging({pageNumber: 1, pageSize: 1000000});
        const idSortStr = 'sort=id,desc&';
        return this.http.get(`${this.url}?${tempPaging.getPagingStr()}${this.embeddedStr + searchCondition}`).pipe(
            map((response) => response), catchError((res: any) => observableThrowError(res)));
    }
}
