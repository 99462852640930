
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Constants} from '../constants/app.constant';
import {Beneficiary} from '../models/beneficiary.model';
import {MyHttp} from './http.client.service';
import {PayerSubmit} from '../models/payer.submit.model';
import {Role} from "../models/admin/role.model";
import {PriceCategory} from "../models/admin/price-category.model";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class PriceCategoryService {
    public endpoint = Constants.API_ENDPOINT + 'v1/price-category';
    constructor(private http: HttpClient) {
    }



    page(keyword: string, pageNumber: number, pageSize: number) {
        return this.http.get(`${this.endpoint}?page=${pageNumber}&size=${pageSize}`).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    get(id: number) {
        return this.http.get(this.endpoint + '/' + id).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }
    post(priceCategory: PriceCategory) {
        return this.http.post(this.endpoint,priceCategory).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }
    put(id: number,priceCategory: PriceCategory) {
        return this.http.put(this.endpoint + '/' + id,priceCategory).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }

    delete(id : number) {
        return this.http.delete(this.endpoint + '/' + id).pipe(
            map((response) => response),catchError((res: any) => observableThrowError(res)),);
    }
}
